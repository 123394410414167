/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookedHoursForProjects } from '../models/BookedHoursForProjects';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingBookedHoursProjectsService {
    /**
     * lists booked hours for projects of given employee
     * @param tenantId tenant_id
     * @param staffNumber staff_number
     * @param startDate start_date
     * @param endDate end_date
     * @returns BookedHoursForProjects successful
     * @throws ApiError
     */
    public static getProjects(
        tenantId: string,
        staffNumber: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<BookedHoursForProjects> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/booked_hours/projects',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'staff_number': staffNumber,
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
}
