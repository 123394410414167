import BusinessIcon from "@mui/icons-material/Business";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  ROUTES,
  getMatchedRouteForPathname,
} from "~components/routeDefinitions.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";

import { HeaderMenu } from "../HeaderMenu.tsx";
import { HeaderMenuButton } from "../HeaderMenuButton.tsx";

import type { Tenant } from "~api/tenants.ts";

interface TenantMenuProps {
  tenants: Tenant[];
}

export function TenantMenu({ tenants }: TenantMenuProps) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const currentTenant = useCurrentTenant();
  const isSingleTenant = tenants.length < 2;

  if (isSingleTenant && !isLargeScreen) {
    return <></>;
  }

  return (
    <Fade enter={true} in={tenants.length > 0}>
      <div>
        <HeaderMenu
          component={(handleClick) => (
            <HeaderMenuButton
              hasMultipleOptions={!isSingleTenant}
              onClick={handleClick}
              startIcon={<BusinessIcon />}
              aria-label={currentTenant?.shortName}
              title={currentTenant?.shortName}
            >
              {currentTenant?.shortName}
            </HeaderMenuButton>
          )}
          menuPosition="left"
        >
          {(handleClose) =>
            tenants.map((tenant) => (
              <TenantMenuItem
                key={tenant.name}
                tenant={tenant}
                handleClose={handleClose}
              />
            ))
          }
        </HeaderMenu>
      </div>
    </Fade>
  );
}

interface TenantMenuItemProps {
  tenant: Tenant;
  handleClose: () => void;
}
function TenantMenuItem({ tenant, handleClose }: TenantMenuItemProps) {
  const currentTenant = useCurrentTenant();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { route: tenantRoute = ROUTES.dashboard, params } =
    getMatchedRouteForPathname(pathname);
  let tenantPath = tenantRoute?.link({
    ...params,
    tenantNumber: tenant.number,
  });

  if (
    tenantRoute.path === ROUTES.timeTracking.path ||
    tenantRoute.path === ROUTES.timeTrackingStartTeamAssignment.path
  ) {
    tenantPath = ROUTES.timeTrackingStart.link({ tenantNumber: tenant.number });
  } else if (tenantRoute.path === ROUTES.constructionSite.path) {
    tenantPath = ROUTES.constructionSites.link({ tenantNumber: tenant.number });
  }

  return (
    <MenuItem
      key={tenant.name}
      disabled={currentTenant === tenant}
      onClick={() => {
        navigate(tenantPath);
        handleClose();
      }}
    >
      <Link to={tenantPath}>{tenant.shortName}</Link>
    </MenuItem>
  );
}
