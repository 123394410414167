import type { FilterParams } from "./tools";

export const toolKeys = {
  base: () => ["tools"] as const,
  list: (filterParams?: FilterParams) =>
    [...toolKeys.base(), "list", filterParams] as const,
  infiniteList: (filterParams?: FilterParams) =>
    [...toolKeys.base(), "infiniteList", filterParams] as const,
  responsibleEmployees: () =>
    [...toolKeys.base(), "responsibleEmployees"] as const,
  managers: () => [...toolKeys.base(), "managers"] as const,
  manufacturers: () => [...toolKeys.base(), "manufacturers"] as const,
  additionalData: () => [...toolKeys.base(), "additionalData"] as const,
  detail: (id: string) => [...toolKeys.base(), "detail", id] as const,
  history: (id: string) => [...toolKeys.detail(id), "history"] as const,
  updateToolMutationKey: ["updateToolMutation"] as const,
};
