import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList.ts";

import type { HTMLAttributes, ReactNode } from "react";

import styles from "./SidebarView.module.scss";

interface DrawerHeaderProps {
  title: string;
  onSidebarToggle: () => void;
  icon: ReactNode;
  isOpen: boolean;
}

function DrawerHeader({
  title,
  onSidebarToggle,
  icon,
  isOpen,
}: DrawerHeaderProps) {
  const t = useTranslation();

  return (
    <div className={styles.drawerHeader}>
      <span>{title}</span>
      <IconButton
        onClick={onSidebarToggle}
        color="inherit"
        size="medium"
        aria-label={isOpen ? t.closeSidebar : t.openSidebar}
      >
        {icon}
      </IconButton>
    </div>
  );
}

export interface SidebarViewProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  title: string;
  onSidebarToggle: () => void;
}

export function SidebarView({
  children,
  open = false,
  title,
  className,
  onSidebarToggle,
  ...props
}: SidebarViewProps) {
  const t = useTranslation();

  const ToggleIcon = open ? ChevronLeftIcon : MenuIcon;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div
      {...props}
      className={classList(
        styles.container,
        isDesktop && styles.permanent,
        open && styles.open,
        className,
      )}
    >
      {!isDesktop && (
        <div className={styles.toggle_temporary}>
          <IconButton
            onClick={onSidebarToggle}
            color="inherit"
            size="medium"
            aria-label={open ? t.closeSidebar : t.openSidebar}
          >
            <MenuIcon />
          </IconButton>
        </div>
      )}
      <SwipeableDrawer
        variant={isDesktop ? "permanent" : "temporary"}
        anchor="left"
        open={open}
        onOpen={onSidebarToggle}
        onClose={onSidebarToggle}
        className={styles.drawer}
        PaperProps={{ className: styles.drawer__paper }}
      >
        <div className={styles.drawer__container}>
          <DrawerHeader
            title={title}
            onSidebarToggle={onSidebarToggle}
            icon={<ToggleIcon />}
            isOpen={open}
          />
          {children}
        </div>
      </SwipeableDrawer>
    </div>
  );
}
