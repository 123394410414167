import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import trees from "~assets/png/trees.png";
import { classList } from "~lib/classList";

import type { PaperProps } from "@mui/material/Paper";
import type { HTMLAttributes } from "react";

import styles from "./DashboardLayout.module.scss";

export interface DashboardLayoutColumnProps
  extends HTMLAttributes<HTMLDivElement> {
  title?: string;
}

export function DashboardLayoutColumn({
  title,
  className,
  children,
  ...props
}: DashboardLayoutColumnProps) {
  return (
    <div className={classList(styles.column, className)} {...props}>
      {title && <h2>{title}</h2>}
      {children}
    </div>
  );
}

export interface DashboardLayoutEmptyProps
  extends HTMLAttributes<HTMLDivElement> {
  message: string;
}

export function DashboardLayoutEmpty({
  message,
  className,
  ...props
}: DashboardLayoutEmptyProps) {
  return (
    <div className={classList(styles.empty, className)} {...props}>
      <p>{message}</p>
      <img src={trees} alt="trees" />
    </div>
  );
}

export interface DashboardLayoutProps extends HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "equalColumns";
}

export function DashboardLayout({
  className,
  children,
  variant = "default",
  ...props
}: DashboardLayoutProps) {
  return (
    <div
      className={classList(
        styles.container,
        styles[`variant_${variant}`],
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export function DashboardLayoutNavigationGrid({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const theme = useTheme();
  const isMinSm = useMediaQuery(theme.breakpoints.up("sm"));

  const Component = isMinSm ? Paper : "div";

  return (
    <Component
      className={classList(styles.navigationGrid, className)}
      {...props}
    >
      {children}
    </Component>
  );
}

export function DashboardLayoutNavigationGridHeader({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classList(styles.navigationGridHeader, className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function DashboardLayoutNavigationGridBody({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classList(styles.navigationGridBody, className)} {...props}>
      {children}
    </div>
  );
}

export function DashboardTaskCard({
  className,
  children,
  ...props
}: PaperProps) {
  return (
    <Paper className={classList(styles.taskCard, className)} {...props}>
      {children}
    </Paper>
  );
}

export function DashboardTaskCardTitle({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classList(styles.taskCardTitle, className)} {...props}>
      {children}
    </div>
  );
}

export function DashboardTaskCardLink({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classList(styles.taskCardLink, className)} {...props}>
      {children}
      <ArrowForwardIcon fontSize="small" />
    </div>
  );
}
