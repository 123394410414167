/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingAutoStopEventBody = {
    action: TimeTrackingAutoStopEventBody.action;
    stop_time: string;
    pause_duration_seconds: number;
    reference: string;
    resource: Resource;
};
export namespace TimeTrackingAutoStopEventBody {
    export enum action {
        AUTO_STOP = 'auto_stop',
    }
}

