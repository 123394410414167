import { useQuery } from "@tanstack/react-query";

import { tenantKeys } from "~api/tenants.ts";
import { useCurrentEmployeeRunningTimeTrackingsContext } from "~contexts/CurrentEmployeeRunningTimeTrackingsContext/CurrentEmployeeRunningTimeTrackingsContext.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { TimeTrackingsService } from "~generated";

import { findEntity } from "../../../api/helpers.ts";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { Tenant, TimeTracking } from "~generated";

type CurrentEmployeeStatus = NonNullable<
  Parameters<typeof TimeTrackingsService.getCurrentEmployeeTimeTrackings>[1]
>;

type MeFilters = { status: CurrentEmployeeStatus };
export const timeTrackingKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "timeTrackings"] as const,
  me(tenantId: string, filters: MeFilters) {
    return [...timeTrackingKeys.base(tenantId), "me", filters];
  },
  detail: (tenantId: string, id: string) =>
    [...timeTrackingKeys.base(tenantId), "detail", id] as const,
};

const getCurrentEmployeeTimeTrackings = async ({
  tenantId,
  status,
}: {
  tenantId: Tenant["id"];
  status: CurrentEmployeeStatus;
}) => {
  const timeTrackings =
    await TimeTrackingsService.getCurrentEmployeeTimeTrackings(
      tenantId,
      status,
    );
  if (!timeTrackings || !timeTrackings.time_trackings) {
    return [];
  }
  return timeTrackings.time_trackings;
};

type UseTimeTrackingsQueryOptions = UseQueryOptions<
  TimeTracking[],
  Error,
  TimeTracking[],
  ReturnType<typeof timeTrackingKeys.me>
>;

export function useCurrentEmployeeRunningTimeTrackings(
  options?: Partial<UseTimeTrackingsQueryOptions>,
) {
  const currentTenant = useCurrentTenant();
  return useQuery({
    ...options,
    queryKey: timeTrackingKeys.me(currentTenant.id, { status: "running" }),
    queryFn: async () =>
      getCurrentEmployeeTimeTrackings({
        tenantId: currentTenant.id,
        status: "running",
      }),
  });
}

export function useCurrentEmployeeStoppedTimeTrackings(
  options?: Partial<UseTimeTrackingsQueryOptions>,
) {
  const currentTenant = useCurrentTenant();
  return useQuery({
    ...options,
    queryKey: timeTrackingKeys.me(currentTenant.id, { status: "stopped" }),
    queryFn: async () =>
      getCurrentEmployeeTimeTrackings({
        tenantId: currentTenant.id,
        status: "stopped",
      }),
  });
}

export function useCurrentEmployeeRunningTimeTracking(id: string) {
  const currentEmployeeRunningTimeTrackings =
    useCurrentEmployeeRunningTimeTrackingsContext();
  return findEntity(currentEmployeeRunningTimeTrackings, id);
}

export function useCurrentEmployeeNewestRunningTimeTracking() {
  const currentEmployeeRunningTimeTrackings =
    useCurrentEmployeeRunningTimeTrackingsContext();
  return currentEmployeeRunningTimeTrackings.slice(-1)[0];
}
