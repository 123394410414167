import Collapse from "@mui/material/Collapse";
import Popover from "@mui/material/Popover";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { classList } from "~lib/classList.ts";

import { SidebarMenuItem } from "./SidebarMenuItem.tsx";

import type { ReactNode } from "react";
import type { NavLinkProps } from "react-router-dom";

import styles from "./SidebarMenuGroupView.module.scss";

interface SidebarMenuGroupViewProps {
  title: string;
  icon: ReactNode;
  children: ReactNode;
  active: boolean;
  variant: "popover" | "collapsible";
}

export function SidebarMenuGroupView(props: SidebarMenuGroupViewProps) {
  switch (props.variant) {
    case "popover":
      return <SidebarMenuPopoverGroupView {...props} />;
    case "collapsible":
    default:
      return <SidebarMenuCollapsibleGroupView {...props} />;
  }
}

function SidebarMenuCollapsibleGroupView({
  title,
  icon,
  active,
  children,
}: SidebarMenuGroupViewProps) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={classList(styles.group, styles.collapsible)}>
      <SidebarMenuItem
        title={title}
        icon={icon}
        isActive={active}
        collapsible={{ collapsed }}
        listItemButtonProps={{
          onClick: (event) => {
            event.stopPropagation();
            setCollapsed((prev) => !prev);
          },
        }}
      />
      <Collapse in={!collapsed}>
        <div className={styles.group__items}>{children}</div>
      </Collapse>
    </div>
  );
}

function SidebarMenuPopoverGroupView({
  title,
  icon,
  active,
  children,
}: SidebarMenuGroupViewProps) {
  const anchorEl = useRef(null);
  const [anchorElHovered, setAnchorElHovered] = useState(false);
  const [popoverElHovered, setPopoverElHovered] = useState(false);

  const popoverOpen = anchorElHovered || popoverElHovered;

  return (
    <div className={(styles.group, styles.popover)}>
      <div
        ref={anchorEl}
        onMouseEnter={() => setAnchorElHovered(true)}
        onMouseLeave={() => setAnchorElHovered(false)}
      >
        <SidebarMenuItem
          title={title}
          icon={icon}
          hover={popoverOpen}
          isActive={active}
          listItemButtonProps={{ style: { cursor: "default" } }}
        />
      </div>
      <Popover
        slotProps={{
          root: { sx: { pointerEvents: "none" } },
          paper: {
            sx: {
              pointerEvents: "auto",
              background: "transparent",
              boxShadow: "none",
              overflow: "visible",
            },
          },
        }}
        open={popoverOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        <div
          onMouseEnter={() => setPopoverElHovered(true)}
          onMouseLeave={() => setPopoverElHovered(false)}
          className={classList(styles.group__items, styles.popover)}
        >
          <div>
            <div className={styles.groupTitle}>{title}</div>
            {children}
          </div>
        </div>
      </Popover>
    </div>
  );
}

interface SidebarMenuGroupItemViewProps extends Omit<NavLinkProps, "children"> {
  title: string;
}

export function SidebarMenuGroupItemView({
  title,
  className,
  ...props
}: SidebarMenuGroupItemViewProps) {
  return (
    <NavLink
      className={({ isActive }) =>
        classList(styles.groupItem, isActive && styles.active, className)
      }
      {...props}
    >
      <span>{title}</span>
    </NavLink>
  );
}
