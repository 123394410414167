import { useEffect } from "react";

// used to trigger an error (i.e. for testing sentry connection)
export function TestError() {
  useEffect(() => {
    throw new Error("test error");
  }, []);

  return <>Test Error</>;
}
