import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import type { OptionsObject, SnackbarKey } from "notistack";

export function useToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const t = useTranslation();

  const closeAction = useCallback(
    (id: SnackbarKey) => (
      <IconButton
        size="small"
        aria-label={t.closeSnackbar}
        color="inherit"
        onClick={() => {
          closeSnackbar(id);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ),
    [closeSnackbar, t],
  );

  const showErrorToast = useCallback(
    (message: string | React.ReactNode) => {
      enqueueSnackbar(message, {
        variant: "error",
        persist: true,
        action: closeAction,
        SnackbarProps: {
          "data-testid": "error-toast",
        },
      } as OptionsObject);
    },
    [closeAction, enqueueSnackbar],
  );

  const showSuccessToast = useCallback(
    (message: string | React.ReactNode) => {
      enqueueSnackbar(message, {
        variant: "success",
        action: closeAction,
        SnackbarProps: {
          "data-testid": "success-toast",
        },
      } as OptionsObject);
    },
    [enqueueSnackbar, closeAction],
  );

  return { showErrorToast, showSuccessToast };
}
