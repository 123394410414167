/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportWageTypes } from '../models/ExportWageTypes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExportWageTypesService {
    /**
     * returns the export wage types of the current tenant
     * @param tenantId tenant_id
     * @returns ExportWageTypes successful
     * @throws ApiError
     */
    public static getExportWageTypes(
        tenantId: string,
    ): CancelablePromise<ExportWageTypes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/export_wage_types',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
