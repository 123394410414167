import ExpandIcon from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { classList } from "~lib/classList.ts";

import type { HTMLAttributes, ReactNode } from "react";

import styles from "./SidebarMenuItem.module.scss";

interface SidebarMenuItemProps {
  title: string;
  isActive: boolean;
  hover?: boolean;
  icon: ReactNode;
  collapsible?: { collapsed: boolean } | undefined;
  listItemButtonProps?: HTMLAttributes<HTMLDivElement>;
}

export function SidebarMenuItem({
  title,
  isActive,
  hover = false,
  icon,
  collapsible = undefined,
  listItemButtonProps,
}: SidebarMenuItemProps) {
  return (
    <ListItem className={styles.item}>
      <ListItemButton
        className={classList(
          styles.button,
          isActive && styles.active,
          hover && styles.hover,
          collapsible && styles.collapsible,
          collapsible?.collapsed && styles.collapsed,
        )}
        {...listItemButtonProps}
      >
        <ListItemIcon className={styles.icon}>{icon}</ListItemIcon>
        <ListItemText
          className={styles.text}
          primary={title}
          primaryTypographyProps={{ variant: "body2" }}
        />
        {collapsible && (
          <ListItemIcon
            className={classList(
              styles.icon,
              styles.chevron,
              collapsible?.collapsed && styles.collapsed,
            )}
          >
            <ExpandIcon fontSize="small" />
          </ListItemIcon>
        )}
      </ListItemButton>
    </ListItem>
  );
}
