import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { config } from "~initializer/config";

export function useTenantBasedFeatureToggle(
  configField: keyof ReturnType<typeof config>,
) {
  const { number } = useCurrentTenant();

  const configValue = config()[configField];
  if (!configValue) {
    return false;
  }

  return configValue.split(",").includes(`${number}`);
}
