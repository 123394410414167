/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationCount } from '../models/NotificationCount';
import type { Notifications } from '../models/Notifications';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationsService {
    /**
     * returns notifications
     * @param startDate start_date
     * @param endDate end_date
     * @returns Notifications notifications
     * @throws ApiError
     */
    public static getNotifications(
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<Notifications> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/me/notifications',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * marks a notification as read
     * @param notificationId notification_id
     * @returns void
     * @throws ApiError
     */
    public static markNotificationRead(
        notificationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/me/notifications/{notification_id}/mark_read',
            path: {
                'notification_id': notificationId,
            },
        });
    }
    /**
     * marks all notifications as read
     * @returns void
     * @throws ApiError
     */
    public static markAllNotificationsRead(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/me/notifications/mark_all_read',
        });
    }
    /**
     * returns notifications
     * @returns NotificationCount notifications
     * @throws ApiError
     */
    public static getNotificationCount(): CancelablePromise<NotificationCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/me/notifications/count',
        });
    }
}
