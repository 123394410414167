import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { useEmployees } from "~api/employees.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { EmployeesService, TeamAssignmentsService } from "~generated";

import { findEntities, findEntity } from "./helpers";
import { teamAssignmentKeys } from "./teamAssignmentKeys";
import { useBillableTools } from "./tools";
import { useWorkPackages } from "./workPackages";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { TeamAssignment } from "~generated";

async function getCurrentEmployeeTeamAssignments(tenantId: string) {
  const teamAssignments =
    await EmployeesService.getCurrentEmployeeTeamAssignments(tenantId);
  if (!teamAssignments || !teamAssignments.team_assignments) {
    return [];
  }

  return teamAssignments.team_assignments;
}

export function useCurrentEmployeeTeamAssignments(
  options?: Partial<
    UseQueryOptions<
      TeamAssignment[],
      Error,
      TeamAssignment[],
      ReturnType<typeof teamAssignmentKeys.me>
    >
  >,
) {
  const currentTenant = useCurrentTenant();

  return useQuery({
    ...options,
    queryKey: teamAssignmentKeys.me(currentTenant.id),
    queryFn: async () => getCurrentEmployeeTeamAssignments(currentTenant.id),
  });
}

export function useTeamAssignments() {
  const currentTenant = useCurrentTenant();

  const query = useQuery({
    queryKey: teamAssignmentKeys.list(currentTenant.id),
    queryFn: async () => {
      const teamAssignments = await TeamAssignmentsService.getTeamAssignments(
        currentTenant.id,
      );
      if (!teamAssignments || !teamAssignments.team_assignments) {
        return [];
      }
      return teamAssignments.team_assignments;
    },
  });

  return {
    ...query,
    data: query.data || [],
  };
}

export function useCurrentEmployeeTeamAssignment(id?: string) {
  const { data: teamAssignments, isLoading: areTeamAssignmentsLoading } =
    useCurrentEmployeeTeamAssignments();
  const { data: employees, isLoading: areEmployeesLoading } = useEmployees();
  const { data: tools, isLoading: areToolsLoading } = useBillableTools();
  const { data: workPackages, isLoading: areWorkPackagesLoading } =
    useWorkPackages();

  const isLoading =
    areTeamAssignmentsLoading ||
    areEmployeesLoading ||
    areToolsLoading ||
    areWorkPackagesLoading;

  const data = useMemo(() => {
    let teamAssignment = (teamAssignments || []).find(
      ({ id: teamAssignmentId }) => id === teamAssignmentId,
    );

    if (teamAssignment) {
      teamAssignment = {
        ...teamAssignment,
        employees: findEntities(employees, teamAssignment?.employee_ids),
        tools: findEntities(tools, teamAssignment?.tool_ids),
        work_package: findEntity(workPackages, teamAssignment.work_package_id),
      } as TeamAssignment;
    }
    return teamAssignment;
  }, [employees, id, teamAssignments, tools, workPackages]);

  return {
    data,
    isLoading,
  };
}

export function useUpdateTeamAssignment() {
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (teamAssignment: TeamAssignment) =>
      TeamAssignmentsService.updateTeamAssignment(
        currentTenant.id,
        teamAssignment.id as string,
        teamAssignment,
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: teamAssignmentKeys.base(currentTenant.id),
      });
    },
  });
}
