import { memo } from "react";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { LocationIndicator, NoLocationIndicator } from "./LocationIndicator";

import type { Translations } from "~contexts/I18nContext/I18nContext.tsx";
import type { GeoPosition } from "~generated";

export type PositionIndicatorProps = {
  position?: GeoPosition | null;
};
function PositionIndicator({ position }: PositionIndicatorProps) {
  const t = useTranslation();

  if (!position) {
    return <NoLocationIndicator />;
  }

  const color = !position ? "red" : "blue";
  const opacity = !position?.outdated ? 1 : 0.3;
  const tooltips = getTooltips({ position, t });

  return (
    <LocationIndicator
      color={color}
      opacity={opacity}
      tooltips={tooltips}
      latitude={position.latitude}
      longitude={position.longitude}
    />
  );
}

function getTooltips({
  position,
  t,
}: PositionIndicatorProps & { t: Translations }): string[] {
  if (!position) {
    return [t.timeApprovalNoLocation];
  }

  return [
    t.timeApprovalShowLocation,
    position.outdated && t.timeApprovalOutdatedLocation,
  ].filter(Boolean) as string[];
}

const MemoizedPositionIndicator = memo(PositionIndicator);

export { MemoizedPositionIndicator as PositionIndicator };
