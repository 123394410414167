import * as Sentry from "@sentry/react";
import { createContext, useContext, useState } from "react";

import type { ReactNode } from "react";
import type { Tenant } from "~api/tenants.ts";

class CurrentTenantError extends Error {}

interface CurrentTenantContextValue {
  currentTenant?: Tenant;
  setCurrentTenant: (tenant?: Tenant) => void;
  isInitialized: boolean;
}
const emptyContext: CurrentTenantContextValue = {
  currentTenant: undefined,
  setCurrentTenant: () => {},
  isInitialized: false,
};
export const CurrentTenantContext = createContext(emptyContext);

interface CurrentTenantProviderProps {
  children: ReactNode;
  currentTenant?: Tenant;
}

export function CurrentTenantProvider({
  currentTenant: tenant,
  children,
}: CurrentTenantProviderProps) {
  const [currentTenant, setCurrentTenant] = useState(tenant);
  const [isInitialized, setInitialized] = useState(false);

  const onSetCurrentTenant = (t?: Tenant) => {
    setCurrentTenant(t);
    const currentTenantContext = t ? { id: t.id } : null;
    Sentry.setContext("Current Tenant", currentTenantContext);
    setInitialized(true);
  };

  return (
    <CurrentTenantContext.Provider
      value={{
        currentTenant,
        setCurrentTenant: onSetCurrentTenant,
        isInitialized,
      }}
    >
      {children}
    </CurrentTenantContext.Provider>
  );
}

export function useCurrentTenant() {
  const { currentTenant } = useContext(CurrentTenantContext);

  if (!currentTenant) {
    throw new CurrentTenantError("Current tenant is undefined");
  }

  return currentTenant;
}
