import { parseISO } from "date-fns/parseISO";

import { useAllEmployees } from "~api/employees";
import { findEntity } from "~api/helpers";
import { useAllTools } from "~api/tools";
import { useNotifications } from "~components/Notifications/api/notifications.ts";
import { Notification } from "~generated";
import { convertToDateOrNull } from "~lib/dateHelpers.ts";

import type { DateRange } from "@mui/x-date-pickers-pro";
import type {
  Employee,
  GeoPosition,
  NotificationBody,
  NotificationBodyToolDataChanged,
  NotificationBodyToolPermanentDecommissioned,
  NotificationBodyToolPermanentRecommissioned,
  NotificationBodyToolResponsibilityReturned,
  NotificationBodyToolResponsibilityTaken,
  Tool,
  ToolDecommissionReason,
} from "~generated";

export type EmbeddedNotification = Omit<Notification, "body"> & {
  notificationAt: Date;
  sender?: Employee;
  body: EmbeddedNotificationBody;
};

export type GroupedNotifications = [Date, EmbeddedNotification[]][];

type EmbeddedChangeSet = {
  oldManager?: Employee;
  newManager?: Employee;
};

export type EmbeddedNotificationBody = {
  tool?: Tool;
  responsibleEmployee?: Employee;
  previousResponsibleEmployee?: Employee;
  position?: GeoPosition | null;
  changeSet?: EmbeddedChangeSet;
  decommissionedAt?: Date;
  decommissionReason?: ToolDecommissionReason;
};

export function useEmbeddedNotifications([
  startDate,
  endDate,
]: DateRange<Date>): {
  isLoading: boolean;
  embeddedNotifications: EmbeddedNotification[];
} {
  const { data: notifications, isLoading: isLoadingNotifications } =
    useNotifications({
      startDate: startDate || undefined,
      endDate: endDate || undefined,
    });
  const { data: employees, isLoading: isLoadingEmployees } = useAllEmployees();
  const { data: tools, isLoading: isLoadingTools } = useAllTools({});

  if (!notifications || !employees || !tools) {
    return {
      isLoading: isLoadingNotifications || isLoadingEmployees || isLoadingTools,
      embeddedNotifications: [],
    };
  }

  const embeddedNotifications = notifications.map((notification) =>
    embedNotifications(notification, employees, tools.tools || []),
  );

  return {
    isLoading: isLoadingNotifications || isLoadingEmployees || isLoadingTools,
    embeddedNotifications,
  };
}

function embedNotifications(
  notification: Notification,
  employees: Employee[],
  tools: Tool[],
): EmbeddedNotification {
  const baseNotification = {
    ...notification,
    notificationAt: parseISO(notification.notification_at),
    sender: findEntity(employees, notification.sender_id),
  };
  if (
    isNotificationToolResponsibilityTaken(
      notification.notification_type,
      notification.body,
    )
  ) {
    return {
      ...baseNotification,
      body: embeddedToolResponsibilityTakenBody({
        body: notification.body,
        employees,
        tools,
      }),
    };
  }
  if (
    isNotificationToolResponsibilityReturned(
      notification.notification_type,
      notification.body,
    )
  ) {
    return {
      ...baseNotification,
      body: embeddedToolResponsibilityReturnedBody({
        body: notification.body,
        employees,
        tools,
      }),
    };
  }
  if (
    isNotificationToolPermanentDecommissioned(
      notification.notification_type,
      notification.body,
    )
  ) {
    return {
      ...baseNotification,
      body: embeddedToolPermanentDecommissionedBody({
        body: notification.body,
        tools,
      }),
    };
  }
  if (
    isNotificationToolPermanentRecommissioned(
      notification.notification_type,
      notification.body,
    )
  ) {
    return {
      ...baseNotification,
      body: embeddedToolPermanentRecommissionedBody({
        body: notification.body,
        tools,
      }),
    };
  }

  // TOOL_DATA_CHANGED
  return {
    ...baseNotification,
    body: embeddedToolDataChangedBody({
      body: notification.body,
      employees,
      tools,
    }),
  };
}

function isNotificationToolResponsibilityTaken(
  type: Notification.notification_type,
  body: NotificationBody,
): body is NotificationBodyToolResponsibilityTaken {
  return type === Notification.notification_type.TOOL_RESPONSIBILITY_TAKEN;
}

function isNotificationToolResponsibilityReturned(
  type: Notification.notification_type,
  body: NotificationBody,
): body is NotificationBodyToolResponsibilityReturned {
  return type === Notification.notification_type.TOOL_RESPONSIBILITY_RETURNED;
}

function isNotificationToolPermanentDecommissioned(
  type: Notification.notification_type,
  body: NotificationBody,
): body is NotificationBodyToolPermanentDecommissioned {
  return type === Notification.notification_type.TOOL_PERMANENT_DECOMMISSIONED;
}

function isNotificationToolPermanentRecommissioned(
  type: Notification.notification_type,
  body: NotificationBody,
): body is NotificationBodyToolPermanentRecommissioned {
  return type === Notification.notification_type.TOOL_PERMANENT_RECOMMISSIONED;
}

function embeddedToolResponsibilityTakenBody({
  body,
  employees,
  tools,
}: {
  body: NotificationBodyToolResponsibilityTaken;
  employees: Employee[];
  tools: Tool[];
}): EmbeddedNotificationBody {
  return {
    responsibleEmployee: findEntity(employees, body.responsible_employee_id),
    previousResponsibleEmployee: findEntity(
      employees,
      body.previous_responsible_employee_id,
    ),
    tool: findEntity(tools, body.tool_id),
    position: body.position,
  };
}

function embeddedToolResponsibilityReturnedBody({
  body,
  employees,
  tools,
}: {
  body: NotificationBodyToolResponsibilityReturned;
  employees: Employee[];
  tools: Tool[];
}): EmbeddedNotificationBody {
  return {
    responsibleEmployee: findEntity(employees, body.responsible_employee_id),
    tool: findEntity(tools, body.tool_id),
    position: body.position,
  };
}

function embeddedToolDataChangedBody({
  body,
  employees,
  tools,
}: {
  body: NotificationBodyToolDataChanged;
  employees: Employee[];
  tools: Tool[];
}): EmbeddedNotificationBody {
  return {
    tool: findEntity(tools, body.tool_id),
    changeSet: {
      oldManager: findEntity(employees, body.change_set.old_manager_id),
      newManager: findEntity(employees, body.change_set.new_manager_id),
    },
  };
}

function embeddedToolPermanentDecommissionedBody({
  body,
  tools,
}: {
  body: NotificationBodyToolPermanentDecommissioned;
  tools: Tool[];
}): EmbeddedNotificationBody {
  return {
    tool: findEntity(tools, body.tool_id),
    decommissionedAt: convertToDateOrNull(body.decommissioned_at) ?? undefined,
    decommissionReason: body.decommission_reason,
  };
}

function embeddedToolPermanentRecommissionedBody({
  body,
  tools,
}: {
  body: NotificationBodyToolPermanentRecommissioned;
  tools: Tool[];
}): EmbeddedNotificationBody {
  return {
    tool: findEntity(tools, body.tool_id),
  };
}
