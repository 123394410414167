/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BusinessLine {
    CONSTRUCTION = 'construction',
    OPEN_SPACE_MANAGEMENT = 'open_space_management',
    TREE_CARE = 'tree_care',
    MAINTENANCE = 'maintenance',
    ADMINISTRATION = 'administration',
}
