import { useMemo } from "react";

import { getGroupNameForTimeEntry } from "~components/TimeManagement/TimeApproval/timeEntryHelpers.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { matchesAny } from "~lib/matchesAny.ts";
import { ResourceType } from "~lib/resourceHelpers.ts";

import type { EmbeddedTimeEntry } from "~components/TimeManagement/shared/useEmbeddedTimeEntries/EmbeddedTimeEntry.ts";
import type { GroupingOption } from "~components/TimeManagement/shared/useGroupedEmbeddedTimeEntries/GroupingOption.ts";
import type { TimeEntry, TimeEntryStatus } from "~generated";

export enum OverlappingFilter {
  ONLY = "only",
  EXCLUDE = "exclude",
}

export enum AnomaliesFilter {
  ONLY = "only",
  EXCLUDE = "exclude",
}

export enum ResourceTypeFilter {
  All = "all",
  Employee = "employee",
  Tool = "tool",
}

export type UseFilterableTimeEntriesProps = {
  timeEntries: EmbeddedTimeEntry[];
  statusList?: readonly TimeEntryStatus[];
  overlapping?: OverlappingFilter;
  anomalies?: AnomaliesFilter;
  groupName?: string;
  groupingOption?: GroupingOption;
  resourceType?: ResourceTypeFilter;
  constructionSiteManagerId?: string;
  resourceId?: string;
};
export function useFilterableTimeEntries({
  timeEntries,
  statusList,
  overlapping,
  anomalies,
  groupName,
  groupingOption,
  resourceType,
  constructionSiteManagerId,
  resourceId,
}: UseFilterableTimeEntriesProps) {
  const t = useTranslation();
  const filteredTimeEntries = useMemo(() => {
    let filteredEntries = timeEntries;

    if (groupName && groupingOption) {
      filteredEntries = filteredEntries.filter((timeEntry) =>
        matchesAny(groupName, [
          getGroupNameForTimeEntry({ groupingOption, timeEntry, t }),
        ]),
      );
    }

    if (statusList) {
      filteredEntries = filteredEntries.filter((timeEntry) =>
        filterByStatus(timeEntry, statusList),
      );
    }

    if (overlapping) {
      filteredEntries = filteredEntries.filter((timeEntry) =>
        overlapping === OverlappingFilter.ONLY
          ? timeEntry.isOverlapping
          : !timeEntry.isOverlapping,
      );
    }

    if (anomalies) {
      filteredEntries = filteredEntries.filter((timeEntry) =>
        anomalies === AnomaliesFilter.ONLY
          ? timeEntry.hasAnomalies
          : !timeEntry.hasAnomalies,
      );
    }

    if (resourceType && resourceType !== ResourceTypeFilter.All) {
      const resourceTypeFilter =
        resourceType === ResourceTypeFilter.Employee
          ? ResourceType.EMPLOYEE
          : ResourceType.TOOL;
      filteredEntries = filteredEntries.filter(
        (timeEntry) => timeEntry.resource?.type === resourceTypeFilter,
      );
    }

    if (constructionSiteManagerId) {
      filteredEntries = filteredEntries.filter(
        ({ construction_site_manager_id }) =>
          construction_site_manager_id === constructionSiteManagerId,
      );
    }

    if (resourceId) {
      filteredEntries = filteredEntries.filter(
        ({ resource }) => resource.id === resourceId,
      );
    }

    return filteredEntries;
  }, [
    anomalies,
    timeEntries,
    groupName,
    statusList,
    overlapping,
    groupingOption,
    resourceType,
    constructionSiteManagerId,
    resourceId,
    t,
  ]);

  return {
    filteredTimeEntries,
  };
}

function filterByStatus(
  timeEntry: TimeEntry,
  statuses?: readonly TimeEntryStatus[],
) {
  if (!statuses || statuses.length === 0) {
    return true;
  }
  return statuses.includes(timeEntry.status);
}
