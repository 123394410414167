import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { ErrorBoundary } from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { type ReactNode, Suspense } from "react";

import { GreweTheme } from "~assets/grewe-theme";
import { LoginPage } from "~components/App/LoginPage/LoginPage";
import { Routes } from "~components/App/Routes/Routes.tsx";
import { UpdateBanner } from "~components/App/UpdateBanner/UpdateBanner.tsx";
import { GenericError } from "~components/shared/ErrorPages/GenericError.tsx";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner";
import { BusinessConfigProvider } from "~contexts/BusinessConfigContext/BusinessConfigContext";
import { CurrentTenantProvider } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useI18n } from "~contexts/I18nContext/I18nContext.tsx";
import { SelectedManagerProvider } from "~contexts/SelectedManagerContext/SelectedManagerContext.tsx";
import { UserProfileProvider } from "~contexts/UserProfileContext/UserProfileContext.tsx";
import { InitializeReactQuery } from "~initializer/react-query";

/*
 * see https://mui.com/guides/interoperability/#css-injection-order
 */
export function GlobalCssPriority({ children }: { children: ReactNode }) {
  return <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>;
}

export function App() {
  const { locale, datePickersTranslations } = useI18n();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={datePickersTranslations}
    >
      <ThemeProvider theme={GreweTheme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <InitializeReactQuery>
            <BusinessConfigProvider>
              <GlobalCssPriority>
                <UpdateBanner />
                <ErrorBoundary showDialog={true} fallback={GenericError}>
                  <AuthenticatedTemplate>
                    <CurrentTenantProvider>
                      <UserProfileProvider>
                        <SelectedManagerProvider>
                          <Suspense fallback={<Spinner />}>
                            <Routes />
                          </Suspense>
                        </SelectedManagerProvider>
                      </UserProfileProvider>
                    </CurrentTenantProvider>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <LoginPage />
                  </UnauthenticatedTemplate>
                </ErrorBoundary>
              </GlobalCssPriority>
            </BusinessConfigProvider>
          </InitializeReactQuery>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
