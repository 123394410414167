import ConstrutionSiteManagerIcon from "@mui/icons-material/Engineering";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import uniq from "lodash/uniq";

import { usePermitted } from "~auth/usePermitted.ts";
import { ROUTES } from "~components/routeDefinitions.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { useSelectedManager } from "~contexts/SelectedManagerContext/useSelectedManager.tsx";
import { getResourceName } from "~lib/resourceHelpers.ts";

import { HeaderMenu } from "../HeaderMenu.tsx";
import { HeaderMenuButton } from "../HeaderMenuButton.tsx";

const managerSelectAbilities = uniq([
  ...ROUTES.bookedHours.permissions,
  ...ROUTES.constructionSites.permissions,
  ...ROUTES.monitoring.permissions,
  ...ROUTES.teamPlanning.permissions,
  ...ROUTES.teams.permissions,
  ...ROUTES.timeApproval.permissions,
]);

export function ManagerSelect() {
  const t = useTranslation();

  const { managers, selectedManager, setSelectedManager, isLoading } =
    useSelectedManager();

  const permitted = usePermitted();

  if (!permitted(managerSelectAbilities)) {
    return null;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  if (!managers?.length) {
    return null;
  }

  const selectedLabel = !selectedManager
    ? t.managerSelectAllOption
    : getResourceName(selectedManager);

  return (
    <HeaderMenu
      component={(handleClick) => (
        <HeaderMenuButton
          hasMultipleOptions={true}
          onClick={handleClick}
          startIcon={<ConstrutionSiteManagerIcon />}
          data-testid="manager-select"
        >
          {selectedLabel}
        </HeaderMenuButton>
      )}
      menuPosition="left"
    >
      {(handleClose) => [
        <MenuItem
          key={0}
          value={"ALL"}
          onClick={() => {
            setSelectedManager("ALL");
            handleClose();
          }}
          disabled={!selectedManager}
        >
          {t.managerSelectAllOption}
        </MenuItem>,
        ...managers.map((manager) => (
          <MenuItem
            key={manager.id}
            value={manager.id}
            disabled={selectedManager?.id === manager.id}
            onClick={() => {
              setSelectedManager(manager);
              handleClose();
            }}
          >
            {getResourceName(manager)}
          </MenuItem>
        )),
      ]}
    </HeaderMenu>
  );
}
