import TableCell from "@mui/material/TableCell";
import { type TableCellProps } from "@mui/material/TableCell";
import { Fragment } from "react";

import { classList } from "~lib/classList";

import type { HTMLAttributes } from "react";

import styles from "./ListHeading.module.scss";

export interface ListHeadingProps {
  isSticky?: boolean;
  stickyOffsetTop?: string;
}

export function ListHeading({
  isSticky,
  children,
  stickyOffsetTop = "0px",
  className,
  ...props
}: ListHeadingProps & HTMLAttributes<HTMLHeadingElement>) {
  const Content = isSticky ? "span" : Fragment;

  return (
    <h3
      className={classList(
        styles.heading,
        isSticky && styles.sticky,
        className,
      )}
      style={{ top: stickyOffsetTop }}
      {...props}
    >
      <Content>{children}</Content>
    </h3>
  );
}

export function ListHeadingTableCell({
  isSticky,
  children,
  stickyOffsetTop = "var(--table-head-size)",
  className,
  ...props
}: ListHeadingProps & TableCellProps) {
  const Content = isSticky ? "span" : Fragment;

  return (
    <TableCell
      className={classList(
        styles.tableCell,
        isSticky && styles.sticky,
        className,
      )}
      style={{ top: stickyOffsetTop }}
      {...props}
    >
      <Content>{children}</Content>
    </TableCell>
  );
}
