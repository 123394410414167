import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import {
  availableLanguages,
  useI18n,
  useTranslation,
} from "~contexts/I18nContext/I18nContext.tsx";

import { SettingsSection } from "./SettingsSection";

export function LanguageSetting() {
  const t = useTranslation();
  const { lang: currentLang, setLang } = useI18n();

  return (
    <SettingsSection title={t.settingsLanguageTitle}>
      <ToggleButtonGroup
        value={currentLang}
        fullWidth={true}
        exclusive={true}
        onChange={(event, newLang) => setLang(newLang)}
      >
        {availableLanguages.map((lang) => (
          <ToggleButton
            key={lang}
            value={lang}
            fullWidth={true}
            size={"small"}
            color={"info"}
          >
            {lang}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </SettingsSection>
  );
}
