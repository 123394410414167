import React from "react";

if (process.env.REACT_APP_WDYR_ENABLED === "true") {
  // eslint-disable-next-line import/no-extraneous-dependencies
  const { default: whyDidYouRender } = await import(
    "@welldone-software/why-did-you-render"
  );

  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
