/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AccountGroup {
    REVENUE = 'revenue',
    MATERIALS = 'materials',
    PLANTS = 'plants',
    DISPOSAL = 'disposal',
    TOOLS = 'tools',
    FOREIGN = 'foreign',
    MISC = 'misc',
}
