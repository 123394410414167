import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useUpdateCurrentEmployee } from "~api/employees";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { SettingsSection } from "./SettingsSection";

import type { Employee } from "~generated";

type BetaSettingProps = {
  currentEmployee: Employee;
};
export function BetaSetting({ currentEmployee }: BetaSettingProps) {
  const t = useTranslation();
  const updateCurrentEmployee = useUpdateCurrentEmployee();

  return (
    <SettingsSection
      title={t.settingsBetaTitle}
      description={t.settingsBetaDescription}
    >
      <ToggleButtonGroup
        value={currentEmployee.beta_user}
        fullWidth={true}
        exclusive={true}
        onChange={(event, beta_user) =>
          updateCurrentEmployee.mutate({ beta_user })
        }
      >
        <ToggleButton
          value={false}
          disabled={!currentEmployee.beta_user}
          fullWidth={true}
          size={"small"}
          color={"info"}
        >
          {t.settingsBetaDisabled}
        </ToggleButton>
        <ToggleButton
          value={true}
          disabled={currentEmployee.beta_user}
          fullWidth={true}
          size={"small"}
          color={"info"}
        >
          {t.settingsBetaEnabled}
        </ToggleButton>
      </ToggleButtonGroup>
    </SettingsSection>
  );
}
