import { Navigate } from "react-router-dom";

import { useCurrentEmployeeTenants } from "~api/tenants.ts";
import { anyAbility } from "~auth/Ability.ts";
import { usePermitted } from "~auth/usePermitted.ts";
import { ROUTES } from "~components/routeDefinitions.ts";
import { Broken } from "~components/shared/ErrorPages/Broken.tsx";
import { Forbidden } from "~components/shared/ErrorPages/Forbidden.tsx";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner.tsx";

export function RedirectToTool() {
  const { toolId } = ROUTES.toolDeeplink.useParams();
  const { data: tenants, isError: isTenantsError } =
    useCurrentEmployeeTenants();
  const permitted = usePermitted();

  if (isTenantsError) {
    return <Broken />;
  }

  if (!permitted(anyAbility) || (tenants && tenants.length === 0)) {
    return <Forbidden />;
  }

  if (permitted(anyAbility) && tenants && tenants.length > 0) {
    const searchParams = new URLSearchParams({ searchText: toolId });
    return (
      <Navigate
        to={ROUTES.tools.link(
          { tenantNumber: tenants[0].number },
          searchParams,
        )}
      />
    );
  }

  return <Spinner />;
}
