import memoize from "lodash/memoize";

import { useAllEmployees, useEmployees } from "~api/employees";
import { hashCode } from "~lib/hashCode";
import { employeeToResource, toolToResource } from "~lib/resourceHelpers.ts";

import { useBillableTools } from "./tools";

import type { BillableTool } from "./BillableTool";
import type { Employee } from "~generated";

const buildResources = memoize(
  (employees?: Employee[], tools?: BillableTool[]) => [
    ...(employees || []).map(employeeToResource),
    ...(tools || []).map(toolToResource),
  ],
  (employees, tools) =>
    hashCode(JSON.stringify({ employees, tools })).toString(),
);

interface UseResourcesOptions {
  includeResignedEmployees: boolean;
}
export function useResources(
  { includeResignedEmployees }: UseResourcesOptions = {
    includeResignedEmployees: false,
  },
) {
  const employeesQuery = useEmployees({ includeResignedEmployees });
  const toolsQuery = useBillableTools({ active: true });
  const resources = buildResources(employeesQuery.data, toolsQuery.data);

  return {
    data: resources,
    isLoading: employeesQuery.isLoading || toolsQuery.isLoading,
    isError: employeesQuery.isError || toolsQuery.isError,
  };
}

export function useAllResources() {
  const employeesQuery = useAllEmployees();
  const toolsQuery = useBillableTools();
  const resources = buildResources(employeesQuery.data, toolsQuery.data);

  return {
    data: resources,
    isLoading: employeesQuery.isLoading || toolsQuery.isLoading,
    isError: employeesQuery.isError || toolsQuery.isError,
    isFetching: employeesQuery.isFetching || toolsQuery.isFetching,
  };
}
