import ExpandMore from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

import { useIsDesktop } from "~components/shared/useIsDesktop/useIsDesktop.ts";
import { classList } from "~lib/classList";

import type { ButtonProps } from "@mui/material/Button";

import styles from "./HeaderMenuButton.module.scss";

export interface HeaderMenuButtonProps
  extends Omit<ButtonProps, "size" | "variant" | "endIcon" | "disabled"> {
  hasMultipleOptions: boolean;
}

export function HeaderMenuButton({
  hasMultipleOptions,
  children,
  className,
  ...props
}: HeaderMenuButtonProps) {
  const isLargeScreen = useIsDesktop();

  return (
    <Button
      className={classList(styles.button, className)}
      size="small"
      variant="contained"
      endIcon={hasMultipleOptions && <ExpandMore />}
      disabled={!hasMultipleOptions}
      {...props}
    >
      {isLargeScreen ? children : <></>}
    </Button>
  );
}
