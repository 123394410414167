/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingDeleteEventBody = {
    action: TimeTrackingDeleteEventBody.action;
    reference: string;
    resource: Resource;
};
export namespace TimeTrackingDeleteEventBody {
    export enum action {
        DELETE = 'delete',
    }
}

