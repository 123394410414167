/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Team } from '../models/Team';
import type { Teams } from '../models/Teams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TeamsService {
    /**
     * list teams
     * @param tenantId tenant_id
     * @param includeDeleted include_deleted
     * @returns Teams include deleted teams
     * @throws ApiError
     */
    public static getTeams(
        tenantId: string,
        includeDeleted?: boolean,
    ): CancelablePromise<Teams> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/teams',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
        });
    }
    /**
     * create team associated with current the tenant
     * @param tenantId tenant_id
     * @param requestBody
     * @returns Team team created
     * @throws ApiError
     */
    public static createTeam(
        tenantId: string,
        requestBody?: Team,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/teams',
            path: {
                'tenant_id': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }
    /**
     * update team associated with current tenant
     * @param tenantId tenant_id
     * @param id id
     * @param requestBody
     * @returns Team team updated
     * @throws ApiError
     */
    public static updateTeam(
        tenantId: string,
        id: string,
        requestBody?: Team,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/tenants/{tenant_id}/teams/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }
    /**
     * delete teams
     * @param tenantId tenant_id
     * @param id id
     * @returns void
     * @throws ApiError
     */
    public static deleteTeam(
        tenantId: string,
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/tenants/{tenant_id}/teams/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
        });
    }
}
