/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TimeTrackingSessionStopEventBody = {
    action: TimeTrackingSessionStopEventBody.action;
    performed_activities?: string;
    note?: string;
};
export namespace TimeTrackingSessionStopEventBody {
    export enum action {
        SESSION_STOP = 'session_stop',
    }
}

