import { anyAbility } from "~auth/Ability.ts";
import { usePermitted } from "~auth/usePermitted.ts";
import { Forbidden } from "~components/shared/ErrorPages/Forbidden.tsx";

import type { ReactElement } from "react";

interface RequirePermissionProps {
  children: ReactElement;
}

export function RequireAppAccess({ children }: RequirePermissionProps) {
  const permitted = usePermitted();

  if (!permitted(anyAbility)) {
    return <Forbidden />;
  }

  return children;
}
