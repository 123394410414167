import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useToast } from "~components/shared/useToast.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { TeamsService } from "~generated";

import { allEmployeeKeys, employeeKeys } from "./employees";
import { managerKeys } from "./managerKeys.ts";
import { tenantKeys } from "./tenants";
import { toolKeys } from "./toolKeys";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { Team } from "~generated";

export const teamKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "teams"] as const,
  list: (tenantId: string, includeDeleted?: boolean) =>
    [...teamKeys.base(tenantId), "list", includeDeleted] as const,
};

async function getTeams(tenantId: string, includeDeleted?: boolean) {
  const teams = await TeamsService.getTeams(tenantId, includeDeleted);
  if (!teams || !teams.teams) {
    return [];
  }

  return teams.teams.sort((a, b) => a.name.localeCompare(b.name));
}

export function useTeams(
  {
    includeDeleted,
    options,
  }: {
    includeDeleted?: boolean;
    options?: Partial<
      UseQueryOptions<Team[], Error, Team[], ReturnType<typeof teamKeys.list>>
    >;
  } = { includeDeleted: false, options: {} },
) {
  const currentTenant = useCurrentTenant();
  return useQuery({
    ...options,
    queryKey: teamKeys.list(currentTenant.id, includeDeleted || false),
    queryFn: async () => getTeams(currentTenant.id, includeDeleted),
  });
}

export function useTeam({
  id,
  includeDeleted,
}: {
  id?: string;
  includeDeleted?: boolean;
}) {
  const { data: teams, ...loadingState } = useTeams({ includeDeleted });
  const team = teams?.find(({ id: teamId }) => teamId === id);
  return { data: team, ...loadingState };
}

export function useCreateTeam() {
  const { showSuccessToast } = useToast();
  const t = useTranslation();
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (team: Team) =>
      TeamsService.createTeam(currentTenant.id, team),
    onSuccess: async () => {
      showSuccessToast(t.addTeamSuccess);

      await invalidateQueriesForTeamUpdates({
        queryClient,
        tenantId: currentTenant.id,
      });
    },
  });
  return mutation;
}

export function useUpdateTeam() {
  const { showSuccessToast } = useToast();
  const t = useTranslation();
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (team: Team) =>
      TeamsService.updateTeam(currentTenant.id, team.id as string, team),

    onSuccess: async () => {
      showSuccessToast(t.editSuccess);

      await invalidateQueriesForTeamUpdates({
        queryClient,
        tenantId: currentTenant.id,
      });
    },
  });
  return mutation;
}

export function useDeleteTeam() {
  const { showSuccessToast } = useToast();
  const t = useTranslation();
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (teamId: string) =>
      TeamsService.deleteTeam(currentTenant.id, teamId),

    onSuccess: async () => {
      showSuccessToast(t.deleteTeamSuccess);

      await invalidateQueriesForTeamUpdates({
        queryClient,
        tenantId: currentTenant.id,
      });
    },
  });
  return mutation;
}

async function invalidateQueriesForTeamUpdates({
  queryClient,
  tenantId,
}: {
  queryClient: ReturnType<typeof useQueryClient>;
  tenantId: string;
}) {
  await queryClient.invalidateQueries({
    queryKey: teamKeys.base(tenantId),
  });
  await queryClient.invalidateQueries({ queryKey: allEmployeeKeys.list() });
  await queryClient.invalidateQueries({
    queryKey: employeeKeys.list(tenantId),
  });
  await queryClient.invalidateQueries({ queryKey: toolKeys.list() });
  await queryClient.invalidateQueries({
    queryKey: managerKeys.list(tenantId),
  });
}
