/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ControllingProjectStatus {
    COMPLETED = 'completed',
    IN_PROGRESS = 'in_progress',
    UNSTARTED = 'unstarted',
    UNKNOWN = 'unknown',
}
