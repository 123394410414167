import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { useLayoutEffect, useState } from "react";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import type { CircularProgressProps } from "@mui/material/CircularProgress";

import styles from "./FullscreenSpinner.module.scss";

export type FullscreenSpinnerProps = {
  showMoreInfoTimeout?: number;
} & CircularProgressProps;

export function FullscreenSpinner({
  showMoreInfoTimeout = 3000,
  ...props
}: FullscreenSpinnerProps) {
  const t = useTranslation();
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setShowMoreInfo(true);
    }, showMoreInfoTimeout);

    return () => clearTimeout(timeout);
  }, [setShowMoreInfo, showMoreInfoTimeout]);

  return (
    <div className={styles.container} data-testid="fullscreen-spinner">
      <CircularProgress {...props} size={"4rem"} />
      <Collapse in={showMoreInfo}>
        <p className={styles.moreLoadingInfo}>{t.moreLoadingInfo}</p>
      </Collapse>
    </div>
  );
}

export function FullscreenSpinnerOverlay() {
  return (
    <Backdrop
      open={true}
      sx={{ color: "#fff", zIndex: Number.MAX_SAFE_INTEGER }}
    >
      <FullscreenSpinner color="inherit" />
    </Backdrop>
  );
}
