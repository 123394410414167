import {
  CalendarIcon,
  DateRangePicker as MUIDateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { differenceInCalendarDays } from "date-fns/differenceInCalendarDays";
import { isBefore } from "date-fns/isBefore";
import { isSameDay } from "date-fns/isSameDay";
import { isValid } from "date-fns/isValid";
import React from "react";

import { classList } from "~lib/classList";

import type {
  DateRange,
  DateRangePickerProps,
  PickersActionBarAction,
} from "@mui/x-date-pickers-pro";

import styles from "./DateRangePicker.module.scss";

type CustomDateRangePickerProps = DateRangePickerProps<Date> & {
  hasClearButton?: boolean;
};

export const DateRangePicker = React.forwardRef(
  (
    { label, hasClearButton = true, ...props }: CustomDateRangePickerProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const actions: PickersActionBarAction[] = hasClearButton ? ["clear"] : [];

    return (
      <MUIDateRangePicker
        {...props}
        className={classList(props.className, styles.rangePicker)}
        format="dd.MM.yy"
        ref={ref}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          ...props.slotProps,
          actionBar: { actions },
          textField: {
            size: "small",
            label,
            InputProps: {
              endAdornment: <CalendarIcon />,
            },
            ...props.slotProps?.textField,
          },
        }}
      />
    );
  },
);
DateRangePicker.displayName = "DateRangePicker";

export function isValidDateRange(
  dateRange: DateRange<Date>,
  maxDateRangeInDays?: number,
): dateRange is [Date, Date] {
  const [startDate, endDate] = dateRange;
  if (!startDate || !endDate) {
    return false;
  }

  if (
    maxDateRangeInDays &&
    differenceInCalendarDays(endDate, startDate) > maxDateRangeInDays
  ) {
    return false;
  }

  return (
    isValid(startDate) &&
    isValid(endDate) &&
    (isSameDay(startDate, endDate) || isBefore(startDate, endDate))
  );
}
