import { Outlet } from "react-router-dom";

import { PositionErrorModalProvider } from "~contexts/PositionContext/PositionErrorModalContext";

export function TimeTrackingOutlet() {
  return (
    <PositionErrorModalProvider>
      <Outlet />
    </PositionErrorModalProvider>
  );
}
