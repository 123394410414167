/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ControllingProjectsExportStatus = {
    status?: ControllingProjectsExportStatus.status | null;
    csv?: string | null;
};
export namespace ControllingProjectsExportStatus {
    export enum status {
        IN_PROGRESS = 'inProgress',
        COMPLETED = 'completed',
    }
}

