/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ControllingMonthlyEmployeeTargets } from '../models/ControllingMonthlyEmployeeTargets';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingMonthlyEmployeeTargetsService {
    /**
     * upload monthly targets
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateControllingMonthlyEmployeeTargets(
        requestBody?: ControllingMonthlyEmployeeTargets,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/controlling/monthly_employee_targets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `invalid request`,
            },
        });
    }
    /**
     * export monthly employee targets as csv
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static export(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/controlling/monthly_employee_targets/export',
        });
    }
}
