import some from "lodash/some";

type Haystack = string | undefined | null;

export function matchesAny(
  needle: string,
  haystacks: Haystack[],
  minLength = 3,
) {
  const normalizedNeedle = normalize(needle);

  if (normalizedNeedle.length < minLength) {
    return true;
  }

  return some(haystacks, (haystack: Haystack) => {
    if (haystack === undefined || haystack === null) {
      return false;
    }
    return normalize(haystack).includes(normalizedNeedle);
  });
}

function normalize(text: string) {
  return text.toLocaleLowerCase().trim();
}
