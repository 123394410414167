import { Spinner } from "~components/shared/Loading/Spinner/Spinner.tsx";
import { RunningTimeTrackingBanner } from "~components/TimeManagement/RunningTimeTrackingBanner/RunningTimeTrackingBanner";
import { useNavigationDefinitions } from "~components/useNavigationDefinitions.tsx";

import { Header } from "./Header/Header";
import { Sidebar } from "./Sidebar/Sidebar.tsx";

import type { ReactNode } from "react";

import styles from "./Layout.module.scss";

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const { isLoading } = useNavigationDefinitions();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div>
        <RunningTimeTrackingBanner />
      </div>
      <div className={styles.base}>
        <aside className={styles.aside}>{<Sidebar />}</aside>
        <header className={styles.header}>{<Header />}</header>
        <main className={styles.main}>{children}</main>
      </div>
    </div>
  );
}
