import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatISO } from "date-fns/formatISO";

import { NotificationsService } from "~generated";

type NotificationFilters = {
  startDate?: string;
  endDate?: string;
};

export const notificationKeys = {
  base: () => ["notifications"] as const,
  list: (filters?: NotificationFilters) =>
    [...notificationKeys.base(), filters] as const,
  count: () => [...notificationKeys.base(), "count"] as const,
};

export function useNotifications(dateRange: {
  startDate?: Date;
  endDate?: Date;
}) {
  const startDate = dateRange.startDate
    ? formatISO(dateRange.startDate, { representation: "date" })
    : undefined;
  const endDate = dateRange.endDate
    ? formatISO(dateRange.endDate, { representation: "date" })
    : undefined;

  return useQuery({
    queryKey: notificationKeys.list({ endDate, startDate }),
    queryFn: async () => {
      const notifications = await NotificationsService.getNotifications(
        startDate,
        endDate,
      );
      if (!notifications || !notifications.notifications) {
        return [];
      }
      return notifications.notifications;
    },
  });
}

export function useNotificationCount() {
  return useQuery({
    queryKey: notificationKeys.count(),
    queryFn: async () =>
      (await NotificationsService.getNotificationCount()).count,
  });
}

export function useMarkNotificationRead() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) =>
      NotificationsService.markNotificationRead(id),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: notificationKeys.base(),
      });
    },
  });
}

export function useMarkAllNotificationsRead() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => NotificationsService.markAllNotificationsRead(),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: notificationKeys.base(),
      });
    },
  });
}
