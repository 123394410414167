/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ControllingProjectDetails } from '../models/ControllingProjectDetails';
import type { ControllingProjectSearchResult } from '../models/ControllingProjectSearchResult';
import type { ControllingProjectsExportStatus } from '../models/ControllingProjectsExportStatus';
import type { ControllingProjectTotals } from '../models/ControllingProjectTotals';
import type { PaginatedControllingProjectOverviews } from '../models/PaginatedControllingProjectOverviews';
import type { SyncStatus } from '../models/SyncStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingProjectsService {
    /**
     * lists project names
     * @param tenantId tenant_id
     * @param searchText search_text
     * @returns ControllingProjectSearchResult successful
     * @throws ApiError
     */
    public static searchProjects(
        tenantId: string,
        searchText?: string,
    ): CancelablePromise<ControllingProjectSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/projects/search',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'search_text': searchText,
            },
        });
    }
    /**
     * lists paginated projects
     * @param tenantId tenant_id
     * @param numbersArray project numbers
     * @param businessLinesArray business lines
     * @param managerStaffNumbersArray manager staff numbers
     * @param searchText search_text
     * @param statusArray filter status
     * @param clientNamesArray client names
     * @param startDate start_date
     * @param endDate end_date
     * @param page page
     * @param sortBy sort_by
     * @param direction
     * @returns PaginatedControllingProjectOverviews successful
     * @throws ApiError
     */
    public static getProjectOverviews(
        tenantId: string,
        numbersArray?: Array<string>,
        businessLinesArray?: Array<string>,
        managerStaffNumbersArray?: Array<string>,
        searchText?: string,
        statusArray?: Array<'completed' | 'in_progress' | 'unstarted' | 'unknown'>,
        clientNamesArray?: Array<string>,
        startDate?: string,
        endDate?: string,
        page?: number,
        sortBy?: 'number' | 'actual_costs_total' | 'actual_productive_hours_total' | 'actual_profit_contribution' | 'actual_profit_contribution_per_hour' | 'actual_profit_contribution_percentage' | 'actual_relative_profit_contribution' | 'actual_revenue_total' | 'last_incoming_invoice_at' | 'last_outgoing_invoice_at' | 'minimum_invoice_amount',
        direction?: 'asc' | 'desc',
    ): CancelablePromise<PaginatedControllingProjectOverviews> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/projects/overviews',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'numbers[]': numbersArray,
                'business_lines[]': businessLinesArray,
                'manager_staff_numbers[]': managerStaffNumbersArray,
                'search_text': searchText,
                'status[]': statusArray,
                'client_names[]': clientNamesArray,
                'start_date': startDate,
                'end_date': endDate,
                'page': page,
                'sort_by': sortBy,
                'direction': direction,
            },
        });
    }
    /**
     * lists project totals
     * @param tenantId tenant_id
     * @param numbersArray project numbers
     * @param businessLinesArray business lines
     * @param managerStaffNumbersArray manager staff numbers
     * @param searchText search_text
     * @param statusArray filter status
     * @param clientNamesArray client names
     * @param startDate start_date
     * @param endDate end_date
     * @returns ControllingProjectTotals successful
     * @throws ApiError
     */
    public static getProjectTotals(
        tenantId: string,
        numbersArray?: Array<string>,
        businessLinesArray?: Array<string>,
        managerStaffNumbersArray?: Array<string>,
        searchText?: string,
        statusArray?: Array<'completed' | 'in_progress' | 'unstarted' | 'unknown'>,
        clientNamesArray?: Array<string>,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<ControllingProjectTotals> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/projects/totals',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'numbers[]': numbersArray,
                'business_lines[]': businessLinesArray,
                'manager_staff_numbers[]': managerStaffNumbersArray,
                'search_text': searchText,
                'status[]': statusArray,
                'client_names[]': clientNamesArray,
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * start generating csv of selected projects
     * @param tenantId tenant_id
     * @param numbersArray project numbers
     * @param businessLinesArray business lines
     * @param managerStaffNumbersArray manager staff numbers
     * @param statusArray filter status
     * @param clientNamesArray client names
     * @param startDate start_date
     * @param endDate end_date
     * @returns any start generating csv
     * @throws ApiError
     */
    public static startProjectsExport(
        tenantId: string,
        numbersArray?: Array<string>,
        businessLinesArray?: Array<string>,
        managerStaffNumbersArray?: Array<string>,
        statusArray?: Array<'completed' | 'in_progress' | 'unstarted' | 'unknown'>,
        clientNamesArray?: Array<string>,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<{
        export_id?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/controlling/projects/export/start',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'numbers[]': numbersArray,
                'business_lines[]': businessLinesArray,
                'manager_staff_numbers[]': managerStaffNumbersArray,
                'status[]': statusArray,
                'client_names[]': clientNamesArray,
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * get csv of selected projects
     * @param tenantId tenant_id
     * @param exportId export_id
     * @returns ControllingProjectsExportStatus successfully exports project data
     * @throws ApiError
     */
    public static getProjectsExportStatus(
        tenantId: string,
        exportId?: string,
    ): CancelablePromise<ControllingProjectsExportStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/projects/export/status',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'export_id': exportId,
            },
        });
    }
    /**
     * returns the project details
     * @param tenantId tenant_id
     * @param id id
     * @param startDate start_date
     * @param endDate end_date
     * @returns ControllingProjectDetails successful
     * @throws ApiError
     */
    public static getProjectDetails(
        tenantId: string,
        id: string,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<ControllingProjectDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/projects/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * sync projects
     * @param tenantId tenant_id
     * @returns void
     * @throws ApiError
     */
    public static syncControllingData(
        tenantId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/controlling/projects/sync',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * get status of construction sites sync
     * @param tenantId tenant_id
     * @returns SyncStatus sync status
     * @throws ApiError
     */
    public static getControllingDataSyncStatus(
        tenantId: string,
    ): CancelablePromise<SyncStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/projects/sync_status',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
