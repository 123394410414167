/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeoPosition } from './GeoPosition';
import type { ToolDecommissionReason } from './ToolDecommissionReason';
export type ToolForm = {
    abbreviation?: string;
    billable_from?: string | null;
    category_id?: string;
    daily_rate?: number | null;
    license_plate_number?: string | null;
    manager_id?: string;
    minute_rate?: number | null;
    name?: string;
    previous_responsible_employee_id?: string | null;
    responsible_employee_id?: string | null;
    serial_number?: string | null;
    tenant_id?: string;
    tool_id?: string;
    attachments?: string | null;
    comment?: string | null;
    construction_year?: number | null;
    cost_center?: string | null;
    decommission_comment?: string | null;
    decommissioned_at?: string | null;
    decommission_reason?: ToolDecommissionReason | null;
    financing_to?: string | null;
    financing_type?: ToolForm.financing_type | null;
    insurance_line?: string | null;
    insurance_tool_type?: string | null;
    insurance_type?: string | null;
    manufacturer?: string | null;
    operating_hours?: string | null;
    position?: GeoPosition | null;
    power?: string | null;
    purchase_date?: string | null;
    purchase_price?: number | null;
    query_operating_hours?: boolean | null;
    size?: string | null;
    supplier?: string | null;
    temporarily_decommissioned_from?: string | null;
    temporarily_decommissioned_to?: string | null;
    type_designation?: string | null;
};
export namespace ToolForm {
    export enum financing_type {
        DARLEHEN = 'Darlehen',
        MIETKAUF = 'Mietkauf',
        LEASING = 'Leasing',
    }
}

