import { format } from "date-fns/format";
import { isToday } from "date-fns/isToday";

import {
  useLocale,
  useTranslation,
} from "~contexts/I18nContext/I18nContext.tsx";

type DayTitleProps = {
  date: Date;
};
export function DayTitle({ date }: DayTitleProps) {
  const t = useTranslation();
  const locale = useLocale();
  return (
    <>
      {isToday(date) ? t.notificationsToday : format(date, "PPPP", { locale })}
    </>
  );
}
