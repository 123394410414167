/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Resource = {
    id: string;
    type: Resource.type;
};
export namespace Resource {
    export enum type {
        EMPLOYEE = 'Employee',
        TOOL = 'Tool',
    }
}

