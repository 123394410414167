/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConstructionSiteDetail } from '../models/ConstructionSiteDetail';
import type { ConstructionSites } from '../models/ConstructionSites';
import type { CreateAzureTeamBody } from '../models/CreateAzureTeamBody';
import type { SyncStatus } from '../models/SyncStatus';
import type { TeamAssignments } from '../models/TeamAssignments';
import type { UpdateAzureTeamBody } from '../models/UpdateAzureTeamBody';
import type { UpdateConstructionSitePlan } from '../models/UpdateConstructionSitePlan';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ConstructionSitesService {
    /**
     * create azure team for a construction site
     * @param tenantId tenant_id
     * @param constructionSiteId construction_site_id
     * @param requestBody
     * @returns any connect to existing team
     * @throws ApiError
     */
    public static createAzureTeam(
        tenantId: string,
        constructionSiteId: string,
        requestBody: CreateAzureTeamBody,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/construction_sites/{construction_site_id}/azure_team/create',
            path: {
                'tenant_id': tenantId,
                'construction_site_id': constructionSiteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `team allready in use`,
                404: `team not found`,
            },
        });
    }
    /**
     * add unsynced employees to azure team
     * @param tenantId tenant_id
     * @param constructionSiteId construction_site_id
     * @param requestBody
     * @returns any initialize new azure team
     * @throws ApiError
     */
    public static updateAzureTeam(
        tenantId: string,
        constructionSiteId: string,
        requestBody: UpdateAzureTeamBody,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/construction_sites/{construction_site_id}/azure_team/update',
            path: {
                'tenant_id': tenantId,
                'construction_site_id': constructionSiteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * add unsynced employees to azure team
     * @param tenantId tenant_id
     * @param constructionSiteId construction_site_id
     * @returns any retry azure team sync
     * @throws ApiError
     */
    public static retryAzureTeamSync(
        tenantId: string,
        constructionSiteId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/construction_sites/{construction_site_id}/azure_team/retry_sync',
            path: {
                'tenant_id': tenantId,
                'construction_site_id': constructionSiteId,
            },
        });
    }
    /**
     * list construction sites
     * @param tenantId tenant_id
     * @param includeDeleted include_deleted
     * @returns ConstructionSites successful with deleted construction sites
     * @throws ApiError
     */
    public static getConstructionSites(
        tenantId: string,
        includeDeleted?: boolean,
    ): CancelablePromise<ConstructionSites> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/construction_sites',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
        });
    }
    /**
     * sync construction sites
     * @param tenantId tenant_id
     * @returns void
     * @throws ApiError
     */
    public static syncConstructionSites(
        tenantId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/construction_sites/sync',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * get status of construction sites sync
     * @param tenantId tenant_id
     * @returns SyncStatus sync status
     * @throws ApiError
     */
    public static getConstructionSitesSyncStatus(
        tenantId: string,
    ): CancelablePromise<SyncStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/construction_sites/sync_status',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * show construction site
     * @param tenantId tenant_id
     * @param id construction_site_id
     * @returns ConstructionSiteDetail successful
     * @throws ApiError
     */
    public static getConstructionSite(
        tenantId: string,
        id: string,
    ): CancelablePromise<ConstructionSiteDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/construction_sites/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
        });
    }
    /**
     * update construction site plan
     * @param tenantId tenant_id
     * @param id construction_site_id
     * @param requestBody
     * @returns TeamAssignments team_assignments updated
     * @throws ApiError
     */
    public static updateConstructionSitePlan(
        tenantId: string,
        id: string,
        requestBody?: UpdateConstructionSitePlan,
    ): CancelablePromise<TeamAssignments> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/tenants/{tenant_id}/construction_sites/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
            },
        });
    }
}
