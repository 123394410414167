import { useMemo } from "react";

import { useUserProfile } from "~contexts/UserProfileContext/UserProfileContext.tsx";

import type { Ability } from "./Ability";

export function usePermitted() {
  const userProfile = useUserProfile();

  return useMemo(
    () => (requiredAbilites: Ability[]) =>
      requiredAbilites.some((ability) =>
        userProfile.abilities.includes(ability),
      ),
    [userProfile],
  );
}
