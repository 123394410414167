import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

type SettingsSectionProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
};
export function SettingsSection({
  title,
  description,
  children,
}: SettingsSectionProps) {
  return (
    <Box mb={4}>
      <Typography variant={"h4"} component="h3" sx={{ marginBottom: 2 }}>
        {title}
      </Typography>
      {description && (
        <Typography
          component={"p"}
          sx={{ fontSize: "0.75rem", marginBottom: 2 }}
        >
          {description}
        </Typography>
      )}
      {children}
    </Box>
  );
}
