import { useMsal } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/PersonAddAlt";
import InactiveAccountIcon from "@mui/icons-material/PersonOutline";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";

import { apiScopes } from "~auth/config.ts";
import { useLogout } from "~auth/useLogout.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { useOfflineContext } from "~contexts/OfflineContext/useOfflineContext.tsx";

import { HeaderMenu } from "../HeaderMenu.tsx";
import { HeaderMenuIconButton } from "../HeaderMenuIconButton.tsx";

import type { AccountInfo } from "@azure/msal-browser";

import styles from "./UserMenu.module.scss";

interface UserMenuContentProps {
  closeMenu: () => void;
}

function UserMenuContent({ closeMenu }: UserMenuContentProps) {
  const { instance, accounts } = useMsal();
  const queryClient = useQueryClient();
  const logout = useLogout();
  const t = useTranslation();
  const { isOffline } = useOfflineContext();

  const activeAccount = instance.getActiveAccount();

  const loginAnotherUser = async () => {
    await queryClient.resetQueries({ queryKey: [] });
    await instance.loginRedirect({
      scopes: apiScopes,
      prompt: "select_account",
    });
  };

  const select = async (account: AccountInfo) => {
    instance.setActiveAccount(account);
    await queryClient.resetQueries({ queryKey: [] });
    closeMenu();
  };

  const inactiveAccounts = accounts.filter(
    (a) => a.username !== activeAccount?.username,
  );

  return (
    <div>
      <div className={styles.activeAccount} data-testid="active-account">
        <div className={styles.activeAccount__header}>
          <p className={styles.activeAccount__label}>{t.loggedInAs}</p>
          <Button
            onClick={logout}
            size="small"
            startIcon={<LogoutIcon />}
            className={styles.activeAccount__logout}
            disabled={isOffline}
          >
            {t.signOut}
          </Button>
        </div>
        <p className={styles.activeAccount__name}>{activeAccount?.name}</p>
        <p className={styles.activeAccount__email}>{activeAccount?.username}</p>
      </div>

      <div className={styles.inactiveAccounts}>
        {inactiveAccounts.map((a) => (
          <MenuItem
            key={a.username}
            onClick={() => select(a)}
            className={styles.inactiveAccount}
            disabled={isOffline}
          >
            <ListItemIcon>
              <InactiveAccountIcon />
            </ListItemIcon>
            <div>
              <p className={styles.inactiveAccount__name}>{a.name}</p>
              <p className={styles.inactiveAccount__email}>{a.username}</p>
            </div>
          </MenuItem>
        ))}
      </div>
      <MenuItem onClick={loginAnotherUser} disabled={isOffline}>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        {t.loginAnotherUser}
      </MenuItem>
    </div>
  );
}

export interface UserMenuProps {
  userName?: string;
}

export function UserMenu({ userName }: UserMenuProps) {
  return (
    <HeaderMenu
      data-testid="user-menu"
      component={(handleClick) => (
        <HeaderMenuIconButton
          onClick={handleClick}
          className={styles.avatarButton}
          icon={
            <Avatar className={styles.avatarButton__avatar} alt={userName}>
              {userName?.charAt(0)}
            </Avatar>
          }
        />
      )}
    >
      {(closeMenu) => <UserMenuContent closeMenu={closeMenu} />}
    </HeaderMenu>
  );
}
