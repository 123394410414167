/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Task } from './Task';
export type TimeTrackingSessionStartEventBody = {
    action: TimeTrackingSessionStartEventBody.action;
    team_assignment_id?: string;
    construction_site_manager_id: string;
    task?: Task;
    training_note?: string;
    previous_time_tracking_id?: (string | null);
};
export namespace TimeTrackingSessionStartEventBody {
    export enum action {
        SESSION_START = 'session_start',
    }
}

