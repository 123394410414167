import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";

import { config } from "~initializer/config.ts";

import { apiScopes } from "./config";

import type {
  AccountInfo,
  IPublicClientApplication,
} from "@azure/msal-browser";

/**
 * This is a workaround for the following bug in msal-browser
 * @link https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206#issuecomment-961505563
 */
function shouldForceRefresh(idToken: AccountInfo["idTokenClaims"]) {
  return Boolean(
    idToken && idToken.exp && new Date(idToken.exp * 1000) < new Date(),
  );
}

export const fetchToken = async (
  instance: IPublicClientApplication,
  scopes: string[],
  inProgress: InteractionStatus,
) => {
  const account = instance.getActiveAccount() || instance.getAllAccounts()[0];
  if (!account) {
    throw new Error("no account in fetchToken");
  }

  const forceRefresh = shouldForceRefresh(account.idTokenClaims);
  const request = { account, scopes, forceRefresh };

  try {
    return (
      await instance.acquireTokenSilent({
        ...request,
        // using a blank page is recommended by microsoft, when using silent / popup flows.
        // without `blank.html` we had `monitor_window_timeout` errors
        redirectUri: `${config().msalRedirectUri}/blank.html`,
      })
    ).accessToken;
  } catch (e) {
    if (!(e instanceof InteractionRequiredAuthError)) {
      captureException(e);
    }
    if (inProgress === InteractionStatus.None) {
      await instance.acquireTokenRedirect({
        ...request,
        // NO `blank.html` here as this will be used as redirect target in browser
        redirectUri: config().msalRedirectUri,
      });
    }
  }
  return null;
};

export function useAccessToken() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const user = instance.getActiveAccount();

  return useQuery({
    queryKey: ["accessToken", user?.username || ""],
    queryFn: async () => fetchToken(instance, apiScopes, inProgress),
    enabled: isAuthenticated && inProgress === "none",
    staleTime: 0,
  });
}
