/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingCommuteTimeEventBody = {
    action: TimeTrackingCommuteTimeEventBody.action;
    reference: string;
    resource: Resource;
    arrival_commute_time_seconds?: number;
    departure_commute_time_seconds?: number;
};
export namespace TimeTrackingCommuteTimeEventBody {
    export enum action {
        COMMUTE_TIME = 'commute_time',
    }
}

