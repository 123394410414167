/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvalidConstructionSiteStats } from '../models/InvalidConstructionSiteStats';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvalidConstructionSitesService {
    /**
     * returns sync errors and invalid construction site stats
     * @param tenantId tenant_id
     * @returns InvalidConstructionSiteStats successful
     * @throws ApiError
     */
    public static invalidConstructionSites(
        tenantId: string,
    ): CancelablePromise<InvalidConstructionSiteStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/health_board/invalid_construction_sites',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
