import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

type SettingsHeaderProps = {
  onClose: () => void;
};
export function SettingsHeader({ onClose }: SettingsHeaderProps) {
  const t = useTranslation();
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      mb={4}
      alignItems={"center"}
    >
      <Typography component={"h2"} sx={{ fontWeight: "bold" }}>
        {t.settingsHeader}
      </Typography>
      <IconButton onClick={onClose} aria-label={t.settingsCloseButton}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
