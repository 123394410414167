import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import styles from "./ErrorPages.module.scss";

export function NotFound() {
  const t = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t.notFoundHeader}</h1>
      <div>
        <p>{t.notFoundText}</p>
      </div>
    </div>
  );
}
