const availableEnvironments = ["development", "staging", "production"] as const;

export type Environment = (typeof availableEnvironments)[number];

export function envFromHostname(): Environment {
  const { hostname } = window.location;
  if (hostname === "localhost") {
    return "development";
  }
  if (hostname.includes("staging")) {
    return "staging";
  }
  return "production";
}
