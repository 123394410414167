/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ToolAccountingExportService {
    /**
     * export tool accounting records as csv
     * @param tenantId tenant_id
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static exportAccountingRecords(
        tenantId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/resources/tool_accounting_export',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
