import { InteractionStatus } from "@azure/msal-browser";

import { apiScopes } from "~auth/config";
import { fetchToken } from "~auth/useAccessToken";
import { OpenAPI } from "~generated";

import type { PublicClientApplication } from "@azure/msal-browser";

export function initOpenApi(msalInstance: PublicClientApplication) {
  OpenAPI.TOKEN = async () => {
    if (!msalInstance.getActiveAccount()) {
      return "";
    }
    return (
      (await fetchToken(msalInstance, apiScopes, InteractionStatus.None)) ?? ""
    );
  };
}
