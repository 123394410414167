import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { SignOutButton } from "./SignOutButton.tsx";

import styles from "./ErrorPages.module.scss";

export function Forbidden() {
  const t = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t.forbiddenHeader}</h1>
      <div>
        <p>{t.forbiddenText}</p>
        <p className={styles.contacts}>
          {t.forbiddenFooter} ({t.forbiddenContacts})
        </p>
      </div>
      <SignOutButton />
    </div>
  );
}
