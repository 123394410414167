/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ToolHistoryEntry = {
    changes: Record<string, any>;
    type: ToolHistoryEntry.type;
    readonly creator_id: string;
    readonly created_at: string;
};
export namespace ToolHistoryEntry {
    export enum type {
        CREATED = 'created',
        UPDATED = 'updated',
        RESPONSIBILITY_TAKEN = 'responsibility_taken',
        RESPONSIBILITY_RETURNED = 'responsibility_returned',
    }
}

