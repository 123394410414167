/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookedHoursForEmployees } from '../models/BookedHoursForEmployees';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingBookedHoursService {
    /**
     * lists booked hours
     * @param tenantId tenant_id
     * @param startDate start_date
     * @param endDate end_date
     * @param superiorId superior_id
     * @returns BookedHoursForEmployees successful
     * @throws ApiError
     */
    public static getBookedHours(
        tenantId: string,
        startDate: string,
        endDate: string,
        superiorId?: string,
    ): CancelablePromise<BookedHoursForEmployees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/booked_hours',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'superior_id': superiorId,
            },
        });
    }
}
