/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OriginalTeamAssignment } from './OriginalTeamAssignment';
import type { Task } from './Task';
import type { TimeTrackingEvent } from './TimeTrackingEvent';
export type TimeTracking = {
    readonly id: string;
    readonly task_type: TimeTracking.task_type;
    readonly creator_id: string;
    readonly construction_site_manager_id?: string;
    readonly work_package_id?: string;
    readonly task?: Task;
    readonly training_note?: string;
    readonly original_team_assignment?: OriginalTeamAssignment;
    readonly time_tracking_events: Array<TimeTrackingEvent>;
    readonly date: string | null;
};
export namespace TimeTracking {
    export enum task_type {
        WORK_PACKAGE = 'work_package',
        MANAGER_ACCOUNT = 'manager_account',
        TRAINING = 'training',
    }
}

