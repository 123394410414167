import { config } from "~initializer/config.ts";
import { classList } from "~lib/classList.ts";

import type { HTMLAttributes } from "react";

import styles from "./SidebarFooter.module.scss";

interface SidebarFooterProps extends HTMLAttributes<HTMLDivElement> {
  sidebarOpen: boolean;
}

export function SidebarFooter({
  sidebarOpen,
  className,
  ...props
}: SidebarFooterProps) {
  return (
    <div
      className={classList(
        styles.footer,
        sidebarOpen && styles.footer_open,
        className,
      )}
      {...props}
    >
      <div className={styles.footer__footnote}>
        <div className={styles.footerVersionTag}>
          <span>{config().version}</span>
        </div>
        <span className={styles.footerCopyright}>
          © grewe-gruppe {new Date().getFullYear()}
        </span>
      </div>
    </div>
  );
}
