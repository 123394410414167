import { format } from "date-fns/format";

import { useToolDecommissionReasons } from "~components/shared/useToolDecommissionReasons";
import {
  useLocale,
  useTranslation,
} from "~contexts/I18nContext/I18nContext.tsx";
import { Notification } from "~generated";
import { handleError } from "~lib/handleError";
import { getResourceName } from "~lib/resourceHelpers.ts";

import type {
  EmbeddedNotification,
  EmbeddedNotificationBody,
} from "../useEmbeddedNotifications";

export function Description({
  notification,
}: {
  notification: EmbeddedNotification;
}) {
  switch (notification.notification_type) {
    case Notification.notification_type.TOOL_RESPONSIBILITY_TAKEN: {
      return <ToolResponsibilityTakenDescription body={notification.body} />;
    }
    case Notification.notification_type.TOOL_RESPONSIBILITY_RETURNED: {
      return <ToolResponsibilityReturnedDescription body={notification.body} />;
    }
    case Notification.notification_type.TOOL_DATA_CHANGED: {
      return <ToolDataChangedDescription body={notification.body} />;
    }
    case Notification.notification_type.TOOL_PERMANENT_DECOMMISSIONED: {
      return <ToolPermanentDecommissioned notification={notification} />;
    }
    case Notification.notification_type.TOOL_PERMANENT_RECOMMISSIONED: {
      return <ToolPermanentRecommissioned />;
    }
    default:
      handleError(
        new Error(
          `Unsupported notification type ${notification.notification_type} found.`,
        ),
      );
      return (
        <div>Unknown notification type: {notification.notification_type}</div>
      );
  }
}

type Body = {
  body: EmbeddedNotificationBody;
};

function ToolResponsibilityTakenDescription({ body }: Body) {
  const t = useTranslation();

  const responsibleEmployeeName = getResourceName(body.responsibleEmployee);
  const previousResponsibleEmployeeName = getResourceName(
    body.previousResponsibleEmployee,
  );
  if (body.previousResponsibleEmployee) {
    return (
      <div>
        <strong>{t.notificationsDescriptionResponsibleEmployee}</strong>{" "}
        {t.notificationsDescriptionFromTo
          .replace("{from}", previousResponsibleEmployeeName)
          .replace("{to}", responsibleEmployeeName)}
      </div>
    );
  }
  return (
    <div>
      <strong>{t.notificationsDescriptionResponsibleEmployee}</strong>{" "}
      {t.notificationsDescriptionTo.replace("{to}", responsibleEmployeeName)}
    </div>
  );
}

function ToolResponsibilityReturnedDescription({ body }: Body) {
  const t = useTranslation();
  return (
    <div>
      {t.notificationsDescriptionToolReturned.replace(
        "{by}",
        getResourceName(body.responsibleEmployee),
      )}
    </div>
  );
}

function ToolDataChangedDescription({ body }: Body) {
  const t = useTranslation();
  return (
    <div>
      <strong>{t.notificationsDescriptionManager}</strong>{" "}
      {t.notificationsDescriptionFromTo
        .replace("{from}", getResourceName(body.changeSet?.oldManager))
        .replace("{to}", getResourceName(body.changeSet?.newManager))}
    </div>
  );
}

function ToolPermanentDecommissioned({
  notification,
}: {
  notification: EmbeddedNotification;
}) {
  const t = useTranslation();
  const locale = useLocale();
  const { getDecommissionReasonLabel } = useToolDecommissionReasons();
  return (
    <div>
      {t.notificationsDescriptionToolPermanentDecommissioned
        .replace(
          "{decommissionedAt}",
          format(notification.body.decommissionedAt as Date, "P", { locale }),
        )
        .replace("{employee}", getResourceName(notification.sender))
        .replace(
          "{decommissionReason}",
          notification.body.decommissionReason
            ? getDecommissionReasonLabel(notification.body.decommissionReason)
            : t.toolDecommissionReasonUnknown,
        )}
    </div>
  );
}

function ToolPermanentRecommissioned() {
  const t = useTranslation();
  return <div>{t.notificationsDescriptionToolPermanentRecommissioned}</div>;
}
