/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeoPosition } from './GeoPosition';
export type WorkPackage = {
    readonly id?: string;
    tenant_id?: string;
    construction_site_id?: string;
    name: string;
    display_name?: string;
    construction_site_manager_id?: string;
    service_category_id?: string;
    calculated_minutes?: number | null;
    location: string | null;
    location_geo_position?: GeoPosition | null;
    note?: string | null;
    planned_minutes?: number | null;
    wage_type?: WorkPackage.wage_type | null;
    commute_expenses_enabled?: boolean;
    can_modify_accounting_fields?: boolean;
};
export namespace WorkPackage {
    export enum wage_type {
        CHARGEABLE_HOURS = 'chargeable_hours',
        PREPARATION = 'preparation',
    }
}

