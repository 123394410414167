/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrowserGeolocationPosition } from './BrowserGeolocationPosition';
import type { CommuteType } from './CommuteType';
import type { Resource } from './Resource';
import type { TrackingTaskType } from './TrackingTaskType';
export type TimeTrackingStartEventBody = {
    action: TimeTrackingStartEventBody.action;
    start_time: string;
    work_package_id?: string;
    resource: Resource;
    tracking_task_type: TrackingTaskType;
    arrival_commute_type: CommuteType;
    departure_commute_type: CommuteType;
    position: BrowserGeolocationPosition;
};
export namespace TimeTrackingStartEventBody {
    export enum action {
        START = 'start',
    }
}

