import { EventType, PublicClientApplication } from "@azure/msal-browser";

import { config } from "~initializer/config.ts";

import type { AuthenticationResult, EventMessage } from "@azure/msal-browser";

export async function initAuth() {
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: config().msalClientId ?? "",
      authority: config().msalAuthority ?? "",
      redirectUri: config().msalRedirectUri ?? "",
      postLogoutRedirectUri: config().msalRedirectUri ?? "",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  });

  await msalInstance.initialize();

  // Check if there are already accounts in the browser session
  // If so, set the first account as the active account
  const accounts = msalInstance.getAllAccounts();
  if (!msalInstance.getActiveAccount() && accounts && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      // Set the active account - this simplifies token acquisition
      const authResult = event.payload as AuthenticationResult;
      msalInstance.setActiveAccount(authResult.account);
    }
  });

  return msalInstance;
}
