/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TimeTrackingSessionAddEventBody = {
    action: TimeTrackingSessionAddEventBody.action;
    date: string;
    work_package_id: string;
};
export namespace TimeTrackingSessionAddEventBody {
    export enum action {
        SESSION_ADD = 'session_add',
    }
}

