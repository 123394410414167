// TODO: until https://github.com/microsoft/TypeScript/issues/40793 is not fixed, we
// have to double the group names as string here. When fixed, we should replace
// these strings with strings imported from grewe-libs/src/v1/defaults/abilityGroups.ts
export enum Ability {
  canAccessApp = "GGA_CanAccessApp",
  canManageConstructionSites = "GGA_CanManageConstructionSites",
  canAddUsersToTenants = "GGA_CanAddUsersToTenants",
  canEditConstructionSiteTeams = "GGA_CanEditConstructionSiteTeams",
  canAccessPlanning = "GGA_CanAccessPlanning",
  canApproveTimeEntries = "GGA_CanApproveTimeEntries",
  canManageTimeEntries = "GGA_CanManageTimeEntries",
  canExportTimeEntries = "GGA_CanExportTimeEntries",
  canMonitorActiveTimeEntries = "GGA_CanMonitorActiveTimeEntries",
  canAccessAppStats = "GGA_CanAccessAppStats",
  canAccessAppStatsUsers = "GGA_CanAccessAppStatsUsers",
  canAccessAppAdmin = "GGA_CanAccessAppAdmin",
  canManageTools = "GGA_CanManageTools",
  canManageToolsAccounting = "GGA_CanManageToolsAccounting",
  canChangeToolManager = "GGA_CanChangeToolManager",
  canExportTools = "GGA_CanExportTools",
  canExportEmployeeTimeEntries = "GGA_CanExportEmployeeTimeEntries",
  canExportEmployees = "GGA_CanExportEmployees",
  canViewOrganigram = "GGA_CanViewOrganigram",
  canManageOrganigram = "GGA_CanManageOrganigram",
  canManageAbsences = "GGA_CanManageAbsences",
  canAccessControlling = "GGA_CanAccessControlling",
  canManageSurchargeRate = "GGA_CanManageSurchargeRate",
  canAccessBookedHoursOfEachEmployee = "GGA_CanAccessBookedHoursOfEachEmployee",
  canAccessHoursEvaluation = "GGA_CanAccessHoursEvaluation",
  canManageBudget = "GGA_CanManageBudget",
  canViewDistanceToWorkLocation = "GGA_CanViewDistanceToWorkLocation",
  canAccessWageTypes = "GGA_CanAccessWageTypes",
}

export const anyAbility = Object.values(Ability);
