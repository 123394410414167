/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ToolDecommissionReason {
    UNKNOWN = 'unknown',
    SCRAP = 'scrap',
    INVENTORY_SHRINKAGE = 'inventory_shrinkage',
    SALE = 'sale',
    THEFT = 'theft',
    RETURN_AFTER_LEASING = 'return_after_leasing',
    LONG_TERM_RENTAL_ENDED = 'long_term_rental_ended',
    ERROR = 'error',
}
