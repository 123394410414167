import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import orderBy from "lodash/orderBy";
import { useMemo } from "react";

import { useToast } from "~components/shared/useToast.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { WorkPackagesService } from "~generated";

import { allEmployeeKeys } from "./employees";
import { managerKeys } from "./managerKeys.ts";
import { teamAssignmentKeys } from "./teamAssignmentKeys";
import { tenantKeys } from "./tenants";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { WorkPackage } from "~generated";

const workPackageKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "workPackages"] as const,
  list: (tenantId: string) =>
    [...workPackageKeys.base(tenantId), "list"] as const,
  detail: (tenantId: string, id: string) =>
    [...workPackageKeys.base(tenantId), "detail", id] as const,
};

async function getWorkPackages(tenantId: string) {
  const workPackages = await WorkPackagesService.getWorkPackages(tenantId);
  if (!workPackages || !workPackages.work_packages) {
    return [];
  }
  return workPackages.work_packages;
}

type UseWorkPackageOptions = UseQueryOptions<
  WorkPackage[],
  Error,
  WorkPackage[],
  ReturnType<typeof workPackageKeys.list>
>;

export function useWorkPackages(options?: Partial<UseWorkPackageOptions>) {
  const currentTenant = useCurrentTenant();
  const query = useQuery({
    ...options,
    queryKey: workPackageKeys.list(currentTenant.id),
    queryFn: (): Promise<WorkPackage[]> => getWorkPackages(currentTenant.id),
  });
  return {
    ...query,
    data: query.data || [],
  };
}

type WorkPackageSorting = {
  orderField?: keyof WorkPackage;
  direction?: "asc" | "desc";
};

export function useSortedWorkPackages({
  orderField,
  direction,
}: WorkPackageSorting = {}) {
  const { data: workPackages, isLoading, ...query } = useWorkPackages();

  const sortedWorkPackages = useMemo(() => {
    if (!workPackages) {
      return undefined;
    }
    return orderBy(workPackages, orderField, direction ?? "asc");
  }, [workPackages, orderField, direction]);

  return { ...query, isLoading, data: sortedWorkPackages };
}

export function useWorkPackage(
  id?: string | null,
  options?: Partial<UseWorkPackageOptions>,
) {
  const query = useWorkPackages(options);
  return useMemo(
    () => ({
      ...query,
      data: query.data?.find((e) => e.id === id),
    }),
    [id, query],
  );
}

export function useWorkPackagesByConstructionSite(constructionSiteId: string) {
  const query = useWorkPackages();
  return {
    ...query,
    data:
      query.data?.filter(
        (w) => w.construction_site_id === constructionSiteId,
      ) ?? [],
  };
}

export function useCreateWorkPackage() {
  const currentTenant = useCurrentTenant();
  const { showSuccessToast } = useToast();
  const t = useTranslation();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (workPackage: WorkPackage) =>
      WorkPackagesService.createWorkPackage(currentTenant.id, workPackage),

    onSuccess: async () => {
      showSuccessToast(t.addWorkPackageSuccess);
      await queryClient.invalidateQueries({
        queryKey: workPackageKeys.base(currentTenant.id),
      });
      await queryClient.invalidateQueries({
        queryKey: allEmployeeKeys.list(),
      });
      await queryClient.invalidateQueries({
        queryKey: managerKeys.list(currentTenant.id),
      });
    },
  });
  return mutation;
}

export function useEditWorkPackage(id: string) {
  const currentTenant = useCurrentTenant();
  const { showSuccessToast } = useToast();
  const t = useTranslation();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (workPackage: WorkPackage) =>
      WorkPackagesService.updateWorkPackage(currentTenant.id, id, workPackage),
    onSuccess: async () => {
      showSuccessToast(t.editSuccess);
      await queryClient.invalidateQueries({
        queryKey: workPackageKeys.base(currentTenant.id),
      });
      await queryClient.invalidateQueries({
        queryKey: teamAssignmentKeys.list(currentTenant.id),
      });
      await queryClient.invalidateQueries({
        queryKey: allEmployeeKeys.list(),
      });
      await queryClient.invalidateQueries({
        queryKey: managerKeys.list(currentTenant.id),
      });
    },
  });
  return mutation;
}
