import { lazy } from "react";

// planning group
export const LazyConstructionSiteList = lazy(
  () =>
    import(
      "~components/Planning/ConstructionSiteList/ConstructionSiteList.tsx"
    ),
);

export const LazyConstructionSitePlanning = lazy(
  () =>
    import(
      "~components/Planning/ConstructionSitePlanning/ConstructionSitePlanning"
    ),
);
export const LazyWorkPackageAdministration = lazy(
  () =>
    import(
      "~components/Planning/WorkPackageAdministration/WorkPackageAdministration"
    ),
);

export const LazyTeamPlanning = lazy(
  () => import("~components/Planning/TeamPlanning/TeamPlanning.tsx"),
);

export const LazyTeamAdministration = lazy(
  () =>
    import("~components/Planning/TeamAdministration/TeamAdministration.tsx"),
);

export const LazyAbsences = lazy(
  () => import("~components/Planning/Absences/Absences.tsx"),
);

// time group
export const LazyTimeTrackingOverview = lazy(
  () => import("~components/TimeManagement/TimeTracking/Overview/Overview.tsx"),
);

export const LazyTimeTrackingStartViewWorkPackage = lazy(
  () =>
    import(
      "~components/TimeManagement/TimeTracking/StartView/WorkPackage/StartViewWorkPackage.tsx"
    ),
);

export const LazyTimeTrackingStartViewManagerAccount = lazy(
  () =>
    import(
      "~components/TimeManagement/TimeTracking/StartView/ManagerAccount/StartViewManagerAccount.tsx"
    ),
);

export const LazyTimeTrackingStartViewTraining = lazy(
  () =>
    import(
      "~components/TimeManagement/TimeTracking/StartView/Training/StartViewTraining.tsx"
    ),
);

export const LazyTimeTrackingRunningView = lazy(
  () =>
    import(
      "~components/TimeManagement/TimeTracking/RunningView/RunningView.tsx"
    ),
);

export const LazyDailySummary = lazy(
  () =>
    import(
      "~components/TimeManagement/TimeTracking/Overview/DailySummary/DailySummary.tsx"
    ),
);

export const LazyMyTimeEntries = lazy(
  () => import("~components/TimeManagement/MyTimeEntries/MyTimeEntries.tsx"),
);

export const LazyMonitoring = lazy(
  () => import("~components/TimeManagement/Monitoring/Monitoring.tsx"),
);

export const LazyTimeApproval = lazy(
  () => import("~components/TimeManagement/TimeApproval/TimeApproval.tsx"),
);

export const LazyTimeApprovalExport = lazy(
  () => import("~components/TimeManagement/TimeApproval/Export/Export.tsx"),
);

// resources group
export const LazyToolAdministration = lazy(
  () =>
    import("~components/Resources/ToolAdministration/ToolAdministration.tsx"),
);

export const LazyToolsCostCenters = lazy(
  () => import("~components/Resources/ToolsCostCenters/ToolsCostCenters.tsx"),
);

export const LazyEmployeeAdministration = lazy(
  () =>
    import(
      "~components/Resources/EmployeeAdministration/EmployeeAdministration.tsx"
    ),
);

// health group
export const LazyInvalidConstructionSitesOverview = lazy(
  () =>
    import(
      "~components/HealthBoard/InvalidConstructionSites/InvalidConstructionSitesOverview.tsx"
    ),
);

export const LazyInvalidProjectsOverview = lazy(
  () =>
    import(
      "~components/HealthBoard/InvalidProjects/InvalidProjectsOverview.tsx"
    ),
);

export const LazyInvalidToolsOverview = lazy(
  () => import("~components/HealthBoard/InvalidTools/InvalidToolsOverview.tsx"),
);

export const LazyStats = lazy(
  () => import("~components/HealthBoard/Stats/Stats.tsx"),
);

// controlling group
export const LazyProjectsJournal = lazy(
  () => import("~components/Controlling/ProjectsJournal/ProjectsJournal.tsx"),
);

export const LazySurcharges = lazy(
  () => import("~components/Controlling/Surcharges/Surcharges.tsx"),
);

export const LazyWageTypes = lazy(
  () => import("~components/Controlling/WageTypes/WageTypes"),
);

export const LazyBookedHours = lazy(
  () => import("~components/Controlling/BookedHours/BookedHours.tsx"),
);

export const LazyKeyFigures = lazy(
  () => import("~components/Controlling/KeyFigures/KeyFigures.tsx"),
);

export const LazyHoursEvaluation = lazy(
  () => import("~components/Controlling/HoursEvaluation/HoursEvaluation.tsx"),
);

// hidden routes
export const LazyOrganigram = lazy(
  () => import("~components/Resources/Organigram/Organigram"),
);

export const LazyAbilities = lazy(
  () => import("~components/App/Abilities/Abilities.tsx"),
);
