import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { apiScopes } from "./config";

export function useLogin() {
  const { instance, inProgress } = useMsal();
  const loading = inProgress !== InteractionStatus.None;

  return {
    login: async () => {
      if (!loading) {
        await instance.loginRedirect({ scopes: apiScopes });
      }
    },
    loading,
  };
}
