import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns/minutesToMilliseconds";
import { useMemo } from "react";

import { optimisticallyUpdateSyncStatusToRunning } from "~api/sync.ts";
import { tenantKeys } from "~api/tenants.ts";
import { useToast } from "~components/shared/useToast.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { EmployeesService } from "~generated";
import { getResourceName } from "~lib/resourceHelpers.ts";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { CurrentEmployeeUpdate, Employee } from "~generated";

export const employeeKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "employees"] as const,
  list: (tenantId: string) => [...employeeKeys.base(tenantId), "list"] as const,
  detail: (tenantId: string, id: string) =>
    [...employeeKeys.base(tenantId), "detail", id] as const,
  syncStatus: (tenantId: string) =>
    [...employeeKeys.base(tenantId), "syncStatus"] as const,
};

export const allEmployeeKeys = {
  base: () => ["employees"] as const,
  list: () => [...allEmployeeKeys.base(), "list"] as const,
  me: () => [...allEmployeeKeys.base(), "me"],
};

async function getEmployees({ tenantId }: { tenantId: string }) {
  const employees = await EmployeesService.getTenantEmployees(tenantId);
  if (!employees || !employees.employees) {
    return [];
  }
  return employees.employees.sort((a, b) =>
    getResourceName(a).localeCompare(getResourceName(b)),
  );
}

export function useEmployeesSyncStatus() {
  const currentTenant = useCurrentTenant();

  return useQuery({
    queryKey: employeeKeys.syncStatus(currentTenant.id),
    queryFn: async () =>
      EmployeesService.getEmployeesSyncStatus(currentTenant.id),
    refetchInterval: 5000,
  });
}

export function useSyncEmployees() {
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => EmployeesService.syncEmployees(currentTenant.id),
    onMutate: () => {
      optimisticallyUpdateSyncStatusToRunning({
        queryKey: employeeKeys.syncStatus(currentTenant.id),
        queryClient,
      });
    },
  });
}

interface UseEmployeesQueryOptions {
  includeResignedEmployees?: boolean;
  useQueryOptions?: Partial<
    UseQueryOptions<
      Employee[],
      Error,
      Employee[],
      ReturnType<typeof employeeKeys.list>
    >
  >;
}
export function useEmployees(
  {
    includeResignedEmployees = false,
    useQueryOptions,
  }: UseEmployeesQueryOptions = {
    includeResignedEmployees: false,
  },
) {
  const currentTenant = useCurrentTenant();

  const queryResult = useQuery({
    ...useQueryOptions,
    queryKey: employeeKeys.list(currentTenant.id),
    queryFn: async () => getEmployees({ tenantId: currentTenant.id }),
  });

  const filteredData = useMemo(
    () =>
      queryResult.data?.filter(
        (employee) => includeResignedEmployees || !employee.resigned_at,
      ),
    [includeResignedEmployees, queryResult.data],
  );

  return {
    ...queryResult,
    data: filteredData,
  };
}

export function useEmployee(
  id?: string | null,
  options?: UseEmployeesQueryOptions,
) {
  const query = useEmployees(options);
  return useMemo(
    () => ({
      ...query,
      data: query.data?.find((e) => e.id === id),
    }),
    [id, query],
  );
}

export function useUpdateCurrentEmployee() {
  const t = useTranslation();
  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();
  const currentTenant = useCurrentTenant();

  const mutation = useMutation({
    mutationFn: (body: CurrentEmployeeUpdate) =>
      EmployeesService.updateCurrentEmployee(body),

    onSuccess: async () => {
      showSuccessToast(t.SettingsWereSaved);
      await queryClient.invalidateQueries({ queryKey: allEmployeeKeys.base() });
      await queryClient.invalidateQueries({
        queryKey: employeeKeys.base(currentTenant.id),
      });
    },
  });
  return mutation;
}

async function getAllEmployees() {
  const employees = await EmployeesService.getEmployees();
  if (!employees || !employees.employees) {
    return [];
  }
  return employees.employees.sort((a, b) =>
    getResourceName(a).localeCompare(getResourceName(b)),
  );
}

export function useAllEmployees(
  options?: Partial<
    UseQueryOptions<
      Employee[],
      Error,
      Employee[],
      ReturnType<typeof allEmployeeKeys.list>
    >
  >,
) {
  return useQuery({
    ...options,
    queryKey: allEmployeeKeys.list(),
    queryFn: () => getAllEmployees(),
  });
}

export function useCurrentEmployee(
  options?: Partial<
    UseQueryOptions<
      Employee,
      Error,
      Employee,
      ReturnType<typeof allEmployeeKeys.me>
    >
  >,
) {
  return useQuery({
    ...options,
    queryKey: allEmployeeKeys.me(),
    queryFn: () => EmployeesService.getCurrentEmployee(),
    staleTime: minutesToMilliseconds(1),
  });
}
