import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { type TableBodyProps } from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { type TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { type TableContainerProps } from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { type TableHeadProps } from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { type TableRowProps } from "@mui/material/TableRow";
import React from "react";

import { classList } from "~lib/classList";

import { ListHeadingTableCell } from "../ListHeading/ListHeading";

import type { PaperProps } from "@mui/material/Paper";

import styles from "./GroupedTable.module.scss";

export function GroupedTable({
  children,
  className,
  ...props
}: TableContainerProps) {
  return (
    <TableContainer
      className={classList(styles.tableContainer, className)}
      {...props}
    >
      <Table className={styles.table} stickyHeader>
        {children}
      </Table>
    </TableContainer>
  );
}

export interface GroupProps extends TableBodyProps {
  groupTitle: React.ReactNode;
}

export function GroupedTableItem({
  groupTitle,
  children,
  ...props
}: GroupProps) {
  return (
    <TableBody {...props}>
      <TableRow>
        <ListHeadingTableCell colSpan={100} isSticky>
          {groupTitle}
        </ListHeadingTableCell>
      </TableRow>
      {children}
    </TableBody>
  );
}

type GroupedTableInfoResultProps = {
  text: string;
  imageUrl?: string;
};
export function GroupedTableInfoResult({
  text,
  imageUrl,
}: GroupedTableInfoResultProps) {
  return (
    <TableBody>
      <GroupedTableItemRow variant={"outlined"}>
        <GroupedTableItemCell colSpan={100}>
          <div className={styles.infoResult}>
            {imageUrl && (
              <img
                className={styles.infoResult__image}
                src={`${process.env.PUBLIC_URL}/motorsaege_stihl.svg`}
              />
            )}
            <div
              className={styles.infoResult__text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        </GroupedTableItemCell>
      </GroupedTableItemRow>
    </TableBody>
  );
}

type GroupedTableErrorResultProps = {
  text: string;
};
export function GroupedTableErrorResult({
  text,
}: GroupedTableErrorResultProps) {
  return (
    <GroupedTableInfoResult
      text={text}
      imageUrl={`${process.env.PUBLIC_URL}/motorsaege_stihl.svg`}
    />
  );
}

export function GroupedTableItemRow({
  children,
  className,
  variant = "elevation",
  ...props
}: TableRowProps & Pick<PaperProps, "variant">) {
  return (
    <Paper
      variant={variant}
      component={TableRow}
      className={classList(
        styles.itemRow,
        styles[`itemRow_${variant}`],
        className,
      )}
      {...props}
    >
      {children}
    </Paper>
  );
}

export function GroupedTableItemCell({
  children,
  className,
  ...props
}: TableCellProps) {
  return (
    <TableCell {...props} className={classList(styles.itemCell, className)}>
      {children}
    </TableCell>
  );
}

export function GroupedTableHead({
  children,
  className,
  ...props
}: TableHeadProps) {
  return (
    <TableHead className={classList(styles.tableHead, className)} {...props}>
      <Paper component={TableRow} className={styles.tableHead__row}>
        {children}
      </Paper>
    </TableHead>
  );
}

export function GroupedTableHeadCell({
  children,
  className,
  ...props
}: TableCellProps) {
  return (
    <TableCell className={className} {...props}>
      {children}
    </TableCell>
  );
}
