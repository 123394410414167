/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamAssignment } from '../models/TeamAssignment';
import type { TeamAssignments } from '../models/TeamAssignments';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TeamAssignmentsService {
    /**
     * list team_assignments
     * @param tenantId tenant_id
     * @returns TeamAssignments successful
     * @throws ApiError
     */
    public static getTeamAssignments(
        tenantId: string,
    ): CancelablePromise<TeamAssignments> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/team_assignments',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * update team assignment
     * @param tenantId tenant_id
     * @param id id
     * @param requestBody
     * @returns TeamAssignment team assignment updated
     * @throws ApiError
     */
    public static updateTeamAssignment(
        tenantId: string,
        id: string,
        requestBody?: TeamAssignment,
    ): CancelablePromise<TeamAssignment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/tenants/{tenant_id}/team_assignments/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }
}
