import { createTheme } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    containedInverted: true;
  }
}

export const GreweTheme = createTheme({
  typography: {
    body1: {
      color: "var(--color-grey-1)",
    },
    body2: {
      color: "white",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "700",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "500",
      lineHeight: "1.25",
    },
    h4: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
      margin: "0 0 1rem 0",
      letterSpacing: "1px",
      fontWeight: "bold",
    },
  },
  shadows: [
    "none",
    `0 0 3px 0 rgb(0 0 0 / 0.05)`,
    `0 0 4px 0 rgb(0 0 0 / 0.05)`,
    `0 0 5px 0 rgb(0 0 0 / 0.10)`,
    `0 0 6px 0 rgb(0 0 0 / 0.10)`,
    `0 0 7px 0 rgb(0 0 0 / 0.15)`,
    `0 0 8px 0 rgb(0 0 0 / 0.15)`,
    `0 0 9px 0 rgb(0 0 0 / 0.20)`,
    `0 0 10px 0 rgb(0 0 0 / 0.20)`,
    `0 0 11px 0 rgb(0 0 0 / 0.21)`,
    `0 0 12px 0 rgb(0 0 0 / 0.22)`,
    `0 0 13px 0 rgb(0 0 0 / 0.23)`,
    `0 0 14px 0 rgb(0 0 0 / 0.24)`,
    `0 0 15px 0 rgb(0 0 0 / 0.25)`,
    `0 0 16px 0 rgb(0 0 0 / 0.25)`,
    `0 0 17px 0 rgb(0 0 0 / 0.25)`,
    `0 0 18px 0 rgb(0 0 0 / 0.25)`,
    `0 0 19px 0 rgb(0 0 0 / 0.25)`,
    `0 0 20px 0 rgb(0 0 0 / 0.25)`,
    `0 0 21px 0 rgb(0 0 0 / 0.25)`,
    `0 0 22px 0 rgb(0 0 0 / 0.25)`,
    `0 0 23px 0 rgb(0 0 0 / 0.25)`,
    `0 0 24px 0 rgb(0 0 0 / 0.25)`,
    `0 0 25px 0 rgb(0 0 0 / 0.25)`,
    `0 0 26px 0 rgb(0 0 0 / 0.25)`,
  ],
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true },
      variants: [
        {
          props: { variant: "containedInverted" },
          style: {
            color: "var(--color-blue-1)",
            backgroundColor: "rgba(var(--color-blue-1-rgb), 0.15)",
            ":hover": {
              backgroundColor: "rgba(var(--color-blue-1-rgb), 0.25)",
            },
          },
        },
        {
          props: { variant: "containedInverted", color: "secondary" },
          style: {
            color: "var(--color-grey-1)",
            backgroundColor: "var(--color-grey-5)",
            ":hover": {
              backgroundColor: "var(--color-grey-4)",
            },
          },
        },
      ],
      styleOverrides: {
        sizeSmall: {
          fontSize: "0.75rem",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === "secondary" && {
            color: "var(--color-grey-1)",
            backgroundColor: "var(--color-grey-5)",
            ":hover": {
              backgroundColor: "var(--color-grey-4)",
            },
          }),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "elevation" && {
            color: "var(--color-grey-1)",
            border: 0,
          }),
          ...(ownerState.variant === "outlined" && {
            borderColor: "var(--color-grey-4)",
            backgroundColor: "var(--color-grey-5)",
          }),
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "var(--color-grey-1)",
          fontSize: "1.5rem",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: "var(--color-grey-1)",
          paddingTop: "0",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(
            2,
          )} ${theme.spacing(3)}`,
          position: "sticky",
          bottom: 0,
          zIndex: 10,
          background: "var(--color-white)",
          "::before": {
            content: '""',
            display: "block",
            height: theme.spacing(3.5),
            width: "100%",
            position: "absolute",
            left: "0",
            bottom: "100%",
            background:
              "linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
          },
        }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          position: "relative",
          overflow: "auto",
          height: "100%",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "var(--color-grey-1)",
          borderColor: "var(--color-grey-4)",
          backgroundColor: "var(--color-white)",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundClip: "padding-box",
        },
        head: {
          height: "var(--table-head-size)",
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: "1rem",
          paddingRight: "1rem",
          textTransform: "uppercase",
          fontSize: "0.7rem",
          letterSpacing: "1px",
          lineHeight: "1",
          color: "var(--color-grey-1)",
          fontWeight: 500,
          position: "relative",
          ":after": {
            content: "''",
            height: "16px",
            width: "1px",
            backgroundColor: "var(--color-grey-4)",
            position: "absolute",
            left: 0,
            borderRadius: "1px",
            top: "50%",
            transform: "translateY(-50%)",
          },
          ":first-of-type::after": {
            content: "none",
          },
        },
        stickyHeader: {
          position: "sticky",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover": {
            "> .MuiTableCell-root": {
              cursor: "pointer",
              transition: "var(--transition)",
            },
          },
          "&.MuiTableRow-hover:hover": {
            "> .MuiTableCell-root": {
              backgroundColor: "var(--color-grey-5)",
            },
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            "> .MuiTableCell-root": {
              backgroundColor: "var(--color-blue-5)",
              color: "var(--color-blue-1)",
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:last-child > .MuiTableCell-root": {
            borderBottom: 0,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
      defaultProps: {
        animation: "wave",
        variant: "rectangular",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: ({ theme }) => ({
          boxShadow: theme.shadows[8],
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
        color: "primary",
      },
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      variants: [
        {
          props: { color: "primary" },
          style: {
            backgroundColor: "var(--color-white)",
            "&.Mui-focused": {
              backgroundColor: "var(--color-white)",
              borderColor: "rgba(var(--color-blue-1-rgb), 1)",
            },
          },
        },
        {
          props: { color: "secondary" },
          style: {
            backgroundColor: "var(--main-bg)",
            "&.Mui-focused": {
              backgroundColor: "var(--color-white)",
              borderColor: "rgba(var(--color-blue-1-rgb), 1)",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          width: "100%",
          border: "1px solid transparent",
          borderRadius: "var(--border-radius)",
          transition: "var(--transition)",
          overflow: "hidden",
        },
        input: {
          "&:focus": { backgroundColor: "var(--color-white)" },
        },
        adornedEnd: {
          "> .MuiSvgIcon-root": {
            color: "var(--color-grey-1)",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          "&.Mui-focused": {
            color: "var(--color-blue-1)",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: ({ theme }) => ({
          boxShadow: theme.shadows[5],
        }),
      },
    },
  },
});
