/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Abbreviation } from '../models/Abbreviation';
import type { AdditionalData } from '../models/AdditionalData';
import type { CategoryId } from '../models/CategoryId';
import type { Employees } from '../models/Employees';
import type { Manufacturers } from '../models/Manufacturers';
import type { ToolDetail } from '../models/ToolDetail';
import type { ToolForm } from '../models/ToolForm';
import type { ToolHistoryResponse } from '../models/ToolHistoryResponse';
import type { ToolId } from '../models/ToolId';
import type { Tools } from '../models/Tools';
import type { Validation } from '../models/Validation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ToolAdministrationService {
    /**
     * get next abbreviation
     * @param requestBody
     * @returns Abbreviation next abbreviation
     * @throws ApiError
     */
    public static nextAbbreviation(
        requestBody?: CategoryId,
    ): CancelablePromise<Abbreviation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/tool_administration/abbreviations/next',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * validate abbreviation
     * @param requestBody
     * @returns Validation validate abbreviation
     * @throws ApiError
     */
    public static validateAbbreviation(
        requestBody?: Abbreviation,
    ): CancelablePromise<Validation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/tool_administration/abbreviations/validate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns additional data
     * @returns AdditionalData successful
     * @throws ApiError
     */
    public static getAdditionalData(): CancelablePromise<AdditionalData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/additional_data',
        });
    }
    /**
     * list tool history
     * @param id id
     * @returns ToolHistoryResponse successful
     * @throws ApiError
     */
    public static getToolHistory(
        id: string,
    ): CancelablePromise<ToolHistoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/tools/{id}/history',
            path: {
                'id': id,
            },
        });
    }
    /**
     * list all managers for tools
     * @returns Employees successful
     * @throws ApiError
     */
    public static getManagers(): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/managers',
        });
    }
    /**
     * list all tool manufacturers once in order
     * @returns Manufacturers successful
     * @throws ApiError
     */
    public static getManufacturers(): CancelablePromise<Manufacturers> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/manufacturers',
        });
    }
    /**
     * list all employees responsible for tools
     * @returns Employees successful
     * @throws ApiError
     */
    public static getResponsibleEmployees(): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/responsible_employees',
        });
    }
    /**
     * get next tool_id
     * @param requestBody
     * @returns ToolId next tool_id
     * @throws ApiError
     */
    public static nextToolId(
        requestBody?: CategoryId,
    ): CancelablePromise<ToolId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/tool_administration/tool_ids/next',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * validate tool_id
     * @param requestBody
     * @returns Validation validate tool_id
     * @throws ApiError
     */
    public static validateToolId(
        requestBody?: ToolId,
    ): CancelablePromise<Validation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/tool_administration/tool_ids/validate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * list tools
     * @param page
     * @param active
     * @param billable
     * @param categoryId
     * @param limit
     * @param searchText
     * @param serialNumber
     * @param withoutResponsibleEmployee
     * @param responsibleEmployeeIdsArray
     * @param managerIdsArray
     * @param tenantIdsArray
     * @returns Tools successful
     * @throws ApiError
     */
    public static getTools(
        page?: number,
        active?: boolean,
        billable?: boolean,
        categoryId?: string,
        limit?: number,
        searchText?: string,
        serialNumber?: string,
        withoutResponsibleEmployee?: boolean,
        responsibleEmployeeIdsArray?: Array<string>,
        managerIdsArray?: Array<string>,
        tenantIdsArray?: Array<string>,
    ): CancelablePromise<Tools> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/tools',
            query: {
                'page': page,
                'active': active,
                'billable': billable,
                'category_id': categoryId,
                'limit': limit,
                'search_text': searchText,
                'serial_number': serialNumber,
                'without_responsible_employee': withoutResponsibleEmployee,
                'responsible_employee_ids[]': responsibleEmployeeIdsArray,
                'manager_ids[]': managerIdsArray,
                'tenant_ids[]': tenantIdsArray,
            },
        });
    }
    /**
     * create new tool
     * @param requestBody
     * @returns ToolDetail tool created
     * @throws ApiError
     */
    public static createTool(
        requestBody?: ToolForm,
    ): CancelablePromise<ToolDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/tool_administration/tools',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }
    /**
     * tool details
     * @param id id
     * @returns ToolDetail successful
     * @throws ApiError
     */
    public static getToolDetail(
        id: string,
    ): CancelablePromise<ToolDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/tools/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * update tool
     * @param id id
     * @param xOriginalRequestAt
     * @param requestBody
     * @returns ToolDetail tool updated
     * @throws ApiError
     */
    public static updateTool(
        id: string,
        xOriginalRequestAt?: string,
        requestBody?: ToolForm,
    ): CancelablePromise<ToolDetail> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/resources/tool_administration/tools/{id}',
            path: {
                'id': id,
            },
            headers: {
                'X-Original-Request-At': xOriginalRequestAt,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }
    /**
     * export all tools as csv
     * @param active
     * @param billable
     * @param categoryId
     * @param searchText
     * @param serialNumber
     * @param withoutResponsibleEmployee
     * @param responsibleEmployeeIdsArray
     * @param managerIdsArray
     * @param tenantIdsArray
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static exportTools(
        active?: boolean,
        billable?: boolean,
        categoryId?: string,
        searchText?: string,
        serialNumber?: string,
        withoutResponsibleEmployee?: boolean,
        responsibleEmployeeIdsArray?: Array<string>,
        managerIdsArray?: Array<string>,
        tenantIdsArray?: Array<string>,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/tool_administration/tools/export',
            query: {
                'active': active,
                'billable': billable,
                'category_id': categoryId,
                'search_text': searchText,
                'serial_number': serialNumber,
                'without_responsible_employee': withoutResponsibleEmployee,
                'responsible_employee_ids[]': responsibleEmployeeIdsArray,
                'manager_ids[]': managerIdsArray,
                'tenant_ids[]': tenantIdsArray,
            },
        });
    }
}
