/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WageTypes } from '../models/WageTypes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingWageTypesService {
    /**
     * list wage types
     * @param tenantId tenant_id
     * @returns WageTypes successful
     * @throws ApiError
     */
    public static getWageTypes(
        tenantId: string,
    ): CancelablePromise<WageTypes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/wage_types',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * export wage types as csv
     * @param tenantId tenant_id
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static export(
        tenantId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/wage_types/export',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
