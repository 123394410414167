import { useNavigate } from "react-router-dom";

import { ROUTES } from "~components/routeDefinitions.ts";
import {
  Priority,
  TaskBadge,
  TaskBar,
} from "~components/shared/TaskBar/TaskBar.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { useEmployeeAdministrationTaskBar } from "./useEmployeeAdministrationTaskBar.ts";

import type { TaskBarProps } from "~components/shared/TaskBar/TaskBar.tsx";

export function EmployeeAdministrationTaskBar({
  variant = "horizontal",
  limitTo,
}: {
  variant?: TaskBarProps["variant"];
  limitTo?: "superiorMissing" | "ambiguousAddress";
}) {
  const { superiorMissingCount, ambiguousAddressCount } =
    useEmployeeAdministrationTaskBar();
  const t = useTranslation();
  const navigate = useNavigate();

  const { number: tenantNumber } = useCurrentTenant();
  if (
    superiorMissingCount === undefined &&
    ambiguousAddressCount === undefined
  ) {
    return null;
  }
  const showSuperiorMissing =
    superiorMissingCount !== undefined &&
    (limitTo === undefined || limitTo === "superiorMissing");
  const showAmbiguousAddress =
    ambiguousAddressCount !== undefined &&
    (limitTo === undefined || limitTo === "ambiguousAddress");
  return (
    <TaskBar variant={variant}>
      {showSuperiorMissing && (
        <TaskBadge
          count={superiorMissingCount}
          priority={superiorMissingCount > 0 ? Priority.HIGH : Priority.NORMAL}
          text={
            superiorMissingCount === 1
              ? t.employeeAdministrationSuperiorAssignmentMissingSingular
              : t.employeeAdministrationSuperiorAssignmentMissingPlural
          }
          onClick={() => {
            navigate(
              ROUTES.employees.link(
                { tenantNumber },
                new URLSearchParams({ filterOption: "assignmentPending" }),
              ),
            );
          }}
        />
      )}
      {showAmbiguousAddress && (
        <TaskBadge
          count={ambiguousAddressCount}
          priority={ambiguousAddressCount > 0 ? Priority.HIGH : Priority.NORMAL}
          text={
            ambiguousAddressCount === 1
              ? t.employeeAdministrationAmbiguousAddress
              : t.employeeAdministrationAmbiguousAddresses
          }
          onClick={() => {
            navigate(
              ROUTES.employees.link(
                { tenantNumber },
                new URLSearchParams({ filterOption: "ambiguousAddress" }),
              ),
            );
          }}
        />
      )}
    </TaskBar>
  );
}
