/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ControllingMonthlyTargets } from '../models/ControllingMonthlyTargets';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingMonthlyTargetsService {
    /**
     * upload monthly targets
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateControllingMonthlyTargets(
        requestBody?: ControllingMonthlyTargets,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/controlling/monthly_targets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `invalid request`,
            },
        });
    }
    /**
     * export monthly targets as csv
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static export(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/controlling/monthly_targets/export',
        });
    }
}
