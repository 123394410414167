/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employee } from '../models/Employee';
import type { EmployeeUpdate } from '../models/EmployeeUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmployeeAdministrationService {
    /**
     * update employee
     * @param tenantId tenant_id
     * @param id id
     * @param requestBody
     * @returns Employee superior can update employee residence address
     * @throws ApiError
     */
    public static updateTenantEmployee(
        tenantId: string,
        id: string,
        requestBody?: EmployeeUpdate,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/tenants/{tenant_id}/resources/employee_administration/employees/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad entity`,
                422: `invalid request`,
            },
        });
    }
    /**
     * export employees for controlling as csv
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static exportControlling(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/employee_administration/export_controlling',
        });
    }
    /**
     * export employees as csv
     * @param tenantId tenant_id
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static exportPurchasing(
        tenantId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/resources/employee_administration/export_purchasing',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
