/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingApproveEventBody = {
    action: TimeTrackingApproveEventBody.action;
    reference: string;
    resource: Resource;
};
export namespace TimeTrackingApproveEventBody {
    export enum action {
        APPROVE = 'approve',
    }
}

