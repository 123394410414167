/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SerialDateRepetitionWeekDay } from './SerialDateRepetitionWeekDay';
export type SerialDate = {
    id: string;
    repetition_interval: number;
    repetition_unit: SerialDate.repetition_unit;
    repetition_weekdays?: Array<SerialDateRepetitionWeekDay> | null;
    repetition_month_type?: SerialDate.repetition_month_type | null;
    end_after_number_of_dates?: number | null;
    end_on_date?: string | null;
};
export namespace SerialDate {
    export enum repetition_unit {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }
    export enum repetition_month_type {
        DAY = 'day',
        DATE = 'date',
    }
}

