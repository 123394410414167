import LocationOff from "@mui/icons-material/LocationOff";
import LocationOn from "@mui/icons-material/LocationOn";

import { useTranslation } from "~contexts/I18nContext/I18nContext";

import { TooltipStack } from "../OnHoverTooltip/OnHoverTooltip";

import styles from "./LocationIndicator.module.scss";

export type LocationIndicatorProps = {
  latitude: number;
  longitude: number;
  color: "red" | "green" | "blue" | "orange";
  tooltips?: string[];
  opacity?: number;
};

export function LocationIndicator({
  latitude,
  longitude,
  color = "blue",
  opacity = 1,
  tooltips = [],
}: LocationIndicatorProps) {
  const t = useTranslation();

  const link = `https://www.openstreetmap.org/?mlat=${latitude}&mlon=${longitude}&zoom=15`;

  return (
    <TooltipStack
      texts={tooltips}
      containerAttributes={{
        style: {
          display: "flex",
          alignItems: "center",
          height: "100%",
          opacity,
        },
      }}
    >
      <a
        className={styles.locationIndicator}
        href={link}
        target="_blank"
        rel="noreferrer"
        aria-label={t.timeApprovalShowLocation}
      >
        <LocationOn fontSize="small" className={styles[color]} />
      </a>
    </TooltipStack>
  );
}

export function NoLocationIndicator() {
  const t = useTranslation();
  return (
    <TooltipStack
      texts={[t.timeApprovalNoLocation]}
      containerAttributes={{
        style: { display: "flex", alignItems: "center", height: "100%" },
      }}
    >
      <LocationOff fontSize="small" className={styles.red} />
    </TooltipStack>
  );
}
