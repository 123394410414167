import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Button from "@mui/material/Button";

import { NotificationsBadge } from "~components/Notifications/NotificationsBell/NotificationsBell";
import { DrawerContainer } from "~components/shared/Drawer/Drawer";
import { PageLayout } from "~components/shared/PageLayout/PageLayout";
import { PageLayoutHeader } from "~components/shared/PageLayout/PageLayoutHeader";
import { useIsDesktop } from "~components/shared/useIsDesktop/useIsDesktop.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { NotificationDrawer } from "./Notification/NotificationDrawer";
import { NotificationCards } from "./NotificationCards";
import { NotificationTable } from "./NotificationTable";
import { useNotificationsView } from "./useNotificationsView";

export function Notifications() {
  const isDesktop = useIsDesktop();
  const t = useTranslation();

  const {
    count,
    groupedNotifications,
    isLoading,
    onSelectNotification,
    selectedNotification,
    notificationFilters,
    isDateRangeValid,
    markAllNotificationsRead,
  } = useNotificationsView();

  return (
    <PageLayout
      headerChildren={
        <PageLayoutHeader
          showTitleBar
          title={
            <NotificationsBadge>{t.notificationsTitle}</NotificationsBadge>
          }
          actions={
            isDesktop && (
              <Button
                variant="containedInverted"
                onClick={markAllNotificationsRead}
                startIcon={<MarkEmailReadIcon />}
                disabled={count === 0}
              >
                {t.notificationsMarkAsRead}
              </Button>
            )
          }
        />
      }
    >
      <DrawerContainer style={{ height: "100%" }}>
        {groupedNotifications &&
          (isDesktop ? (
            <NotificationTable
              groupedNotifications={groupedNotifications}
              onSelectNotification={onSelectNotification}
              selectedNotification={selectedNotification}
              notificationFilters={notificationFilters}
              isDateRangeValid={isDateRangeValid}
              isLoading={isLoading}
            />
          ) : (
            <NotificationCards
              groupedNotifications={groupedNotifications}
              onSelectNotification={onSelectNotification}
              notificationFilters={notificationFilters}
              markAllNotificationsRead={markAllNotificationsRead}
            />
          ))}
        <NotificationDrawer
          selectedNotification={selectedNotification}
          setSelectedNotification={onSelectNotification}
        />
      </DrawerContainer>
    </PageLayout>
  );
}
