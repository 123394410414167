import { classList } from "~lib/classList.ts";

import type { ReactNode } from "react";

import styles from "./PageLayout.module.scss";

interface PageLayoutProps {
  children?: ReactNode;
  headerChildren?: ReactNode;
  testId?: string;
  noPadding?: boolean;
}

export function PageLayout({
  headerChildren,
  children,
  testId,
  noPadding = false,
}: PageLayoutProps) {
  return (
    <div
      className={classList(
        styles.container,
        noPadding && styles.container_noPadding,
      )}
      data-testid={testId}
    >
      <div className={styles.container__header}>{headerChildren}</div>
      <div
        className={classList(
          styles.container__content,
          noPadding && styles.noPadding,
        )}
      >
        {children}
      </div>
    </div>
  );
}
