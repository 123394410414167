export const GEOLOCATION_POSITION_UNSUPPORTED = -1;

export function createGeolocationPositionUnsupportedError(): GeolocationPositionError {
  const error = {
    code: GEOLOCATION_POSITION_UNSUPPORTED,
    message: "Geolocation not available",
    PERMISSION_DENIED: 1,
    POSITION_UNAVAILABLE: 2,
    TIMEOUT: 3,
  } as const;

  // necessary for instanceof check
  return Object.setPrototypeOf(error, GeolocationPositionError.prototype);
}

export function createGeolocationPositionUnavailableError(): GeolocationPositionError {
  const error = {
    code: 2,
    message: "Geolocation not available",
    PERMISSION_DENIED: 1,
    POSITION_UNAVAILABLE: 2,
    TIMEOUT: 3,
  } as const;

  // necessary for instanceof check
  return Object.setPrototypeOf(error, GeolocationPositionError.prototype);
}
