import { PositionIndicator } from "~components/shared/LocationIndicator/PositionIndicator";
import { Notification } from "~generated";

import type { EmbeddedNotification } from "../useEmbeddedNotifications";

export function NotificationLocationIndicator({
  notification,
}: {
  notification: EmbeddedNotification;
}) {
  if (
    [
      Notification.notification_type.TOOL_RESPONSIBILITY_TAKEN,
      Notification.notification_type.TOOL_RESPONSIBILITY_RETURNED,
    ].includes(notification.notification_type)
  ) {
    return <PositionIndicator position={notification.body.position} />;
  }
  return null;
}
