import { createContext, useContext, useMemo, useState } from "react";

import type { FunctionComponent, PropsWithChildren } from "react";

export interface ModalContextValue<T> {
  openModal: (data: T) => void;
  closeModal: () => void;
}

type ModalProps<T> = {
  isModalOpen: boolean;
  onClose: () => void;
  data: T | null;
};

export function createModalProvider<T>(
  Modal: FunctionComponent<ModalProps<T>>,
) {
  const context = createContext<ModalContextValue<T> | null>(null);

  const hook = function useModalProvider() {
    const value = useContext(context);
    if (!value) {
      throw new Error("useModal must be used within a ModalProvider");
    }

    return value;
  };

  const provider = function ModalProvider({ children }: PropsWithChildren) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState<T | null>(null);

    const value = useMemo(
      () => ({
        openModal: (newData: T) => {
          setData(newData);
          setIsModalOpen(true);
        },
        closeModal: () => {
          setData(null);
          setIsModalOpen(false);
        },
      }),
      [],
    );

    return (
      <context.Provider value={value}>
        {children}
        <Modal
          isModalOpen={isModalOpen}
          onClose={value.closeModal}
          data={data}
        />
      </context.Provider>
    );
  };

  return { hook, context, provider };
}
