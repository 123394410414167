import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { useBusinessConfig } from "~contexts/BusinessConfigContext/BusinessConfigContext";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { ManagersService } from "~generated";
import { getResourceName } from "~lib/resourceHelpers.ts";

import { useAllEmployees, useCurrentEmployee } from "./employees";
import { managerKeys } from "./managerKeys.ts";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { Employee } from "~generated";

async function getManagers({ tenantId }: { tenantId: string }) {
  const employees = await ManagersService.getTenantManagers(tenantId);
  if (!employees || !employees.employees) {
    return [];
  }
  return employees.employees.sort((a, b) =>
    getResourceName(a).localeCompare(getResourceName(b)),
  );
}

interface UseManagersQueryOptions {
  useQueryOptions?: Partial<
    UseQueryOptions<
      Employee[],
      Error,
      Employee[],
      ReturnType<typeof managerKeys.list>
    >
  >;
}
export function useManagers({ useQueryOptions }: UseManagersQueryOptions = {}) {
  const currentTenant = useCurrentTenant();

  const queryResult = useQuery({
    ...useQueryOptions,
    queryKey: managerKeys.list(currentTenant.id),
    queryFn: async () => getManagers({ tenantId: currentTenant.id }),
  });

  const filteredData = useMemo(
    () => queryResult.data?.filter((employee) => !employee.resigned_at),
    [queryResult.data],
  );

  return {
    ...queryResult,
    data: filteredData,
  };
}

export function useSuperiorConstructionSiteManagerOfCurrentEmployee() {
  const { data: currentEmployee, isLoading: isLoadingCurrentEmployee } =
    useCurrentEmployee();

  const { data: allEmployees, isLoading: isLoadingAllEmployees } =
    useAllEmployees();

  const {
    jobTitleRoleMapping: { managingDirector: managingDirectorTitles },
  } = useBusinessConfig();

  const isSuperior = (e: Employee) => e.id === superior?.superior_id;
  let superior = currentEmployee;

  let depth = 0;

  while (superior && depth < 10) {
    if (superior?.is_construction_site_manager) {
      return {
        data: superior,
        isLoading: isLoadingCurrentEmployee || isLoadingAllEmployees,
      };
    }
    superior = allEmployees?.find(isSuperior);
    depth += 1;
  }

  const managingDirector = allEmployees?.find(
    (e) =>
      managingDirectorTitles.includes(e.job_title) &&
      e.tenant_id === currentEmployee?.tenant_id,
  );

  return {
    data: managingDirector,
    isLoading: isLoadingCurrentEmployee || isLoadingAllEmployees,
  };
}
