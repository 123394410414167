/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkingTimes } from '../models/WorkingTimes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WorkingTimesService {
    /**
     * list working times
     * @param tenantId tenant_id
     * @returns WorkingTimes successful
     * @throws ApiError
     */
    public static getWorkingTimes(
        tenantId: string,
    ): CancelablePromise<WorkingTimes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/working_times',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
