/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingUnapproveEventBody = {
    action: TimeTrackingUnapproveEventBody.action;
    reference: string;
    resource: Resource;
};
export namespace TimeTrackingUnapproveEventBody {
    export enum action {
        UNAPPROVE = 'unapprove',
    }
}

