/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommuteType } from './CommuteType';
import type { Resource } from './Resource';
import type { TrackingTaskType } from './TrackingTaskType';
export type TimeTrackingModifyEventBody = {
    action: TimeTrackingModifyEventBody.action;
    start_time?: string;
    stop_time?: string;
    work_package_id?: string;
    pause_duration_seconds?: number;
    reference: string;
    resource: Resource;
    tracking_task_type?: TrackingTaskType;
    arrival_commute_type?: CommuteType;
    departure_commute_type?: CommuteType;
    arrival_commute_time_seconds?: number;
    departure_commute_time_seconds?: number;
};
export namespace TimeTrackingModifyEventBody {
    export enum action {
        MODIFY = 'modify',
    }
}

