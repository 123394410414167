/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employees } from '../models/Employees';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ManagersService {
    /**
     * list managers
     * @param tenantId tenant_id
     * @returns Employees successful
     * @throws ApiError
     */
    public static getTenantManagers(
        tenantId: string,
    ): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/managers',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
