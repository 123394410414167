import { endOfToday } from "date-fns/endOfToday";
import { startOfDay } from "date-fns/startOfDay";
import { subWeeks } from "date-fns/subWeeks";

import type { DateRange } from "@mui/x-date-pickers-pro";

export const DEFAULT_RANGE_IN_WEEKS = 6;

export function defaultDateRange(): DateRange<Date> {
  return [
    startOfDay(subWeeks(new Date(), DEFAULT_RANGE_IN_WEEKS)),
    endOfToday(),
  ];
}
