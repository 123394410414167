import Menu from "@mui/material/Menu";
import { useState } from "react";

import type { ReactNode } from "react";

import styles from "./HeaderMenu.module.scss";

export interface HeaderMenuProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
  menuPosition?: "left" | "center" | "right";
  component: (
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  ) => ReactNode;
  children: (closeMenu: () => void) => ReactNode[] | ReactNode;
}

export function HeaderMenu({
  component,
  menuPosition,
  children,
  ...props
}: HeaderMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div {...props}>
      {component(handleClick)}
      <Menu
        sx={{ mt: "32px" }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: menuPosition || "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuPosition || "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.menu}
      >
        {children(handleClose)}
      </Menu>
    </div>
  );
}
