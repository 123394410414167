/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TimeEntryStatus {
    EXPORTED = 'exported',
    APPROVED = 'approved',
    DELETED = 'deleted',
    OPEN = 'open',
    MANAGER_ACCOUNT = 'manager_account',
    RUNNING = 'running',
}
