/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingExportEventBody = {
    action: TimeTrackingExportEventBody.action;
    reference: string;
    resource: Resource;
};
export namespace TimeTrackingExportEventBody {
    export enum action {
        EXPORT = 'export',
    }
}

