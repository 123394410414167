import { SyncStatus } from "~generated";

import type { QueryClient, QueryKey } from "@tanstack/react-query";

export function optimisticallyUpdateSyncStatusToRunning({
  queryKey,
  queryClient,
}: {
  queryKey: QueryKey;
  queryClient: QueryClient;
}) {
  queryClient.setQueryData<SyncStatus>(queryKey, (oldSyncStatus) => {
    if (oldSyncStatus) {
      return {
        ...oldSyncStatus,
        state: SyncStatus.state.RUNNING,
      };
    }
    return {
      last_synced_at: null,
      duration_in_sec: null,
      state: SyncStatus.state.RUNNING,
    };
  });
}
