import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiDrawer from "@mui/material/Drawer";
import { format } from "date-fns/format";

import {
  Drawer as CustomDrawer,
  DrawerContent,
  DrawerHeader,
  DrawerSegment,
} from "~components/shared/Drawer/Drawer";
import { EmployeeWithPhone } from "~components/shared/EmployeeWithPhone/EmployeeWithPhone";
import { useIsDesktop } from "~components/shared/useIsDesktop/useIsDesktop.ts";
import {
  useLocale,
  useTranslation,
} from "~contexts/I18nContext/I18nContext.tsx";

import { ActionChip } from "./ActionChip";
import { Description } from "./Description";
import { NotificationLocationIndicator } from "./NotificationLocationIndicator";

import type { EmbeddedNotification } from "../useEmbeddedNotifications";

type NotificationDrawerProps = {
  selectedNotification: EmbeddedNotification | undefined;
  setSelectedNotification: (
    notification: EmbeddedNotification | undefined,
  ) => void;
};
export function NotificationDrawer({
  selectedNotification,
  setSelectedNotification,
}: NotificationDrawerProps) {
  const isDesktop = useIsDesktop();
  const t = useTranslation();
  const locale = useLocale();

  const Drawer = isDesktop ? CustomDrawer : MuiDrawer;
  const onClose = () => setSelectedNotification(undefined);

  return (
    <Drawer
      open={Boolean(selectedNotification)}
      anchor={"bottom"}
      data-testid={"notification-drawer"}
      onClose={onClose}
    >
      {selectedNotification && (
        <>
          <DrawerHeader onClose={onClose}>
            <Box sx={{ display: "flex", gap: 1 }}>
              {format(selectedNotification.notificationAt, "PPPPp", {
                locale,
              })}
              <NotificationLocationIndicator
                notification={selectedNotification}
              />
            </Box>
          </DrawerHeader>
          <DrawerContent>
            <DrawerSegment label={t.notificationsOccurrence}>
              <ActionChip
                notificationType={selectedNotification.notification_type}
              />
            </DrawerSegment>

            <DrawerSegment label={t.notificationsTool}>
              {selectedNotification.body.tool?.abbreviation}
              <div
                style={{
                  fontSize: "0.875rem",
                  color: "var(--color-grey-3)",
                }}
              >
                {selectedNotification.body.tool?.name}
              </div>
            </DrawerSegment>

            <DrawerSegment label={t.notificationsSender}>
              <EmployeeWithPhone employee={selectedNotification.sender} />
            </DrawerSegment>

            <DrawerSegment label={t.notificationsDescription}>
              <Description notification={selectedNotification} />
              {!isDesktop && (
                <Button
                  onClick={onClose}
                  variant="outlined"
                  sx={{ mt: 8, width: "100%" }}
                >
                  {t.close}
                </Button>
              )}
            </DrawerSegment>
          </DrawerContent>
        </>
      )}
    </Drawer>
  );
}
