/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SyncStatus = {
    state: SyncStatus.state | null;
    last_synced_at: string | null;
    duration_in_sec: number | null;
};
export namespace SyncStatus {
    export enum state {
        ERROR = 'error',
        RUNNING = 'running',
        DONE = 'done',
    }
}

