import "./wdyr.ts";
import { MsalProvider } from "@azure/msal-react";
import TimeAgo from "javascript-time-ago";
import de from "javascript-time-ago/locale/de";
import en from "javascript-time-ago/locale/en";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { initAuth } from "~auth/initialization";
import { App } from "~components/App/App";
import { I18nProvider } from "~contexts/I18nContext/I18nContext.tsx";
import {
  UpdateHandler,
  UpdateProvider,
} from "~contexts/UpdateContext/UpdateContext.tsx";
import { OpenAPI } from "~generated";
import { initMuiX } from "~initializer/mui";
import { initOpenApi } from "~initializer/openapi";
import { init as initSentry } from "~initializer/sentry";
import * as serviceWorkerRegistration from "~initializer/serviceWorkerRegistration.ts";

import "./assets/scss/index.scss";

initSentry();
initMuiX();

const msalInstance = await initAuth();
initOpenApi(msalInstance);
const updateHandler = new UpdateHandler();

TimeAgo.addDefaultLocale(de);
TimeAgo.addLocale(en);

OpenAPI.BASE = "";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <I18nProvider>
      <MsalProvider instance={msalInstance}>
        <UpdateProvider updateHandler={updateHandler}>
          <App />
        </UpdateProvider>
      </MsalProvider>
    </I18nProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => updateHandler.updateFound(registration),
  onWaiting: (registration) => updateHandler.updateFound(registration),
});
