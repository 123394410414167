import { captureException } from "@sentry/react";
import { Suspense, useEffect } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";

import { RequirePermit } from "~auth/RequirePermit.tsx";
import { Layout } from "~components/App/Layout/Layout.tsx";
import { PageGroup } from "~components/App/Routes/PageGroup/PageGroup.tsx";
import { Dashboard } from "~components/Dashboard/Dashboard.tsx";
import { Notifications } from "~components/Notifications/Notifications.tsx";
import { ROUTES } from "~components/routeDefinitions.ts";
import { GenericError } from "~components/shared/ErrorPages/GenericError.tsx";
import { NotFound } from "~components/shared/ErrorPages/NotFound.tsx";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner.tsx";
import { CurrentEmployeeRunningTimeTrackingsProvider } from "~contexts/CurrentEmployeeRunningTimeTrackingsContext/CurrentEmployeeRunningTimeTrackingsContext.tsx";
import { OfflineContextProvider } from "~contexts/OfflineContext/OfflineContext.tsx";

import { useNavigationDefinitions } from "../../useNavigationDefinitions.tsx";

import { RequireAppAccess } from "./RedirectAppAccess/RequireAppAccess.tsx";
import { RequireTenantAccess } from "./RedirectTenantAccess/RequireTenantAccess.tsx";
import { RedirectToMainTenant } from "./RedirectToMainTenant/RedirectToMainTenant.tsx";
import { RedirectToTool } from "./RedirectToTool/RedirectToTool.tsx";
import { RequireValidTenantNumber } from "./RequireValidTenantNumber/RequireValidTenantNumber.tsx";
import { TestError } from "./TestError.tsx";

export function Routes() {
  const { navigationDefinitions, hiddenRoutes } = useNavigationDefinitions();
  const routeDefinition = createRoutesFromElements(
    <Route path={"/"} element={<Outlet />} errorElement={<ErrorElement />}>
      <Route
        path={ROUTES.dashboard.path}
        element={
          <RequireAppAccess>
            <RequireValidTenantNumber>
              <RequireTenantAccess>
                <CurrentEmployeeRunningTimeTrackingsProvider>
                  <OfflineContextProvider>
                    <Layout>
                      <Suspense fallback={<Spinner />}>
                        <RequirePermit>
                          <Outlet />
                        </RequirePermit>
                      </Suspense>
                    </Layout>
                  </OfflineContextProvider>
                </CurrentEmployeeRunningTimeTrackingsProvider>
              </RequireTenantAccess>
            </RequireValidTenantNumber>
          </RequireAppAccess>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path={ROUTES.notifications.path} element={<Notifications />} />

        {navigationDefinitions.map((group) => (
          <Route
            key={group.titleTranslationKey}
            element={
              <PageGroup navigationGroup={group}>
                <Suspense fallback={<Spinner />}>
                  <Outlet />
                </Suspense>
              </PageGroup>
            }
          >
            {group.items.map(({ route, routeProps }) => (
              <Route key={route.path} {...routeProps} />
            ))}
          </Route>
        ))}

        {hiddenRoutes.map(({ route, routeProps }) => (
          <Route key={route.path} {...routeProps} />
        ))}

        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path={ROUTES.testError.path} element={<TestError />} />
      <Route path={ROUTES.toolDeeplink.path} element={<RedirectToTool />} />
      <Route path="*" element={<RedirectToMainTenant />} />
      <Route index element={<RedirectToMainTenant />} />
    </Route>,
  );

  const router = createBrowserRouter(routeDefinition);

  return <RouterProvider router={router} />;
}

function ErrorElement() {
  const error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, [error]);

  return <GenericError />;
}
