import { differenceInHours } from "date-fns/differenceInHours";
import { parseISO } from "date-fns/parseISO";
import { useMemo } from "react";

import { useCurrentEmployee } from "~api/employees.ts";
import { usePermitted } from "~auth/usePermitted.ts";
import { ROUTES } from "~components/routeDefinitions.ts";
import { useEmbeddedTimeEntriesForDateRange } from "~components/TimeManagement/shared/useEmbeddedTimeEntries/useEmbeddedTimeEntries.ts";
import {
  ResourceTypeFilter,
  useFilterableTimeEntries,
} from "~components/TimeManagement/shared/useFilterableTimeEntries/useFilterableTimeEntries.ts";
import { defaultDateRange } from "~components/TimeManagement/TimeApproval/defaultDateRange.ts";
import { type TimeEntry, TimeEntryStatus } from "~generated";

export enum Age {
  YOUNG = "young",
  OLD = "old",
  ANCIENT = "ancient",
}

export const AGE_TO_DAYS = {
  [Age.OLD]: 2,
  [Age.ANCIENT]: 7,
};

export function useTimeApprovalTaskCounts() {
  const permitted = usePermitted();
  const { data: currentEmployee } = useCurrentEmployee();

  const { embeddedTimeEntries } = useEmbeddedTimeEntriesForDateRange({
    dateRange: defaultDateRange(),
  });

  const canViewTimeApproval = permitted(ROUTES.timeApproval.permissions);

  const { filteredTimeEntries } = useFilterableTimeEntries({
    timeEntries: embeddedTimeEntries,
    resourceType: ResourceTypeFilter.Employee,
    constructionSiteManagerId: currentEmployee?.id,
  });

  return useMemo(() => {
    const managerAccountTimeEntries = filterByStatus({
      timeEntries: filteredTimeEntries,
      status: TimeEntryStatus.MANAGER_ACCOUNT,
    });
    const openTimeEntries = filterByStatus({
      timeEntries: filteredTimeEntries,
      status: TimeEntryStatus.OPEN,
    });
    const conflictTimeEntries = filteredTimeEntries.filter(
      (timeEntry) => timeEntry.isOverlapping,
    );

    const anomaliesTimeEntries = filteredTimeEntries.filter(
      (timeEntry) => timeEntry.hasAnomalies,
    );

    const managerAccountCount = managerAccountTimeEntries.length;
    const openCount = openTimeEntries.length;
    const conflictCount = conflictTimeEntries.length;
    const anomaliesCount = anomaliesTimeEntries.length;
    return {
      managerAccountCount,
      managerAccountAge: getAge(findOldestTimeEntry(managerAccountTimeEntries)),
      openCount,
      openAge: getAge(findOldestTimeEntry(openTimeEntries)),
      conflictCount,
      conflictAge: getAge(findOldestTimeEntry(conflictTimeEntries)),
      anomaliesCount,
      anomaliesAge: getAge(findOldestTimeEntry(anomaliesTimeEntries)),
      hasTasks:
        canViewTimeApproval &&
        (managerAccountCount > 0 ||
          openCount > 0 ||
          conflictCount > 0 ||
          anomaliesCount > 0),
    };
  }, [filteredTimeEntries, canViewTimeApproval]);
}

function filterByStatus({
  timeEntries,
  status,
}: {
  timeEntries: TimeEntry[];
  status: TimeEntryStatus;
}) {
  return timeEntries.filter((timeEntry) => timeEntry.status === status);
}

function getAge(timeEntry?: TimeEntry) {
  if (!timeEntry || isYoungerThanAge({ timeEntry, age: Age.OLD })) {
    return Age.YOUNG;
  }
  if (isYoungerThanAge({ timeEntry, age: Age.ANCIENT })) {
    return Age.OLD;
  }
  return Age.ANCIENT;
}

function isYoungerThanAge({
  timeEntry,
  age,
}: {
  timeEntry: TimeEntry;
  age: Age.OLD | Age.ANCIENT;
}) {
  return (
    differenceInHours(new Date(), parseISO(timeEntry.start_time as string)) <=
    AGE_TO_DAYS[age] * 24
  );
}

function findOldestTimeEntry(timeEntries: TimeEntry[]) {
  return timeEntries.reduce<TimeEntry | undefined>(
    (oldestTimeEntry, timeEntry) => {
      if (!oldestTimeEntry) {
        return timeEntry;
      }
      const oldestStartTime = parseISO(oldestTimeEntry.start_time as string);
      const currentStartTime = parseISO(timeEntry.start_time as string);

      return oldestStartTime < currentStartTime ? oldestTimeEntry : timeEntry;
    },
    undefined,
  );
}
