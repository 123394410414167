import groupBy from "lodash/groupBy.js";
import { useMemo } from "react";

import { useConstructionSites } from "~api/constructionSites.ts";
import { useAllEmployees } from "~api/employees.ts";
import { useAllResources } from "~api/resources.ts";
import { useWorkPackages } from "~api/workPackages.ts";
import {
  useOverlappingTimeEntryIds,
  useTimeEntries,
} from "~components/TimeManagement/api/timeEntries.ts";
import { Resource as APIResource } from "~generated";

import { DataProvider } from "./DataProvider.ts";
import { EmbeddedTimeEntry } from "./EmbeddedTimeEntry.ts";
import { getAnomalies } from "./getAnomalies.ts";
import {
  timeEntriesGroupedByDateAndResourceKey,
  timeEntriesGroupedByDateAndTimeTrackingKey,
} from "./groupTimeEntries.ts";

import type {
  DateRange,
  DateRange as MuiDateRange,
} from "@mui/x-date-pickers-pro";
import type { TimeEntry } from "~generated";

interface UseEmbeddedTimeEntriesForDateRangeProps {
  dateRange?: DateRange<Date>;
}

export function useEmbeddedTimeEntriesForDateRange({
  dateRange,
}: UseEmbeddedTimeEntriesForDateRangeProps = {}) {
  const {
    data: timeEntries,
    isLoading: isLoadingTimeEntries,
    isFetching: isFetchingTimeEntries,
  } = useTimeEntries(dateRange);

  const {
    embeddedTimeEntries,
    isLoading: isLoadingEmbeddedTimeEntries,
    isFetching: isFetchingEmbeddedTimeEntries,
  } = useEmbeddedTimeEntries({ timeEntries: timeEntries || [], dateRange });

  const isLoading = isLoadingTimeEntries || isLoadingEmbeddedTimeEntries;
  const isFetching = isFetchingTimeEntries || isFetchingEmbeddedTimeEntries;

  return {
    embeddedTimeEntries,
    isLoading,
    isFetching,
  };
}

type UseEmbeddedTimeEntriesProps = {
  timeEntries: TimeEntry[];
  dateRange: MuiDateRange<Date> | undefined;
};
export function useEmbeddedTimeEntries({
  timeEntries,
  dateRange,
}: UseEmbeddedTimeEntriesProps) {
  const {
    data: employees,
    isLoading: isLoadingEmployees,
    isFetching: isFetchingEmployees,
  } = useAllEmployees();

  const {
    data: resources,
    isLoading: isLoadingResources,
    isFetching: isFetchingResources,
  } = useAllResources();

  const {
    data: constructionSites,
    isLoading: isLoadingConstructionSites,
    isFetching: isFetchingConstructionSites,
  } = useConstructionSites({ includeDeleted: true });

  const {
    data: workPackages,
    isLoading: isLoadingWorkPackages,
    isFetching: isFetchingWorkPackages,
  } = useWorkPackages();

  const {
    data: overlaps,
    isLoading: isLoadingOverlaps,
    isFetching: isFetchingOverlaps,
  } = useOverlappingTimeEntryIds(dateRange);

  const isLoading =
    isLoadingEmployees ||
    isLoadingResources ||
    isLoadingConstructionSites ||
    isLoadingWorkPackages ||
    isLoadingOverlaps;

  const isFetching =
    isFetchingEmployees ||
    isFetchingResources ||
    isFetchingConstructionSites ||
    isFetchingWorkPackages ||
    isFetchingOverlaps;

  const embeddedTimeEntries: EmbeddedTimeEntry[] = useMemo(() => {
    if (isLoading || !timeEntries) {
      return [];
    }

    const dataProvider = new DataProvider(
      constructionSites,
      resources,
      workPackages || [],
      employees || [],
      overlaps || [],
    );

    const result = timeEntries.map<EmbeddedTimeEntry>(
      (timeEntry) => new EmbeddedTimeEntry(timeEntry, dataProvider),
    );

    // This is a performance optimization to avoid iterating over all time entries
    // in the isExceedingMaxHours function.
    const timeEntriesGroupedByDateAndEmployee = groupBy(
      result,
      timeEntriesGroupedByDateAndResourceKey,
    );

    const timeEntriesGroupedByDateAndTimeTracking = groupBy(
      result,
      timeEntriesGroupedByDateAndTimeTrackingKey,
    );

    result
      .filter(
        (timeEntry) =>
          timeEntry.stopTime &&
          !timeEntry.approvedAt &&
          !timeEntry.deletedAt &&
          timeEntry.resource.type === APIResource.type.EMPLOYEE,
      )
      .forEach((timeEntry) => {
        // eslint-disable-next-line no-param-reassign
        timeEntry.anomalies = getAnomalies({
          timeEntry,
          timeEntriesGroupedByDateAndEmployee,
          timeEntriesGroupedByDateAndTimeTracking,
        });
      });

    return result;
  }, [
    isLoading,
    timeEntries,
    employees,
    resources,
    workPackages,
    constructionSites,
    overlaps,
  ]);

  return {
    embeddedTimeEntries,
    isLoading,
    isFetching,
  };
}
