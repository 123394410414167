/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HistoryTimeEntry } from './HistoryTimeEntry';
export type HistoryEntry = {
    readonly creator_id: string;
    action: HistoryEntry.action;
    readonly date: string;
    readonly start_time_changed: boolean;
    readonly stop_time_changed: boolean;
    readonly pause_duration_seconds_changed: boolean;
    readonly work_package_id_changed: boolean;
    readonly tracking_task_type_changed: boolean;
    readonly arrival_commute_type_changed?: boolean;
    readonly arrival_commute_time_seconds_changed?: boolean;
    readonly departure_commute_type_changed?: boolean;
    readonly departure_commute_time_seconds_changed?: boolean;
    readonly history_time_entry: HistoryTimeEntry;
};
export namespace HistoryEntry {
    export enum action {
        START = 'start',
        APPEND = 'append',
        STOP = 'stop',
        AUTO_STOP = 'auto_stop',
        FORCE_STOP = 'force_stop',
        MODIFY = 'modify',
        APPROVE = 'approve',
        UNAPPROVE = 'unapprove',
        EXPORT = 'export',
        COMMUTE_TIME = 'commute_time',
        UPDATE_COMMUTE_TYPE = 'update_commute_type',
    }
}

