import type { EmbeddedTimeEntry } from "./EmbeddedTimeEntry.ts";
import type { TimeEntry } from "~generated";

export function timeEntriesGroupedByDateAndResourceKey(
  timeEntry: TimeEntry,
): string {
  return `${timeEntry.start_time?.substring(0, 10)}${timeEntry.resource.id}`;
}

export function timeEntriesGroupedByDateAndTimeTrackingKey(
  timeEntry: EmbeddedTimeEntry,
): string {
  return `${timeEntry.start_time?.substring(0, 10)}${timeEntry.time_tracking_id}`;
}
