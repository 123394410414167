import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";
import { memo } from "react";

import { EmployeeWithPhone } from "~components/shared/EmployeeWithPhone/EmployeeWithPhone";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList";

import { ActionChip } from "./ActionChip";
import { Description } from "./Description";
import { NotificationLocationIndicator } from "./NotificationLocationIndicator";

import type { EmbeddedNotification } from "../useEmbeddedNotifications";

import styles from "./NotificationCard.module.scss";

type NotificationCardProps = {
  notification: EmbeddedNotification;
  onSelectNotification: (selectedNotification: EmbeddedNotification) => void;
};
function NotificationCard({
  notification,
  onSelectNotification,
}: NotificationCardProps) {
  const t = useTranslation();
  return (
    <Card
      className={classList(styles.card, !notification.read_at && styles.unread)}
      onClick={() => onSelectNotification(notification)}
      variant={notification.read_at ? "outlined" : "elevation"}
    >
      <CardContent>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <ActionChip notificationType={notification.notification_type} />
            <Box sx={{ display: "flex" }} gap={0.5}>
              <NotificationLocationIndicator notification={notification} />
              {format(parseISO(notification.notification_at), "HH:mm")}
            </Box>
          </Box>
          <CardSegment label={t.notificationsTool}>
            {notification.body.tool?.abbreviation}
          </CardSegment>
          <CardSegment label={t.notificationsSender}>
            <EmployeeWithPhone employee={notification.sender} />
          </CardSegment>
          <CardSegment label={t.notificationsDescription}>
            <Description notification={notification} />
          </CardSegment>
        </Box>
      </CardContent>
    </Card>
  );
}
const MemoizedNotificationCard = memo(NotificationCard);

export { MemoizedNotificationCard as NotificationCard };

function CardSegment({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.cardSegment}>
      <span>{label}</span>
      <div>{children}</div>
    </div>
  );
}
