import { useMsal } from "@azure/msal-react";
import { useQueryClient } from "@tanstack/react-query";

export function useLogout() {
  const { instance } = useMsal();
  const queryClient = useQueryClient();

  return async () => {
    queryClient.getQueryCache().clear();
    const account = instance.getActiveAccount();
    return instance.logoutRedirect({ account });
  };
}
