import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { NavLink } from "react-router-dom";

import { useNotificationCount } from "~components/Notifications/api/notifications.ts";
import { ROUTES } from "~components/routeDefinitions.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";

export function NotificationsBadge({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data: count, isLoading } = useNotificationCount();

  if (isLoading || !count) {
    return <>{children}</>;
  }

  return (
    <Badge badgeContent={count} color="error">
      {children}
    </Badge>
  );
}

export function NotificationsBell() {
  return (
    <NotificationsBadge>
      <NotificationsIcon />
    </NotificationsBadge>
  );
}

export function NotificationsBellLink() {
  const { number: tenantNumber } = useCurrentTenant();

  return (
    <NavLink to={ROUTES.notifications.link({ tenantNumber })}>
      <NotificationsBell />
    </NavLink>
  );
}
