import Chip from "@mui/material/Chip";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { Notification } from "~generated";

import styles from "./ActionChip.module.scss";

export function ActionChip({
  notificationType,
}: {
  notificationType: Notification.notification_type;
}) {
  const t = useTranslation();

  const chipTypeProps = () => {
    switch (notificationType) {
      case Notification.notification_type.TOOL_RESPONSIBILITY_TAKEN:
        return {
          label: t.notificationsActionResponsibilityTaken,
          className: styles.toolResponsibilityTaken,
        };
      case Notification.notification_type.TOOL_RESPONSIBILITY_RETURNED:
        return {
          label: t.notificationsActionResponsibilityReturned,
          className: styles.toolResponsibilityReturned,
        };
      case Notification.notification_type.TOOL_DATA_CHANGED:
        return {
          label: t.notificationsActionToolDataChanged,
          className: styles.toolDataChanged,
        };
      case Notification.notification_type.TOOL_PERMANENT_DECOMMISSIONED:
        return {
          label: t.notificationsActionToolPermanentDecommissioned,
          className: styles.toolPermanentDecommissioned,
        };
      case Notification.notification_type.TOOL_PERMANENT_RECOMMISSIONED:
        return {
          label: t.notificationsActionToolPermanentRecommissioned,
          className: styles.toolPermanentRecommissioned,
        };
      default:
        return {
          label: "Unknown type",
          className: styles.unknown,
        };
    }
  };
  return <Chip {...chipTypeProps()} size={"small"} />;
}
