import { isEqual } from "date-fns/isEqual";
import { isSameDay } from "date-fns/isSameDay";
import { isValid } from "date-fns/isValid";
import { set } from "date-fns/set";

import { TrackingTaskType } from "~generated";
import { ResourceType } from "~lib/resourceHelpers.ts";

import type { SetOptional } from "type-fest";
import type { Resource } from "~generated";

export interface Interval {
  start: Date;
  end: Date;
}

class IntervalSet {
  private values: Interval[] = [];

  add(item: Interval | undefined) {
    if (!item) {
      return;
    }
    if (
      this.values.some(
        (interval) =>
          isEqual(interval.start, item.start) &&
          isEqual(interval.end, item.end),
      )
    ) {
      return;
    }
    this.values.push(item);
  }

  getValues() {
    return this.values;
  }
}

function intervalsToDurationSec(intervals: Interval[]) {
  return intervals
    .map(({ start, end }) =>
      Math.floor((end.getTime() - start.getTime()) / 1000),
    )
    .reduce((duration, seconds) => duration + seconds, 0);
}

function getBeforePause(work: Interval, pause: Interval): Interval | undefined {
  if (work.start >= pause.start) {
    return undefined;
  }
  if (work.end > pause.start) {
    return { start: work.start, end: pause.start };
  }

  return { start: work.start, end: work.end };
}

function getDuringPause(work: Interval, pause: Interval): Interval | undefined {
  if (work.end <= pause.start || work.start >= pause.end) {
    return undefined;
  }
  const start = work.start < pause.start ? pause.start : work.start;
  const end = work.end < pause.end ? work.end : pause.end;
  return { start, end };
}

function getAfterPause(work: Interval, pause: Interval): Interval | undefined {
  if (work.end <= pause.end) {
    return undefined;
  }
  if (work.start > pause.end) {
    return { start: work.start, end: work.end };
  }
  return { start: pause.end, end: work.end };
}

export function splitWorkOnPause(
  work: Interval,
  pause: Interval,
): { beforePause?: Interval; duringPause?: Interval; afterPause?: Interval } {
  return {
    beforePause: getBeforePause(work, pause),
    duringPause: getDuringPause(work, pause),
    afterPause: getAfterPause(work, pause),
  };
}

/**
 *
 * @param workIntervals must be ordered and can't overlap
 * @param pauses must be ordered and can't overlap
 * @returns all intervals that where during a pause, and all intervals outside of the pauses
 */
export function subtractPausesFromWorkIntervals(
  workIntervals: Interval[],
  pauses: Interval[],
): { tracked: Interval[]; untracked: Interval[] } {
  if (!pauses || pauses.length === 0) {
    return {
      tracked: workIntervals,
      untracked: [],
    };
  }
  const tracked = new IntervalSet();
  const untracked = new IntervalSet();
  workIntervals.forEach((workInterval) => {
    let intervalToSplit: Interval | undefined = workInterval;

    pauses.forEach((pause, index) => {
      if (!intervalToSplit) {
        return;
      }

      const { beforePause, duringPause, afterPause } = splitWorkOnPause(
        intervalToSplit,
        pause,
      );
      tracked.add(beforePause);
      untracked.add(duringPause);

      /**
       * Any time that is left after the pause, will be tried to split
       * with the next pause, until no more time is remaining
       */
      const isLastPause = pauses.length === index + 1;
      if (isLastPause) {
        tracked.add(afterPause);
        return;
      }
      intervalToSplit = afterPause;
    });
  });

  return {
    tracked: tracked.getValues(),
    untracked: untracked.getValues(),
  };
}

const SUNDAY = 0;
const FRIDAY = 5;
const SATURDAY = 6;

function defaultPauseIntervalsFromDate(date: Date): Interval[] {
  const zeroSeconds = { seconds: 0, milliseconds: 0 };
  if ([FRIDAY, SATURDAY, SUNDAY].includes(date.getDay())) {
    return [
      {
        start: set(date, { hours: 10, minutes: 0, ...zeroSeconds }),
        end: set(date, { hours: 10, minutes: 30, ...zeroSeconds }),
      },
    ];
  }
  return [
    {
      start: set(date, { hours: 10, minutes: 0, ...zeroSeconds }),
      end: set(date, { hours: 10, minutes: 15, ...zeroSeconds }),
    },
    {
      start: set(date, { hours: 13, minutes: 0, ...zeroSeconds }),
      end: set(date, { hours: 13, minutes: 30, ...zeroSeconds }),
    },
  ];
}

export function netTrackedAndUntrackedTime({
  workIntervals,
  resourceType,
  trackingTaskType,
}: {
  workIntervals: SetOptional<Interval, "end">[];
  resourceType: Resource.type;
  trackingTaskType: TrackingTaskType;
}): { trackedDuration: number; untrackedDuration: number } {
  const startOfWork = workIntervals[0].start;

  const intervals = workIntervals.map<Interval>(({ start, end }) => ({
    start,
    end: end || start,
  }));

  const validIntervals = intervals.filter((interval) => {
    if (!isValid(interval.start) || !isValid(interval.end)) {
      return false;
    }
    if (
      !isSameDay(interval.start, startOfWork) ||
      !isSameDay(interval.end, startOfWork)
    ) {
      return false;
    }
    return true;
  });

  const pauseIntervals = hasPauses(resourceType, trackingTaskType)
    ? defaultPauseIntervalsFromDate(startOfWork)
    : [];

  const { tracked, untracked } = subtractPausesFromWorkIntervals(
    validIntervals,
    pauseIntervals,
  );

  return {
    trackedDuration: intervalsToDurationSec(tracked),
    untrackedDuration: intervalsToDurationSec(untracked),
  };
}

const hasPausesTrackingTaskTypeMap: Record<TrackingTaskType, boolean> = {
  [TrackingTaskType.WORK]: true,
  [TrackingTaskType.SCHOOL]: false,
  [TrackingTaskType.SICKNESS]: false,
  [TrackingTaskType.TRAINING]: true,
  [TrackingTaskType.VACATION]: false,
  [TrackingTaskType.LEISURE_TIME_ACCOUNT]: false,
};
function hasPauses(
  resourceType: Resource.type,
  trackingTaskType: TrackingTaskType,
) {
  if (resourceType === ResourceType.TOOL) {
    return false;
  }
  return hasPausesTrackingTaskTypeMap[trackingTaskType];
}
