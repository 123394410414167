import { useContext, useMemo } from "react";

import { useCurrentEmployee } from "~api/employees.ts";
import { useManagers } from "~api/managers.ts";
import { getResourceName } from "~lib/resourceHelpers.ts";

import { SelectedManagerContext } from "./SelectedManagerContext.tsx";

export function useSelectedManager() {
  const { data: currentEmployee, isLoading: isCurrentEmployeeLoading } =
    useCurrentEmployee();
  const { selectedManager, setSelectedManager } = useContext(
    SelectedManagerContext,
  );

  const { data: managers, isLoading: isLoadingManagers } = useManagers();

  const sortedManagers = useMemo(
    () =>
      managers?.sort((a, b) => {
        if (a.id === currentEmployee?.id) {
          return -1;
        }
        if (b.id === currentEmployee?.id) {
          return 1;
        }
        return getResourceName(a).localeCompare(getResourceName(b));
      }),
    [managers, currentEmployee?.id],
  );

  return {
    isLoading: isCurrentEmployeeLoading || isLoadingManagers,
    managers: sortedManagers,
    selectedManager,
    setSelectedManager,
  };
}
