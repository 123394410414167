/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CommuteType {
    NO_COMMUTE = 'no_commute',
    DRIVER_HOME = 'driver_home',
    DRIVER_SITE = 'driver_site',
    PASSENGER_HOME = 'passenger_home',
    PASSENGER_SITE = 'passenger_site',
}
