import Skeleton from "@mui/material/Skeleton";

import { useCurrentEmployee } from "~api/employees";

import { BetaSetting } from "./BetaSetting";
import { LanguageSetting } from "./LanguageSetting.tsx";
import { ResetAppCacheButton } from "./ResetAppCacheButton.tsx";

export function Settings() {
  const {
    data: currentEmployee,
    isLoading: isCurrentEmployeeLoading,
    isError: isCurrentEmployeeError,
    error: currentEmployeeError,
  } = useCurrentEmployee();

  if (isCurrentEmployeeError) {
    throw currentEmployeeError;
  }

  if (isCurrentEmployeeLoading || !currentEmployee) {
    return <Skeleton height={"200px"} />;
  }

  return (
    <>
      <LanguageSetting />
      <BetaSetting currentEmployee={currentEmployee} />
      <ResetAppCacheButton />
    </>
  );
}
