import { useMemo } from "react";

import { useAllEmployees } from "~api/employees";
import { usePermitted } from "~auth/usePermitted";
import { ROUTES } from "~components/routeDefinitions";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { GeoConfidence } from "~generated";

import type { Employee } from "~generated";

export function useEmployeeAdministrationTaskBar() {
  const permitted = usePermitted();
  const currentTenant = useCurrentTenant();

  const { data: allEmployees } = useAllEmployees();
  const tenantEmployees: Employee[] | undefined = useMemo(
    () =>
      allEmployees?.filter(
        (employee) =>
          employee.tenant_id === currentTenant.id &&
          employee.resigned_at === null,
      ),
    [allEmployees, currentTenant.id],
  );

  const canViewEmployeeAdministration = permitted(
    ROUTES.organigram.permissions,
  );

  const superiorMissingCount =
    tenantEmployees?.filter(isSuperiorRequired).length ?? 0;

  const ambiguousAddressCount =
    tenantEmployees?.filter(isAmbiguousAddress).length ?? 0;

  const hasMissingSuperiors =
    canViewEmployeeAdministration && superiorMissingCount > 0;
  const hasAmbigousAddresses =
    canViewEmployeeAdministration && ambiguousAddressCount > 0;

  return {
    superiorMissingCount,
    ambiguousAddressCount,
    hasMissingSuperiors,
    hasAmbigousAddresses,
  };
}

export function isSuperiorRequired(employee: Employee) {
  return employee.superior_required && !employee.superior_id;
}

export function isAmbiguousAddress(employee: Employee) {
  return !(
    employee.residence_address_confidence === GeoConfidence.HIGH ||
    employee.residence_address_confidence === GeoConfidence.MANUAL ||
    employee.residence_address_text === null
  );
}
