import { useMsal } from "@azure/msal-react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink } from "react-router-dom";

import {
  DashboardLayout,
  DashboardLayoutColumn,
  DashboardLayoutNavigationGrid,
  DashboardLayoutNavigationGridBody,
  DashboardLayoutNavigationGridHeader,
} from "~components/Dashboard/DashboardLayout";
import { ContentSkeleton } from "~components/shared/Loading/ContentSkeleton/ContentSkeleton";
import { PageLayout } from "~components/shared/PageLayout/PageLayout";
import { useNavigationDefinitions } from "~components/useNavigationDefinitions.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import {
  DashboardNavigationGroupItemView,
  DashboardNavigationGroupView,
} from "./DashboardNavigationGroupView";
import { DashboardTaskCards } from "./DashboardTaskCards";

import type { TranslationKey } from "~contexts/I18nContext/I18nContext.tsx";

export function Dashboard() {
  const { number: tenantNumber } = useCurrentTenant();
  const t = useTranslation();
  const { navigationDefinitions, isLoading } = useNavigationDefinitions();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const orderGroup = (index: number, titleTranslationKey: TranslationKey) =>
    /* On desktop screen sizes the controlling group should be at the most
     * outer (3rd) column to be a bit separated from the other groups.
     */
    isDesktop && titleTranslationKey === "navigationGroup_controlling"
      ? 3
      : index * 2;
  return (
    <PageLayout noPadding>
      {isLoading ? (
        <ContentSkeleton withPadding />
      ) : (
        <DashboardLayout variant="default">
          <DashboardLayoutColumn>
            <DashboardLayoutNavigationGrid>
              <DashboardLayoutNavigationGridHeader>
                <h1>
                  {t.dashboardGreeting}, {activeAccount?.name}!
                </h1>
              </DashboardLayoutNavigationGridHeader>
              <DashboardLayoutNavigationGridBody>
                {navigationDefinitions.map(
                  ({ items, titleTranslationKey, IconComponent }, index) => (
                    <DashboardNavigationGroupView
                      title={t[titleTranslationKey]}
                      icon={IconComponent && <IconComponent />}
                      key={titleTranslationKey}
                      style={{ order: orderGroup(index, titleTranslationKey) }}
                    >
                      {items.map(
                        ({
                          route,
                          titleTranslationKey: itemTitleTranslationKey,
                        }) => (
                          <NavLink
                            key={route.path}
                            to={route.link({ tenantNumber })}
                          >
                            <DashboardNavigationGroupItemView>
                              {t[itemTitleTranslationKey]}
                            </DashboardNavigationGroupItemView>
                          </NavLink>
                        ),
                      )}
                    </DashboardNavigationGroupView>
                  ),
                )}
              </DashboardLayoutNavigationGridBody>
            </DashboardLayoutNavigationGrid>
          </DashboardLayoutColumn>
          <DashboardTaskCards />
        </DashboardLayout>
      )}
    </PageLayout>
  );
}
