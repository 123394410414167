/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvalidProjectsResponse } from '../models/InvalidProjectsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvalidProjectsService {
    /**
     * returns invalid projects
     * @returns InvalidProjectsResponse successfully returns invalid projects
     * @throws ApiError
     */
    public static invalidProjects(): CancelablePromise<InvalidProjectsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/controlling/invalid_projects',
        });
    }
}
