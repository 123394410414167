import * as Sentry from "@sentry/react";
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useMemo } from "react";

import { useAccessToken } from "~auth/useAccessToken.ts";
import { Broken } from "~components/shared/ErrorPages/Broken.tsx";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner.tsx";
import { useBusinessConfig } from "~contexts/BusinessConfigContext/BusinessConfigContext.tsx";

import type { Ability } from "~auth/Ability.ts";

interface DecodedToken {
  name: string;
  groups: string[];
  sub: string;
  iss: string;
  preferred_username?: string;
}

export interface UserProfile {
  name: string;
  abilities: Ability[];
  iss: string;
  sub: string;
}

const UserProfileContext = createContext<UserProfile>({
  name: "anonymous",
  abilities: [],
  iss: "",
  sub: "",
});

export function UserProfileProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data: accessToken, isPending, isError } = useAccessToken();
  const businessConfig = useBusinessConfig();

  const data = useMemo(() => {
    if (!accessToken) {
      return undefined;
    }
    const decodedToken = jwtDecode<DecodedToken>(accessToken);
    const { abilities } = businessConfig;

    Sentry.setUser({
      username: decodedToken.preferred_username,
      email: decodedToken.preferred_username,
    });
    Sentry.setContext("Current User", {
      name: decodedToken.name,
      email: decodedToken.preferred_username,
    });

    return {
      ...decodedToken,
      abilities: decodedToken.groups
        .map((id) => abilities.find((a) => a.id === id))
        .filter(Boolean)
        .map((a) => a?.displayName)
        .filter((a) => a !== undefined) as Ability[],
    };
  }, [accessToken, businessConfig]);

  if (isPending) {
    return <Spinner />;
  }

  if (isError || data === undefined) {
    return <Broken />;
  }

  return (
    <UserProfileContext.Provider value={data}>
      {children}
    </UserProfileContext.Provider>
  );
}

export function useUserProfile() {
  return useContext(UserProfileContext);
}
