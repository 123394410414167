/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkPackage } from '../models/WorkPackage';
import type { WorkPackages } from '../models/WorkPackages';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WorkPackagesService {
    /**
     * list work packages
     * @param tenantId tenant_id
     * @returns WorkPackages successful
     * @throws ApiError
     */
    public static getWorkPackages(
        tenantId: string,
    ): CancelablePromise<WorkPackages> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/work_packages',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * create work package
     * @param tenantId tenant_id
     * @param requestBody
     * @returns WorkPackage work package created with minimal parameters
     * @throws ApiError
     */
    public static createWorkPackage(
        tenantId: string,
        requestBody?: WorkPackage,
    ): CancelablePromise<WorkPackage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/work_packages',
            path: {
                'tenant_id': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }
    /**
     * update work package
     * @param tenantId tenant_id
     * @param id id
     * @param requestBody
     * @returns WorkPackage work package updated
     * @throws ApiError
     */
    public static updateWorkPackage(
        tenantId: string,
        id: string,
        requestBody?: WorkPackage,
    ): CancelablePromise<WorkPackage> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/tenants/{tenant_id}/work_packages/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `invalid request`,
            },
        });
    }
}
