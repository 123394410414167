import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export class UpdateHandler {
  private callback: (() => void) | null = null;

  private eventTarget = new EventTarget();

  private waitingWorker: ServiceWorker | null = null;

  updateFound(registration: ServiceWorkerRegistration) {
    this.waitingWorker = registration.waiting;

    this.waitingWorker?.addEventListener("statechange", () => {
      if (this.waitingWorker?.state === "activated") {
        window.location.reload();
      }
    });

    this.eventTarget.dispatchEvent(new Event("update"));
  }

  updateNow() {
    this.waitingWorker?.postMessage({ type: "SKIP_WAITING" });
  }

  onUpdate(callback: () => void) {
    if (this.callback) {
      this.stopListening();
    }
    this.callback = callback;
    this.eventTarget.addEventListener("update", this.callback);
  }

  stopListening() {
    this.eventTarget.removeEventListener("update", this.callback);
    this.callback = null;
  }
}

interface UpdateProviderProps {
  children: React.ReactNode;
  updateHandler: UpdateHandler;
}

const UpdateContext = createContext({
  updateAvailable: false,
  updateNow: () => {},
});

export function UpdateProvider({
  children,
  updateHandler,
}: UpdateProviderProps) {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const onUpdate = useCallback(() => setUpdateAvailable(true), []);
  const updateNow = useCallback(
    () => updateHandler.updateNow(),
    [updateHandler],
  );

  useEffect(() => {
    updateHandler.onUpdate(onUpdate);
    return () => updateHandler.stopListening();
  }, [onUpdate, updateHandler]);

  const value = { updateAvailable, updateNow };

  return (
    <UpdateContext.Provider value={value}>{children}</UpdateContext.Provider>
  );
}

export function useUpdate() {
  return useContext(UpdateContext);
}
