import PhoneIcon from "@mui/icons-material/Phone";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import { useCurrentEmployee } from "~api/employees";
import { useIsDesktop } from "~components/shared/useIsDesktop/useIsDesktop.ts";
import { getResourceName } from "~lib/resourceHelpers.ts";

import { CurrentEmployee } from "./CurrentEmployee";

import type { Employee } from "~generated";

import styles from "./EmployeeWithPhone.module.scss";

export function EmployeeWithPhone({
  employee,
}: {
  employee: Employee | undefined;
}) {
  const { data: currentEmployee } = useCurrentEmployee();
  return (
    <EmployeeWithPhoneView
      employee={employee}
      currentEmployee={currentEmployee}
    />
  );
}

export function EmployeeWithPhoneView({
  employee,
  currentEmployee,
}: {
  employee: Employee | undefined;
  currentEmployee: Employee | undefined;
}) {
  const isDesktop = useIsDesktop();

  if (!employee) {
    return <div>-</div>;
  }
  const isCurrentEmployee = employee.id === currentEmployee?.id;
  if (isCurrentEmployee) {
    return <CurrentEmployee />;
  }

  if (isDesktop) {
    return (
      <div>
        <div>{getResourceName(employee)}</div>
        {employee.phone && (
          <a className={styles.phone} href={`tel:${employee.phone}`}>
            {employee.phone}
          </a>
        )}
      </div>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>{getResourceName(employee)}</div>
      {employee.phone && (
        <IconButton
          color="secondary"
          aria-label={employee.phone}
          href={`tel:${employee.phone}`}
        >
          <PhoneIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
}
