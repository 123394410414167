/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommuteType } from './CommuteType';
import type { Resource } from './Resource';
export type TimeTrackingUpdateCommuteTypeEventBody = {
    action: TimeTrackingUpdateCommuteTypeEventBody.action;
    reference: string;
    resource: Resource;
    arrival_commute_type: CommuteType;
};
export namespace TimeTrackingUpdateCommuteTypeEventBody {
    export enum action {
        UPDATE_COMMUTE_TYPE = 'update_commute_type',
    }
}

