/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
import type { ConstructionSite } from './ConstructionSite';
export type ConstructionSiteDetail = (ConstructionSite & {
    tenant_id?: string;
    team_assignment_ids?: Array<string>;
    location?: Address;
    calculated_minutes?: number;
    readonly deleted_at: string | null;
    azure_sync_status: ConstructionSiteDetail.azure_sync_status;
});
export namespace ConstructionSiteDetail {
    export enum azure_sync_status {
        NO_AZURE_TEAM = 'no_azure_team',
        SYNCING = 'syncing',
        SYNCED = 'synced',
        FAILED = 'failed',
    }
}

