import { AuthenticatedTemplate } from "@azure/msal-react";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { SignOutButton } from "./SignOutButton.tsx";

import styles from "./ErrorPages.module.scss";

export function GenericError() {
  const t = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t.genericErrorPageHeader}</h1>
      <div>
        <p>{t.genericErrorPageText}</p>
      </div>
      <AuthenticatedTemplate>
        <SignOutButton />
      </AuthenticatedTemplate>
    </div>
  );
}
