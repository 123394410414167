import { useQuery } from "@tanstack/react-query";

import { useBusinessConfig } from "~contexts/BusinessConfigContext/BusinessConfigContext";
import { TenantsService } from "~generated";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { BusinessConfigTenant } from "~contexts/BusinessConfigContext/BusinessConfigContext";
import type { Tenant as ApiTenant } from "~generated";

export interface Tenant extends ApiTenant {
  shortName: string;
  abbreviation: string;
}

function getTenantShortName(
  tenantNumber: Tenant["number"],
  businessConfigTenants: BusinessConfigTenant[],
) {
  return (
    businessConfigTenants.find((t) => t.tenant_number === tenantNumber)
      ?.short_name || "unbekannter Mandant"
  );
}

function getTenantAbbreviation(
  tenantNumber: Tenant["number"],
  businessConfigTenants: BusinessConfigTenant[],
) {
  return (
    businessConfigTenants.find((t) => t.tenant_number === tenantNumber)
      ?.abbreviation || "unbekannter Mandant"
  );
}

export const tenantKeys = {
  base: () => ["tenants"] as const,
  list: () => [...tenantKeys.base(), "list"] as const,
  detail: (tenantId: string) =>
    [...tenantKeys.base(), "detail", tenantId] as const,
  me: () => [...tenantKeys.base(), "me"] as const,
};

export function useCurrentEmployeeTenants() {
  const { tenants: businessConfigTenants } = useBusinessConfig();
  return useQuery({
    queryKey: tenantKeys.me(),
    queryFn: async () => {
      const tenants = await TenantsService.getTenants();
      if (!tenants || !tenants.tenants) {
        return [];
      }
      return tenants.tenants.map((tenant) => ({
        shortName: getTenantShortName(tenant.number, businessConfigTenants),
        abbreviation: getTenantAbbreviation(
          tenant.number,
          businessConfigTenants,
        ),
        ...tenant,
      }));
    },
  });
}

export function useAllTenants(
  options?: Partial<
    UseQueryOptions<
      Tenant[],
      Error,
      Tenant[],
      ReturnType<(typeof tenantKeys)["list"]>
    >
  >,
) {
  const { tenants: businessConfigTenants } = useBusinessConfig();
  return useQuery({
    ...options,
    queryKey: tenantKeys.list(),
    queryFn: async () => {
      const tenants = await TenantsService.getAllTenants();
      if (!tenants || !tenants.tenants) {
        return [];
      }
      return tenants.tenants.map((tenant) => ({
        shortName: getTenantShortName(tenant.number, businessConfigTenants),
        abbreviation: getTenantAbbreviation(
          tenant.number,
          businessConfigTenants,
        ),
        ...tenant,
      }));
    },
  });
}
