/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountGroup } from '../models/AccountGroup';
import type { ControllingJournalEntries } from '../models/ControllingJournalEntries';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingJournalEntriesService {
    /**
     * lists journal entries of project
     * @param tenantId tenant_id
     * @param projectId project_id
     * @param accountGroup account_group
     * @param startDate start_date
     * @param endDate end_date
     * @returns ControllingJournalEntries successful with all infos
     * @throws ApiError
     */
    public static getJournalEntries(
        tenantId: string,
        projectId: string,
        accountGroup?: AccountGroup,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<ControllingJournalEntries> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/projects/{project_id}/journal_entries',
            path: {
                'tenant_id': tenantId,
                'project_id': projectId,
            },
            query: {
                'account_group': accountGroup,
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                404: `project not found`,
            },
        });
    }
}
