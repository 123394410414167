import { hoursToMilliseconds } from "date-fns/hoursToMilliseconds";
import { minutesToMilliseconds } from "date-fns/minutesToMilliseconds";

import { useAbsencesForToday } from "~api/absences.ts";
import { useConstructionSites } from "~api/constructionSites.ts";
import {
  useAllEmployees,
  useCurrentEmployee,
  useEmployees,
} from "~api/employees.ts";
import { useManagers } from "~api/managers.ts";
import { useCurrentEmployeeTeamAssignments } from "~api/teamAssignments.ts";
import { useTeams } from "~api/teams.ts";
import { useAllTenants } from "~api/tenants.ts";
import { useAllTools, useBillableTools } from "~api/tools.ts";
import { useWorkPackages } from "~api/workPackages.ts";
import {
  useCurrentEmployeeRunningTimeTrackings,
  useCurrentEmployeeStoppedTimeTrackings,
} from "~components/TimeManagement/api/timeTracking.ts";

export interface OfflinePrefetchOptions {
  refetchInterval: number;
  refetchIntervalInBackground: boolean;
  staleTime: number;
  gcTime: number;
}
const prefetchOptions: OfflinePrefetchOptions = {
  refetchInterval: minutesToMilliseconds(5),
  refetchIntervalInBackground: true,
  staleTime: minutesToMilliseconds(5),
  gcTime: hoursToMilliseconds(24) * 7,
};

export function useOfflinePrefetch() {
  const { isSuccess: isCurrentEmployeeTeamAssignmentsSuccess } =
    useCurrentEmployeeTeamAssignments(prefetchOptions);
  const { isSuccess: isCurrentEmployeeRunningTimeTrackingsSuccess } =
    useCurrentEmployeeRunningTimeTrackings(prefetchOptions);
  const { isSuccess: isCurrentEmployeeStoppedTimeTrackingsSuccess } =
    useCurrentEmployeeStoppedTimeTrackings(prefetchOptions);
  const { isSuccess: isEmployeesSuccess } = useEmployees({
    useQueryOptions: prefetchOptions,
  });
  const { isSuccess: isManagersSuccess } = useManagers({
    useQueryOptions: prefetchOptions,
  });
  const { isSuccess: isConstructionSitesSuccess } = useConstructionSites({
    useQueryOptions: prefetchOptions,
  });
  const { isSuccess: isCurrentEmployeeSuccess } =
    useCurrentEmployee(prefetchOptions);
  const { isSuccess: isTeamsSuccess } = useTeams({
    options: prefetchOptions,
    includeDeleted: false,
  });
  const { isSuccess: isTeamsWithDeletedSuccess } = useTeams({
    options: prefetchOptions,
    includeDeleted: true,
  });

  const { isSuccess: isAbsencesForTodaySuccess } =
    useAbsencesForToday(prefetchOptions);
  const { isSuccess: isBillableToolsSuccess } = useBillableTools(
    {},
    prefetchOptions,
  );
  const { isSuccess: isActiveToolsSuccess } = useAllTools(
    { active: true },
    prefetchOptions,
  );
  const { isSuccess: isWorkPackagesSuccess } = useWorkPackages(prefetchOptions);
  const { isSuccess: isAllEmployeesSuccess } = useAllEmployees(prefetchOptions);
  const { isSuccess: isAllTenantsSuccess } = useAllTenants(prefetchOptions);

  return (
    isCurrentEmployeeTeamAssignmentsSuccess &&
    isCurrentEmployeeRunningTimeTrackingsSuccess &&
    isCurrentEmployeeStoppedTimeTrackingsSuccess &&
    isEmployeesSuccess &&
    isManagersSuccess &&
    isConstructionSitesSuccess &&
    isCurrentEmployeeSuccess &&
    isTeamsSuccess &&
    isTeamsWithDeletedSuccess &&
    isAbsencesForTodaySuccess &&
    isBillableToolsSuccess &&
    isActiveToolsSuccess &&
    isWorkPackagesSuccess &&
    isAllEmployeesSuccess &&
    isAllTenantsSuccess
  );
}
