import LoadingButton from "@mui/lab/LoadingButton";
import Chip from "@mui/material/Chip";
import SvgIcon from "@mui/material/SvgIcon";

import { ReactComponent as MicrosoftIcon } from "~assets/svg/icons/microsoft-logo-colored.svg";
import logoSrc from "~assets/svg/logo.svg";
import { useLogin } from "~auth/useLogin.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList.ts";

import type { ChipProps } from "@mui/material/Chip";

import styles from "./LoginPage.module.scss";

export function LoginPage() {
  const t = useTranslation();

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              <img src={logoSrc} alt="Grewe Logo" />
              <BetaChip />
            </div>
          </div>
          <div className={styles.welcomeContainer}>
            <div className={styles.welcomeContainer__content}>
              <h1 className={styles.welcomeContainer__title}>
                {t.loginPageTitle}{" "}
                <span>
                  App
                  <BetaChip />
                </span>
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: t.loginPageTextHtml }}
              ></div>
            </div>
            <div className={styles.welcomeContainer__actions}>
              <SignInButton />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.animationContainer}>
        <img src={`${process.env.PUBLIC_URL}/rasenmaeher.svg`} />
      </div>
    </>
  );
}

function BetaChip({ ...props }: ChipProps) {
  return (
    <Chip
      className={classList(styles.betaChip, props.className)}
      label="beta"
      {...props}
    />
  );
}

function SignInButton() {
  const { login, loading } = useLogin();
  const t = useTranslation();

  return (
    <LoadingButton
      startIcon={<SvgIcon component={MicrosoftIcon} inheritViewBox />}
      variant="outlined"
      size="large"
      onClick={async () => {
        await login();
      }}
      loading={loading}
    >
      {t.signIn}
    </LoadingButton>
  );
}
