import { SharedAbsence } from "~generated";

export const absenceCategoryTranslations = {
  [SharedAbsence.category.SCHOOL]: "absenceCategorySchool",
  [SharedAbsence.category.SICKNESS]: "absenceCategorySickness",
  [SharedAbsence.category.TRAINING]: "absenceCategoryTraining",
  [SharedAbsence.category.VACATION]: "absenceCategoryVacation",
  [SharedAbsence.category.LEISURE_TIME_ACCOUNT]:
    "absenceCategoryLeisureTimeAccount",
} as const;
