import Button from "@mui/material/Button";
import { useQueryClient } from "@tanstack/react-query";

import { useToast } from "~components/shared/useToast.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { useOfflineContext } from "~contexts/OfflineContext/useOfflineContext.tsx";

export function ResetAppCacheButton() {
  const { isOffline } = useOfflineContext();
  const queryClient = useQueryClient();
  const t = useTranslation();
  const { showSuccessToast } = useToast();

  if (isOffline) {
    // If we are offline we don't allow clearing the query cache
    // Example of offline issue:
    // 1. User is offline and starts a time tracking
    // 2. User pushes the "Clear cache" button
    // => The running time tracking data is removed (it reappears only when one goes online again)
    return null;
  }

  const resetAppCache = () => {
    queryClient.clear();
    showSuccessToast(t.resetAppCacheSuccessToast);
  };

  return (
    <Button variant={"outlined"} onClick={resetAppCache}>
      {t.resetAppCache}
    </Button>
  );
}
