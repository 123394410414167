import IconButton from "@mui/material/IconButton";
import { forwardRef } from "react";

import type { IconButtonProps } from "@mui/material/IconButton";
import type { ElementRef, ReactNode, Ref } from "react";

import styles from "./HeaderMenuIconButton.module.scss";

export interface HeaderMenuIconButtonProps extends IconButtonProps {
  icon: ReactNode;
}

export const HeaderMenuIconButton = forwardRef(
  (
    { icon, ...props }: HeaderMenuIconButtonProps,
    ref: Ref<ElementRef<typeof IconButton>>,
  ) => (
    <IconButton ref={ref} className={styles.headerMenuIconButton} {...props}>
      {icon}
    </IconButton>
  ),
);
HeaderMenuIconButton.displayName = "HeaderMenuIconButton";
