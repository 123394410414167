/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ConstructionSite = {
    readonly id: string;
    construction_site_manager_id: string;
    number: string;
    planned_begin_on: string | null;
    begin_on: string | null;
    description?: string;
    client?: {
        name?: string;
        phone?: string;
    };
    readonly status: ConstructionSite.status;
};
export namespace ConstructionSite {
    export enum status {
        INACTIVE = 'inactive',
        UNPLANNED = 'unplanned',
        PLANNED = 'planned',
        IN_PROGRESS = 'in_progress',
        DONE = 'done',
    }
}

