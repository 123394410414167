import { createContext, useMemo, useState } from "react";

import { useCurrentEmployee } from "~api/employees.ts";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner";

import type { PropsWithChildren } from "react";
import type { Employee } from "~generated";

export type SelectedManagerOption = Employee | undefined | "ALL";

type SelectedManagerContextType = {
  selectedManager: Employee | undefined;
  setSelectedManager: (employee: SelectedManagerOption) => void;
};

export const SelectedManagerContext = createContext<SelectedManagerContextType>(
  {
    selectedManager: undefined,
    setSelectedManager: () => {},
  },
);

export function SelectedManagerProvider({
  children,
  initialManager,
}: PropsWithChildren<{ initialManager?: SelectedManagerOption }>) {
  const { data: currentEmployee, isLoading } = useCurrentEmployee();

  const [selectedManagerState, setSelectedManager] =
    useState<SelectedManagerOption>(initialManager);

  const selectedManager = useMemo(
    () => getSelectedManager(selectedManagerState, currentEmployee),
    [selectedManagerState, currentEmployee],
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <SelectedManagerProviderWithProps
      selectedManager={selectedManager}
      setSelectedManager={setSelectedManager}
    >
      {children}
    </SelectedManagerProviderWithProps>
  );
}

export function SelectedManagerProviderWithProps({
  children,
  selectedManager,
  setSelectedManager,
}: PropsWithChildren<SelectedManagerContextType>) {
  return (
    <SelectedManagerContext.Provider
      value={{ selectedManager, setSelectedManager }}
    >
      {children}
    </SelectedManagerContext.Provider>
  );
}

function getSelectedManager(
  selectedManager: SelectedManagerOption,
  currentEmployee: Employee | undefined,
) {
  if (selectedManager === "ALL") {
    return undefined;
  }

  if (selectedManager) {
    return selectedManager;
  }

  if (currentEmployee?.is_construction_site_manager) {
    return currentEmployee;
  }

  return undefined;
}
