import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import { useLayoutEffect, useState } from "react";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList.ts";

import type { HTMLAttributes } from "react";

import s from "./Spinner.module.scss";

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  container?: "inPage" | "fullScreen";
  showMoreInfoTimeout?: number;
}

export function Spinner({
  loading = true,
  showMoreInfoTimeout = 3000,
  container = "fullScreen",
  className,
  ...props
}: SpinnerProps) {
  const t = useTranslation();
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  useLayoutEffect(() => {
    const timeout = loading
      ? setTimeout(() => {
          setShowMoreInfo(true);
        }, showMoreInfoTimeout)
      : undefined;

    return () => clearTimeout(timeout);
  }, [loading, setShowMoreInfo, showMoreInfoTimeout]);

  return (
    <Fade in={loading}>
      <div
        className={classList(className, s.container, s[container])}
        {...props}
        role="progressbar"
      >
        <div className={s.animation}>
          <img src={`${process.env.PUBLIC_URL}/rasenmaeher.svg`} />
          <img src={`${process.env.PUBLIC_URL}/rasenmaeher.svg`} />
          <img src={`${process.env.PUBLIC_URL}/rasenmaeher.svg`} />
        </div>
        <Collapse in={showMoreInfo}>
          <p className={s.info}>{t.moreLoadingInfo}</p>
        </Collapse>
      </div>
    </Fade>
  );
}
