/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tenants } from '../models/Tenants';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TenantsService {
    /**
     * list tenants
     * @returns Tenants successful
     * @throws ApiError
     */
    public static getTenants(): CancelablePromise<Tenants> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/me/tenants',
        });
    }
    /**
     * list tenants
     * @returns Tenants successful
     * @throws ApiError
     */
    public static getAllTenants(): CancelablePromise<Tenants> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants',
        });
    }
}
