import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import styles from "./ErrorPages.module.scss";

export function Broken() {
  const t = useTranslation();

  return (
    <div className={styles.container} data-testid="broken">
      <img
        className={styles.saw}
        src={`${process.env.PUBLIC_URL}/motorsaege_stihl.svg`}
      />
      <h1>{t.genericErrorPageHeader}</h1>
      <div>
        <p>{t.genericErrorPageText}</p>
      </div>
    </div>
  );
}
