import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";

import type { TooltipProps } from "@mui/material/Tooltip";
import type { HTMLAttributes } from "react";

import styles from "./OnHoverTooltip.module.scss";

export function OnHoverTooltip({
  children,
  containerAttributes,
  ...rest
}: TooltipProps & { containerAttributes?: HTMLAttributes<HTMLDivElement> }) {
  const [renderTooltip, setRenderTooltip] = useState(false);

  return (
    <div
      {...containerAttributes}
      onMouseEnter={() => !renderTooltip && setRenderTooltip(true)}
    >
      {!renderTooltip && children}
      {renderTooltip && <Tooltip {...rest}>{children}</Tooltip>}
    </div>
  );
}

export function TooltipStack({
  slotProps,
  texts,
  ...props
}: Omit<TooltipProps, "title"> & {
  containerAttributes?: HTMLAttributes<HTMLDivElement>;
  texts: string[];
}) {
  return (
    <OnHoverTooltip
      slotProps={{
        tooltip: { className: styles.stack },
        ...slotProps,
      }}
      title={
        <div>
          {texts.map((item, idx) => (
            <div className={styles.stackItem} key={idx}>
              {item}
            </div>
          ))}
        </div>
      }
      {...props}
    />
  );
}
