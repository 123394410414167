import React from "react";

import { classList } from "~lib/classList.ts";

import styles from "./IconWithLabel.module.scss";

export interface IconWithLabelProps {
  icon: React.ReactNode;
  isBlock?: boolean;
  children: React.ReactNode;
}

function IconWithLabel({
  icon,
  isBlock = false,
  children,
}: IconWithLabelProps) {
  const Container = isBlock ? "div" : "span";
  return (
    <Container
      className={classList(styles.container, isBlock && styles.containerBlock)}
    >
      {icon}
      {children}
    </Container>
  );
}

const MemoizedIconWithLabel = React.memo(IconWithLabel);

export { MemoizedIconWithLabel as IconWithLabel };
