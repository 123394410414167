import { Ability } from "~auth/Ability";
import { useUserProfile } from "~contexts/UserProfileContext/UserProfileContext.tsx";

import { RefetchButton } from "./RefetchButton";
import { SyncMenu } from "./SyncMenu";

export function Sync() {
  const userProfile = useUserProfile();
  const canAccessSyncMenu = userProfile.abilities.some(
    (ability) => ability !== Ability.canAccessApp,
  );

  if (canAccessSyncMenu) {
    return <SyncMenu />;
  }

  return <RefetchButton />;
}
