/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HistoryEntries } from '../models/HistoryEntries';
import type { OverlappingTimeEntries } from '../models/OverlappingTimeEntries';
import type { Overlaps } from '../models/Overlaps';
import type { TimeEntries } from '../models/TimeEntries';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TimeEntriesService {
    /**
     * lists history entries
     * @param tenantId tenant_id
     * @param timeEntryId time_entry_id
     * @returns HistoryEntries successful
     * @throws ApiError
     */
    public static getTimeEntryHistoryEntries(
        tenantId: string,
        timeEntryId: string,
    ): CancelablePromise<HistoryEntries> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/time_entries/{time_entry_id}/history_entries',
            path: {
                'tenant_id': tenantId,
                'time_entry_id': timeEntryId,
            },
        });
    }
    /**
     * lists time entries for current tenant
     * @param tenantId tenant_id
     * @param startDate start_date
     * @param endDate end_date
     * @returns TimeEntries successful
     * @throws ApiError
     */
    public static getTimeEntries(
        tenantId: string,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<TimeEntries> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/time_entries',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * export time entries for specified time range
     * @param tenantId tenant_id
     * @param startDate start_date
     * @param stopDate stop_date
     * @param onlyUnexported export only unexported times
     * @param filename filename
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static getTimeEntriesExport(
        tenantId: string,
        startDate?: string,
        stopDate?: string,
        onlyUnexported?: boolean,
        filename?: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/time_entries/export',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'start_date': startDate,
                'stop_date': stopDate,
                'only_unexported': onlyUnexported,
                'filename': filename,
            },
        });
    }
    /**
     * export time entries preview for specified time range
     * @param tenantId tenant_id
     * @param startDate start_date
     * @param stopDate stop_date
     * @param onlyUnexported export only unexported times
     * @returns any successfully sends export data
     * @throws ApiError
     */
    public static getTimeEntriesExportPreview(
        tenantId: string,
        startDate: string,
        stopDate: string,
        onlyUnexported: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/time_entries/export_preview',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'start_date': startDate,
                'stop_date': stopDate,
                'only_unexported': onlyUnexported,
            },
        });
    }
    /**
     * overlapping time entry ids
     * @param startDate
     * @param endDate
     * @returns Overlaps successfully returns overlaps
     * @throws ApiError
     */
    public static getOverlappingTimeEntryIds(
        startDate: string,
        endDate: string,
    ): CancelablePromise<Overlaps> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/time_entries/overlapping',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * overlapping time entries
     * @param resourceId
     * @param startTime
     * @param stopTime
     * @returns OverlappingTimeEntries successfully returns overlapping time entries
     * @throws ApiError
     */
    public static getOverlappingTimeEntries(
        resourceId: string,
        startTime: string,
        stopTime: string,
    ): CancelablePromise<OverlappingTimeEntries> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/time_entries/overlapping/{resource_id}',
            path: {
                'resource_id': resourceId,
            },
            query: {
                'start_time': startTime,
                'stop_time': stopTime,
            },
        });
    }
}
