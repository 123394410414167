import { NavLink } from "react-router-dom";

import {
  DashboardLayoutColumn,
  DashboardLayoutEmpty,
  DashboardTaskCard,
  DashboardTaskCardLink,
  DashboardTaskCardTitle,
} from "~components/Dashboard/DashboardLayout";
import { EmployeeAdministrationTaskBar } from "~components/Resources/EmployeeAdministration/EmployeeAdministrationTaskBar";
import { useEmployeeAdministrationTaskBar } from "~components/Resources/EmployeeAdministration/useEmployeeAdministrationTaskBar";
import { ROUTES } from "~components/routeDefinitions.ts";
import { useIsDesktop } from "~components/shared/useIsDesktop/useIsDesktop.ts";
import { TimeApprovalTaskBar } from "~components/TimeManagement/shared/TimeApprovalTaskBar/TimeApprovalTaskBar";
import { useTimeApprovalTaskCounts } from "~components/TimeManagement/shared/TimeApprovalTaskBar/useTimeApprovalTaskCounts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

export function DashboardTaskCards() {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const { hasTasks: hasTimeApprovalTasks } = useTimeApprovalTaskCounts();
  const { hasAmbigousAddresses, hasMissingSuperiors } =
    useEmployeeAdministrationTaskBar();

  if (hasTimeApprovalTasks || hasAmbigousAddresses || hasMissingSuperiors) {
    return (
      <DashboardLayoutColumn title={t.tasks}>
        {hasTimeApprovalTasks && <TimeApprovalTaskCard />}
        {hasMissingSuperiors && (
          <EmployeeAdministrationMissingSuperiorsTaskCard />
        )}
        {hasAmbigousAddresses && (
          <EmployeeAdministrationAmbiguousAddressesTaskCard />
        )}
      </DashboardLayoutColumn>
    );
  }
  if (isDesktop) {
    return (
      <DashboardLayoutColumn title={t.tasks}>
        <DashboardLayoutEmpty message={t.tasksEmpty} />
      </DashboardLayoutColumn>
    );
  }
  return <></>;
}

function TimeApprovalTaskCard() {
  const t = useTranslation();
  const { number: tenantNumber } = useCurrentTenant();

  return (
    <DashboardTaskCard>
      <DashboardTaskCardTitle
        dangerouslySetInnerHTML={{
          __html: t.tasksTimeApprovalTitleHtml,
        }}
      />
      <TimeApprovalTaskBar variant="vertical" />
      <NavLink to={ROUTES.timeApproval.link({ tenantNumber })}>
        <DashboardTaskCardLink>{t.tasksTimeApprovalLink}</DashboardTaskCardLink>
      </NavLink>
    </DashboardTaskCard>
  );
}

function EmployeeAdministrationMissingSuperiorsTaskCard() {
  const t = useTranslation();
  const { number: tenantNumber } = useCurrentTenant();

  return (
    <DashboardTaskCard>
      <DashboardTaskCardTitle
        dangerouslySetInnerHTML={{
          __html: t.tasksEmployeeAdministrationMissingSuperiorsTitleHtml,
        }}
      />
      <EmployeeAdministrationTaskBar
        variant="vertical"
        limitTo="superiorMissing"
      />
      <NavLink to={ROUTES.employees.link({ tenantNumber })}>
        <DashboardTaskCardLink>
          {t.tasksEmployeeAdministrationLink}
        </DashboardTaskCardLink>
      </NavLink>
    </DashboardTaskCard>
  );
}

function EmployeeAdministrationAmbiguousAddressesTaskCard() {
  const t = useTranslation();
  const { number: tenantNumber } = useCurrentTenant();

  return (
    <DashboardTaskCard>
      <DashboardTaskCardTitle
        dangerouslySetInnerHTML={{
          __html: t.tasksEmployeeAdministrationAmbiguousAddressesTitleHtml,
        }}
      />
      <EmployeeAdministrationTaskBar
        variant="vertical"
        limitTo="ambiguousAddress"
      />
      <NavLink to={ROUTES.employees.link({ tenantNumber })}>
        <DashboardTaskCardLink>
          {t.tasksEmployeeAdministrationLink}
        </DashboardTaskCardLink>
      </NavLink>
    </DashboardTaskCard>
  );
}
