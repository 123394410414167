import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList";

import styles from "./TaskBar.module.scss";

export enum Priority {
  HIGH = "high",
  MEDIUM = "medium",
  NORMAL = "normal",
}

type TaskBadgeProps = {
  count: number;
  text: string;
  onClick: () => void;
  priority?: Priority;
  tooltip?: string;
};

const PRIORITY_TO_CSS_CLASS = {
  [Priority.NORMAL]: styles.taskBadge__badge__priorityNormal,
  [Priority.MEDIUM]: styles.taskBadge__badge__priorityMedium,
  [Priority.HIGH]: styles.taskBadge__badge__priorityHigh,
};

export function TaskBadge({
  count,
  text,
  onClick,
  priority,
  tooltip,
}: TaskBadgeProps) {
  const disabled = count === 0;
  return (
    <Link
      component="button"
      disabled={disabled}
      onClick={onClick}
      underline="hover"
      variant="body1"
      className={styles.taskBadgeLink}
    >
      <Tooltip title={tooltip}>
        <div className={styles.taskBadge}>
          <Badge
            showZero
            badgeContent={count}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            className={classList(
              styles.taskBadge__badge,
              count === 0 && styles.taskBadge__badge__noEntries,
              priority ? PRIORITY_TO_CSS_CLASS[priority] : undefined,
            )}
          />
          <span className={styles.taskBadge__text}>{text}</span>
          {!disabled && <ArrowForwardIcon fontSize="inherit" />}
        </div>
      </Tooltip>
    </Link>
  );
}

export type TaskBarProps = {
  children: React.ReactNode;
  variant?: "horizontal" | "vertical";
};

export function TaskBar({ children, variant = "horizontal" }: TaskBarProps) {
  const t = useTranslation();

  return (
    <div className={classList(styles.taskBar, styles[`taskBar_${variant}`])}>
      <div className={styles.taskBarTitle}>{t.taskBarTitle}</div>
      <div className={styles.taskBadgeContainer}>{children}</div>
    </div>
  );
}
