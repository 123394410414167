import AutorenewIcon from "@mui/icons-material/Autorenew";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns/parseISO";
import TimeAgo from "javascript-time-ago";
import { useCallback, useState } from "react";

import {
  useConstructionSitesSyncStatus,
  useSyncConstructionSites,
} from "~api/constructionSites";
import { useEmployeesSyncStatus, useSyncEmployees } from "~api/employees.ts";
import { Ability } from "~auth/Ability.ts";
import {
  useControllingDataSyncStatus,
  useSyncControllingData,
} from "~components/Controlling/api/projects.ts";
import {
  useLocale,
  useTranslation,
} from "~contexts/I18nContext/I18nContext.tsx";
import { useUserProfile } from "~contexts/UserProfileContext/UserProfileContext.tsx";
import { SyncStatus } from "~generated";

import { HeaderMenu } from "../HeaderMenu";
import { HeaderMenuIconButton } from "../HeaderMenuIconButton";

import styles from "./SyncMenu.module.scss";

export function SyncMenu() {
  const t = useTranslation();
  return (
    <HeaderMenu
      data-testid="syncMenu"
      component={(handleClick) => (
        <HeaderMenuIconButton
          onClick={handleClick}
          icon={
            <Tooltip title={t.syncButtonTooltip}>
              <AutorenewIcon fontSize="inherit" />
            </Tooltip>
          }
          data-testid="syncMenuButton"
        />
      )}
    >
      {(onClose) => <SyncMenuContainer onCloseMenu={onClose} />}
    </HeaderMenu>
  );
}

type SyncMenuContainerProps = {
  onCloseMenu: () => void;
};
function SyncMenuContainer({ onCloseMenu }: SyncMenuContainerProps) {
  const userProfile = useUserProfile();
  const canSyncControllingData = userProfile.abilities.some(
    (ability) => ability === Ability.canAccessControlling,
  );

  const startConstructionSiteSync = useSyncConstructionSites();
  const startEmployeeSync = useSyncEmployees();
  const startControllingDataSync = useSyncControllingData();

  const { data: constructionSiteSyncStatus } = useConstructionSitesSyncStatus();
  const { data: employeeSyncStatus } = useEmployeesSyncStatus();
  const { data: controllingDataSyncStatus } = useControllingDataSyncStatus({
    enabled: canSyncControllingData,
  });

  const { syncAppData, syncAppDataStatus } = useSyncAppData();

  return (
    <SyncMenuContent
      onSyncEmployees={() => startEmployeeSync.mutate()}
      syncEmployeesStatus={employeeSyncStatus}
      onSyncConstructionSites={() => startConstructionSiteSync.mutate()}
      syncConstructionSitesStatus={constructionSiteSyncStatus}
      onSyncControllingData={() => startControllingDataSync.mutate()}
      syncControllingDataStatus={controllingDataSyncStatus}
      onSyncAppData={syncAppData}
      syncAppDataStatus={syncAppDataStatus}
      onCloseMenu={onCloseMenu}
      canSyncControllingData={canSyncControllingData}
    />
  );
}

type SyncMenuContentProps = {
  onSyncEmployees: () => void;
  syncEmployeesStatus?: SyncStatus;
  onSyncConstructionSites: () => void;
  syncConstructionSitesStatus?: SyncStatus;
  onSyncControllingData: () => void;
  syncControllingDataStatus?: SyncStatus;
  onSyncAppData: () => void;
  syncAppDataStatus?: SyncStatus;
  onCloseMenu: () => void;
  canSyncControllingData: boolean;
};
export function SyncMenuContent({
  onSyncEmployees,
  syncEmployeesStatus,
  onSyncConstructionSites,
  syncConstructionSitesStatus,
  onSyncControllingData,
  syncControllingDataStatus,
  onSyncAppData,
  syncAppDataStatus,
  onCloseMenu,
  canSyncControllingData,
}: SyncMenuContentProps) {
  const t = useTranslation();

  return (
    <>
      <Box className={styles.header}>
        <h2>{t.syncMenuTitle}</h2>
        <IconButton
          data-testid={"closeSyncMenuButton"}
          onClick={onCloseMenu}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={styles.items}>
        <SyncMenuItem
          title={t.syncMenuEmployeesTitle}
          syncButtonTooltip={t.syncButtonEmployeesTooltip}
          onSync={onSyncEmployees}
          status={syncEmployeesStatus}
          syncMenuItemTestId={"syncEmployeesMenuItem"}
        />
        <SyncMenuItem
          title={t.syncMenuConstructionSitesTitle}
          syncButtonTooltip={t.syncButtonConstructionSitesTooltip}
          onSync={onSyncConstructionSites}
          status={syncConstructionSitesStatus}
          syncMenuItemTestId={"syncConstructionSitesMenuItem"}
        />
        {canSyncControllingData && (
          <SyncMenuItem
            title={t.syncMenuControllingTitle}
            syncButtonTooltip={t.syncButtonControllingDataTooltip}
            onSync={onSyncControllingData}
            status={syncControllingDataStatus}
            syncMenuItemTestId="syncControllingDataMenuItem"
          />
        )}
        <SyncMenuItem
          title={t.syncMenuAppDataTitle}
          syncButtonTooltip={t.syncButtonAppDataTooltip}
          onSync={onSyncAppData}
          status={syncAppDataStatus}
          syncMenuItemTestId={"syncAppDataMenuItem"}
        />
      </Box>
      <Box className={styles.footer}>
        {t.syncMenuFooter}{" "}
        <Link underline={"none"} href="mailto:app@grewe-gruppe.de">
          app@grewe-gruppe.de
        </Link>
      </Box>
    </>
  );
}

export function roundUpSecondsToMinutes(
  seconds: number | null | undefined,
  defaultValue: number,
) {
  if (seconds === null || seconds === undefined) {
    return defaultValue;
  }
  return Math.ceil((seconds || 1) / 60);
}

type SyncMenuItemProps = {
  title: string;
  syncButtonTooltip: string;
  onSync: () => void;
  syncMenuItemTestId: string;
  status?: SyncStatus;
};
function SyncMenuItem({
  title,
  syncButtonTooltip,
  onSync,
  syncMenuItemTestId,
  status,
}: SyncMenuItemProps) {
  const t = useTranslation();
  const locale = useLocale();
  const timeAgo = new TimeAgo(locale.code as string);

  return (
    <Box className={styles.item} data-testid={syncMenuItemTestId}>
      <Box className={styles.statusBox}>
        {(status === undefined || status.state === null) && (
          <HelpIcon color={"disabled"} />
        )}
        {status?.state === SyncStatus.state.RUNNING && (
          <AutorenewIcon color={"info"} />
        )}
        {status?.state === SyncStatus.state.DONE && (
          <CheckCircleIcon color={"success"} />
        )}
        {status?.state === SyncStatus.state.ERROR && (
          <CancelIcon color={"error"} />
        )}
        <Box className={styles.statusInfo}>
          <Box className={styles.itemTitle}>{title}</Box>

          {status?.state !== SyncStatus.state.RUNNING && (
            <Box className={styles.lastSyncedAt}>
              {status?.last_synced_at &&
                `${t.syncMenuLastSyncedAt} ${timeAgo.format(
                  parseISO(status.last_synced_at),
                )}`}

              {!status?.last_synced_at && t.syncMenuLastSyncUnknown}
            </Box>
          )}

          {status?.state === SyncStatus.state.RUNNING && (
            <Box className={styles.runningStatus}>
              {t.syncMenuSyncRunning}
              <br />
              {t.syncMenuMaxTimeInformation.replace(
                "{maxTime}",
                roundUpSecondsToMinutes(status.duration_in_sec, 15).toString(),
              )}
            </Box>
          )}

          {status?.state === SyncStatus.state.ERROR && (
            <Box className={styles.errorStatus}>{t.syncMenuSyncFailed}</Box>
          )}
        </Box>
      </Box>

      <Box>
        <IconButton
          onClick={onSync}
          size="small"
          disabled={status?.state === SyncStatus.state.RUNNING}
        >
          <Tooltip title={syncButtonTooltip}>{<AutorenewIcon />}</Tooltip>
        </IconButton>
      </Box>
    </Box>
  );
}

function useSyncAppData() {
  const queryClient = useQueryClient();
  const [isSyncing, setSyncing] = useState(false);
  const [lastSyncedAt, setLastSyncedAt] = useState<string | null>(null);

  const syncAppData = useCallback(async () => {
    setSyncing(true);
    await queryClient.refetchQueries({ type: "active" });
    setLastSyncedAt(new Date().toISOString());
    setSyncing(false);
  }, [queryClient]);

  const syncAppDataStatus: SyncStatus = {
    state: isSyncing ? SyncStatus.state.RUNNING : SyncStatus.state.DONE,
    last_synced_at: lastSyncedAt,
    duration_in_sec: 0,
  };

  return {
    syncAppData,
    syncAppDataStatus,
  };
}
