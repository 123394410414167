/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Surcharges } from '../models/Surcharges';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingSurchargesService {
    /**
     * lists all available surcharges
     * @param tenantId tenant_id
     * @returns Surcharges successful
     * @throws ApiError
     */
    public static getSurcharges(
        tenantId: string,
    ): CancelablePromise<Surcharges> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/surcharges',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * updates or creates surcharges for all tenants
     * @param tenantId tenant_id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateSurcharges(
        tenantId: string,
        requestBody?: Surcharges,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/controlling/surcharges',
            path: {
                'tenant_id': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `failed`,
            },
        });
    }
    /**
     * export surcharges as csv
     * @param tenantId tenant_id
     * @returns binary successfully sends export data
     * @throws ApiError
     */
    public static export(
        tenantId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/surcharges/export',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
