import {
  Resource as APIResource,
  type Employee,
  type Team,
  type Tool,
} from "~generated";

export function isEmployee(resource: unknown): resource is Employee {
  return (
    Boolean(resource) &&
    typeof resource === "object" &&
    (resource as Employee).staff_number !== undefined
  );
}

export function isTool(resource: unknown): resource is Tool {
  return (
    Boolean(resource) &&
    typeof resource === "object" &&
    (resource as Tool).abbreviation !== undefined
  );
}

const ResourceType = APIResource.type;

export interface Resource extends APIResource {
  displayName?: string;
  teamIds?: string[] | null;
}

export interface ResourceWithDisplayName extends Resource {
  displayName: string;
}

export function employeeToResource(
  employee: Employee,
): ResourceWithDisplayName {
  if (employee.id) {
    return {
      id: employee.id,
      displayName: getResourceName(employee),
      type: ResourceType.EMPLOYEE,
      teamIds: employee.team_ids,
    };
  }
  throw Error("Employee ID missing");
}

export function toolToResource(tool: Tool): ResourceWithDisplayName {
  if (tool.id) {
    return {
      id: tool.id,
      displayName: getResourceName(tool),
      type: ResourceType.TOOL,
      teamIds: tool.team_ids,
    };
  }
  throw Error("Tool ID missing");
}

export function resourceIsInTeam({
  resource,
  team,
}: {
  resource: Resource;
  team: Team | null;
}): boolean {
  if (resource.type === ResourceType.EMPLOYEE && team?.member_ids) {
    return team.member_ids?.some((memberId) => resource.id === memberId);
  }
  if (resource.type === ResourceType.TOOL && team?.tool_ids) {
    return team.tool_ids?.some((toolId) => resource.id === toolId);
  }
  return false;
}

export function isApiResource(resource: unknown): resource is APIResource {
  return (
    Boolean(resource) &&
    typeof resource === "object" &&
    (resource as Resource).type !== undefined &&
    (resource as Resource).id !== undefined
  );
}

export function getResourceName(
  resource: Resource | Employee | Tool | null | undefined,
  fallback = "-",
) {
  if (!resource) {
    return fallback;
  }
  if (isApiResource(resource)) {
    return resource.displayName || `${resource.type} ${resource.id}`;
  }

  if (isEmployee(resource)) {
    return `${resource.first_name} ${resource.last_name} (${resource.staff_number})`;
  }

  return `${resource.abbreviation} (${resource.name})`;
}

export function isResourceEqual(a: Resource, b: Resource) {
  return a.id === b.id && a.type === b.type;
}

export function includesResource(list: Resource[], resource: Resource) {
  return (
    list.filter((listResource) => isResourceEqual(listResource, resource))
      .length > 0
  );
}
export { ResourceType };
