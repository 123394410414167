/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum WageTypeGroup {
    CHARGEABLE_HOURS = 'chargeable_hours',
    PRODUCTIVE_HOURS = 'productive_hours',
    PRODUCTIVE_WITH_COMMUTING_HOURS = 'productive_with_commuting_hours',
    VACATION_HOURS = 'vacation_hours',
    ILLNESS_HOURS = 'illness_hours',
    TRAINING_HOURS = 'training_hours',
    COMMUTING_HOURS = 'commuting_hours',
    ADMINISTRATION_HOURS = 'administration_hours',
    LEISURE_TIME_HOURS = 'leisure_time_hours',
    BAD_WEATHER_HOURS = 'bad_weather_hours',
    BANK_HOLIDAY_HOURS = 'bank_holiday_hours',
    PREPARATION_HOURS = 'preparation_hours',
    TOTAL_HOURS = 'total_hours',
}
