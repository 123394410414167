import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatISO } from "date-fns/formatISO";
import { isValid } from "date-fns/isValid";

import { useToast } from "~components/shared/useToast.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { AbsencesService } from "~generated";

import { tenantKeys } from "./tenants";

import type { UseQueryOptions } from "@tanstack/react-query";
import type { Absence, EditAbsence, NewAbsence } from "~generated";

type FilterParams = {
  startDate?: string;
  endDate?: string;
};
const absenceKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "absences"] as const,
  list: (tenantId: string, filterParams?: FilterParams) =>
    [...absenceKeys.base(tenantId), "list", filterParams] as const,
  detail: (tenantId: string, id: string) =>
    [...absenceKeys.base(tenantId), "detail", id] as const,
};

async function getAbsences(
  tenantId: string,
  startDate?: string,
  endDate?: string,
) {
  const absences = await AbsencesService.getAbsences(
    tenantId,
    startDate,
    endDate,
  );
  if (!absences || !absences.absences) {
    return [];
  }
  return absences.absences;
}

export function useAbsencesForToday(
  options?: Partial<
    UseQueryOptions<
      Absence[],
      Error,
      Absence[],
      ReturnType<(typeof absenceKeys)["list"]>
    >
  >,
) {
  const now = new Date();
  return useAbsences({ startDate: now, endDate: now }, options);
}

type AbsenceFilters = {
  startDate?: Date | null;
  endDate?: Date | null;
};

export function useAbsences(
  filters?: AbsenceFilters,
  options?: Partial<
    UseQueryOptions<
      Absence[],
      Error,
      Absence[],
      ReturnType<typeof absenceKeys.list>
    >
  >,
) {
  const currentTenant = useCurrentTenant();

  const startDate =
    filters?.startDate && isValid(filters.startDate)
      ? formatISO(filters.startDate, { representation: "date" })
      : undefined;

  const endDate =
    filters?.endDate && isValid(filters.endDate)
      ? formatISO(filters.endDate, { representation: "date" })
      : undefined;

  return useQuery({
    queryKey: absenceKeys.list(currentTenant.id, { startDate, endDate }),
    queryFn: async () => getAbsences(currentTenant.id, startDate, endDate),
    ...options,
  });
}

export function useCreateAbsence() {
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (absence: NewAbsence) =>
      AbsencesService.createAbsence(currentTenant.id, absence),

    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: absenceKeys.base(currentTenant.id),
      });
    },
  });
  return mutation;
}

export type UpdateAbsenceParams = {
  absenceId: string;
  absence: EditAbsence;
};
export function useUpdateAbsence() {
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ absenceId, absence }: UpdateAbsenceParams) =>
      AbsencesService.updateAbsence(currentTenant.id, absenceId, absence),

    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: absenceKeys.base(currentTenant.id),
      });
    },
  });
  return mutation;
}

export function useDeleteAbsence() {
  const t = useTranslation();
  const { showSuccessToast } = useToast();

  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (absenceId: string) =>
      AbsencesService.deleteAbsence(currentTenant.id, absenceId),

    onSuccess: async () => {
      showSuccessToast(t.deleteAbsenceSuccess);
      await queryClient.resetQueries({
        queryKey: absenceKeys.base(currentTenant.id),
      });
    },
  });
  return mutation;
}

export function useAbsence(id: string) {
  const currentTenant = useCurrentTenant();
  return useQuery({
    queryKey: absenceKeys.detail(currentTenant.id, id),
    queryFn: async () => AbsencesService.getAbsence(currentTenant.id, id),
  });
}
