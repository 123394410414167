import { parseISO } from "date-fns/parseISO";

import { netTrackedAndUntrackedTime } from "~components/TimeManagement/shared/interval.ts";
import { TrackingTaskType } from "~generated";
import { convertToDateOrNull } from "~lib/dateHelpers.ts";

import type { DataProvider } from "./DataProvider.ts";
import type {
  ConstructionSite,
  Employee,
  TimeEntry,
  WorkPackage,
} from "~generated";
import type { Resource } from "~lib/resourceHelpers.ts";

export type Anomalies = {
  exceedsMaxHours: boolean;
  employeeMultipleArrivalCommute: boolean;
  employeeMultipleDepartureCommute: boolean;
  multipleArrivalDriversOnTimeTracking: boolean;
  multipleDepartureDriversOnTimeTracking: boolean;
  arrivalCommuteTimeBiggerThanTrackingSwitchTime: boolean;
  departureCommuteTimeBiggerThanTrackingSwitchTime: boolean;
};

export class EmbeddedTimeEntry implements TimeEntry {
  anomalies: Anomalies = {
    exceedsMaxHours: false,
    employeeMultipleArrivalCommute: false,
    employeeMultipleDepartureCommute: false,
    multipleArrivalDriversOnTimeTracking: false,
    multipleDepartureDriversOnTimeTracking: false,
    arrivalCommuteTimeBiggerThanTrackingSwitchTime: false,
    departureCommuteTimeBiggerThanTrackingSwitchTime: false,
  };

  constructor(
    private timeEntry: TimeEntry,
    private dataProvider: DataProvider,
  ) {}

  get hasAnomalies() {
    return (
      this.anomalies.employeeMultipleArrivalCommute ||
      this.anomalies.employeeMultipleDepartureCommute ||
      this.anomalies.multipleArrivalDriversOnTimeTracking ||
      this.anomalies.multipleDepartureDriversOnTimeTracking ||
      this.anomalies.arrivalCommuteTimeBiggerThanTrackingSwitchTime ||
      this.anomalies.departureCommuteTimeBiggerThanTrackingSwitchTime
    );
  }

  get id() {
    return this.timeEntry.id;
  }

  get time_tracking_id() {
    return this.timeEntry.time_tracking_id;
  }

  get absence_id() {
    return this.timeEntry.absence_id;
  }

  get tracking_task_type() {
    return this.timeEntry.tracking_task_type;
  }

  get isTrackingTaskTypeWork() {
    return this.timeEntry.tracking_task_type === TrackingTaskType.WORK;
  }

  get isPositionAvailable() {
    return this.isTrackingTaskTypeWork;
  }

  get originalResource() {
    return this.timeEntry.resource;
  }

  get status() {
    return this.timeEntry.status;
  }

  get start_time() {
    return this.timeEntry.start_time;
  }

  get stop_time() {
    return this.timeEntry.stop_time;
  }

  get pause_duration_seconds() {
    return this.timeEntry.pause_duration_seconds;
  }

  get distance_between_start_and_work_location() {
    return this.timeEntry.distance_between_start_and_work_location;
  }

  get arrival_commute_type() {
    return this.timeEntry.arrival_commute_type;
  }

  get departure_commute_type() {
    return this.timeEntry.departure_commute_type;
  }

  get arrival_commute_time_seconds() {
    return this.timeEntry.arrival_commute_time_seconds;
  }

  get departure_commute_time_seconds() {
    return this.timeEntry.departure_commute_time_seconds;
  }

  get distance_between_stop_and_work_location() {
    return this.timeEntry.distance_between_stop_and_work_location;
  }

  get updated_at() {
    return this.timeEntry.updated_at;
  }

  get work_package_id() {
    return this.timeEntry.work_package_id;
  }

  get construction_site_manager_id() {
    return this.timeEntry.construction_site_manager_id;
  }

  get time_tracking_event_ids() {
    return this.timeEntry.time_tracking_event_ids;
  }

  get original_team_name() {
    return this.timeEntry.original_team_name;
  }

  get task() {
    return this.timeEntry.task;
  }

  get approved_at() {
    return this.timeEntry.approved_at;
  }

  get approved_by_id() {
    return this.timeEntry.approved_by_id;
  }

  get exported_at() {
    return this.timeEntry.exported_at;
  }

  get exported_by_id() {
    return this.timeEntry.exported_by_id;
  }

  get deleted_at() {
    return this.timeEntry.deleted_at;
  }

  get deleted_by_id() {
    return this.timeEntry.deleted_by_id;
  }

  get was_modified() {
    return this.timeEntry.was_modified;
  }

  get isOverlapping() {
    return this.overlaps;
  }

  get deletedAt() {
    return convertToDateOrNull(this.deleted_at);
  }

  get approvedAt() {
    return convertToDateOrNull(this.approved_at);
  }

  get exportedAt() {
    return convertToDateOrNull(this.exported_at);
  }

  #stopTime: Date | null = null;

  get stopTime() {
    if (!this.#stopTime) {
      this.#stopTime = this.stop_time ? parseISO(this.stop_time) : null;
    }
    return this.#stopTime;
  }

  set stopTime(value: Date | null) {
    this.#stopTime = value;
  }

  #startTime: Date | null = null;

  get startTime(): Date | null {
    if (!this.#startTime) {
      this.#startTime = this.start_time ? parseISO(this.start_time) : null;
    }
    return this.#startTime;
  }

  set startTime(value: Date | null) {
    this.#startTime = value;
  }

  get netDurationSeconds() {
    if (!this.startTime || !this.stopTime) {
      return 0;
    }

    if (
      this.pause_duration_seconds !== null &&
      this.pause_duration_seconds !== undefined
    ) {
      return (
        (this.stopTime.getTime() - this.startTime.getTime()) / 1000 -
        this.pause_duration_seconds
      );
    }

    const { trackedDuration } = netTrackedAndUntrackedTime({
      workIntervals: [
        {
          start: this.startTime,
          end: this.stopTime,
        },
      ],
      resourceType: this.originalResource.type,
      trackingTaskType: this.tracking_task_type,
    });

    return trackedDuration;
  }

  get breakDurationSeconds() {
    if (!this.startTime || !this.stopTime) {
      return 0;
    }
    if (
      this.pause_duration_seconds !== null &&
      this.pause_duration_seconds !== undefined
    ) {
      return this.pause_duration_seconds;
    }

    return netTrackedAndUntrackedTime({
      workIntervals: [
        {
          start: this.startTime,
          end: this.stopTime,
        },
      ],
      resourceType: this.originalResource.type,
      trackingTaskType: this.tracking_task_type,
    }).untrackedDuration;
  }

  #constructionSite: ConstructionSite | undefined;

  get constructionSite() {
    if (!this.#constructionSite) {
      this.#constructionSite = this.dataProvider.findConstructionSite(
        this.workPackage?.construction_site_id,
      );
    }

    return this.#constructionSite;
  }

  #resource: Resource | undefined;

  get resource() {
    if (!this.#resource) {
      this.#resource = this.dataProvider.findResource(this.originalResource.id);
    }
    if (!this.#resource) {
      this.#resource = this.originalResource;
    }
    return this.#resource;
  }

  #employee: Employee | undefined;

  get employee() {
    if (!this.#employee) {
      this.#employee = this.dataProvider.findEmployee(this.originalResource.id);
    }
    return this.#employee;
  }

  #workPackage: WorkPackage | undefined;

  get workPackage() {
    if (!this.#workPackage) {
      this.#workPackage = this.dataProvider.findWorkPackage(
        this.work_package_id,
      );
    }
    return this.#workPackage;
  }

  #hasOverlaps: boolean | undefined;

  get overlaps() {
    if (this.#hasOverlaps === undefined) {
      this.#hasOverlaps = this.dataProvider.hasOverlaps(this.id);
    }
    return this.#hasOverlaps;
  }

  #approvedBy: Employee | undefined;

  get approvedBy() {
    if (!this.#approvedBy) {
      this.#approvedBy = this.dataProvider.findEmployee(this.approved_by_id);
    }
    return this.#approvedBy;
  }

  #exportedBy: Employee | undefined;

  get exportedBy() {
    if (!this.#exportedBy) {
      this.#exportedBy = this.dataProvider.findEmployee(this.exported_by_id);
    }
    return this.#exportedBy;
  }

  #deletedBy: Employee | undefined;

  get deletedBy() {
    if (!this.#deletedBy) {
      this.#deletedBy = this.dataProvider.findEmployee(this.deleted_by_id);
    }
    return this.#deletedBy;
  }

  get start_geolocation_position() {
    return this.timeEntry.start_geolocation_position;
  }

  get start_position_outdated() {
    return this.timeEntry.start_position_outdated;
  }

  get stop_geolocation_position() {
    return this.timeEntry.stop_geolocation_position;
  }

  get stop_position_outdated() {
    return this.timeEntry.stop_position_outdated;
  }
}
