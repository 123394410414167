/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AccountSubgroup {
    FOREIGN_INTERNAL = 'foreign_internal',
    FOREIGN_EXTERNAL = 'foreign_external',
    TOOLS_INTERNAL = 'tools_internal',
    TOOLS_EXTERNAL = 'tools_external',
    REVENUE_INVOICED = 'revenue_invoiced',
    REVENUE_PARTIALLY_PAID = 'revenue_partially_paid',
    REVENUE_PROCEEDS = 'revenue_proceeds',
}
