import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import styles from "./CurrentEmployee.module.scss";

export function CurrentEmployee() {
  const t = useTranslation();
  return (
    <div className={styles.currentEmployee}>
      <CheckCircleIcon />
      <b>{t.toolFilterMyResponsibilities}</b>
    </div>
  );
}
