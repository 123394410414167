/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ControllingMonthlyEmployeeTarget = {
    business_line_code: string | null;
    hours: number | null;
    month: number;
    staff_number: string;
    tenant_number: number;
    wage_type: ControllingMonthlyEmployeeTarget.wage_type;
    year: number;
};
export namespace ControllingMonthlyEmployeeTarget {
    export enum wage_type {
        PRODUCTIVE_HOURS = 'productive_hours',
        COMMUTING_HOURS = 'commuting_hours',
    }
}

