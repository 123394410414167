import camelCase from "lodash/camelCase";

import { ApiError } from "~generated";

import type { FieldValues, Path, UseFormSetError } from "react-hook-form";
import type { Translations } from "~contexts/I18nContext/I18nContext.tsx";
import type { Errors } from "~generated";

const isErrors = (thing: unknown): thing is Errors => {
  if (thing === null || typeof thing !== "object") {
    return false;
  }

  const maybeErrors = thing as Errors;

  return !!maybeErrors.errors;
};

export type ErrorsBody = {
  errors: Record<string, string[]>;
};

export const getApiErrors = (error: unknown) => {
  if (!(error instanceof ApiError)) {
    throw error;
  }

  const errorBody = error.body;
  if (!isErrors(errorBody) || !errorBody.errors) {
    throw error;
  }

  return errorBody.errors;
};

export function applyErrorsToFormFields<T extends FieldValues>(
  errors: Record<string, string[]>,
  setError: UseFormSetError<T>,
  values: T,
  t?: Translations,
) {
  Object.keys(errors).forEach((field) => {
    const fieldName = camelCase(field);
    if (!(fieldName in values)) {
      throw new Error(
        `Error for field '${fieldName}', when field does not exist in form.`,
      );
    }
    const message = errors[field].map((v) => maybeTranslate(t, v)).join(", ");
    setError(fieldName as Path<T>, { type: "server", message });
  });
}

function maybeTranslate(t: Translations | undefined, message: string) {
  if (!t) {
    return message;
  }
  if (isKeyOf(message, t)) {
    return t[message] as string;
  }
  return t.unknownBackendError;
}

function isKeyOf(key: string, object: object): key is keyof Translations {
  return key in object;
}

export const findEntity = <E extends { id?: string }>(
  entities?: E[],
  id?: string | null,
) => {
  if (!id) {
    return undefined;
  }

  return entities?.find((entity) => entity.id === id);
};

export const findEntities = <E extends { id?: string }>(
  entities?: E[],
  ids?: string[],
) =>
  (ids &&
    ids
      .map((id) => findEntity(entities, id))
      .filter((e): e is E => Boolean(e))) ||
  [];
