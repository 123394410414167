import { tenantKeys } from "./tenants";

export const teamAssignmentKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "teamAssignments"] as const,
  list: (tenantId: string) =>
    [...teamAssignmentKeys.base(tenantId), "list"] as const,
  me: (tenantId: string) =>
    [...teamAssignmentKeys.base(tenantId), "me"] as const,
  detail: (tenantId: string, id: string) =>
    [...teamAssignmentKeys.base(tenantId), "detail", id] as const,
};
