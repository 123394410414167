import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList";

import type { HTMLAttributes, ReactNode } from "react";

import styles from "./Drawer.module.scss";

export function DrawerContainer({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classList(styles.container, className)} {...props}></div>
  );
}

export interface DrawerHeaderProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
}

export function DrawerHeader({
  className,
  onClose,
  children,
  ...props
}: DrawerHeaderProps) {
  const t = useTranslation();
  return (
    <div className={classList(styles.header, className)} {...props}>
      <h4>{children}</h4>
      <IconButton
        className={styles.closeBtn}
        onClick={onClose}
        aria-label={t.close}
        size="small"
        name={t.close}
        sx={{ mt: "-5px" }}
      >
        <CloseIcon fontSize="small" aria-label={t.close} />
      </IconButton>
    </div>
  );
}

export interface DrawerProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
}

export function Drawer({ open, children, ...props }: DrawerProps) {
  return (
    <div
      className={classList(styles.drawer, open && styles.drawer_open)}
      role="dialog"
      {...props}
    >
      <Paper className={styles.paper}>{children}</Paper>
    </div>
  );
}

export function DrawerContent({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return <div className={classList(styles.content, className)} {...props} />;
}

export function DrawerSegment({
  label,
  children,
}: {
  label?: string;
  children?: ReactNode;
}) {
  return (
    <div className={styles.segment}>
      {label && <span>{label}</span>}
      <div>{children}</div>
    </div>
  );
}

export function DrawerActions({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return <div className={classList(styles.actions, className)} {...props} />;
}
