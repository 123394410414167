import { SharedAbsence, TrackingTaskType } from "~generated";

import type { Translations } from "~contexts/I18nContext/I18nContext.tsx";

export function absenceCategoryForTrackingTaskType(
  trackingTaskType: TrackingTaskType,
): SharedAbsence.category {
  switch (trackingTaskType) {
    case TrackingTaskType.SICKNESS:
      return SharedAbsence.category.SICKNESS;
    case TrackingTaskType.VACATION:
      return SharedAbsence.category.VACATION;
    case TrackingTaskType.SCHOOL:
      return SharedAbsence.category.SCHOOL;
    case TrackingTaskType.TRAINING:
      return SharedAbsence.category.TRAINING;
    case TrackingTaskType.LEISURE_TIME_ACCOUNT:
      return SharedAbsence.category.LEISURE_TIME_ACCOUNT;
    case TrackingTaskType.WORK:
    default:
      throw new Error(
        `${trackingTaskType} can't be mapped to an absence category`,
      );
  }
}

export function isAbsenceType(trackingTaskType?: TrackingTaskType | null) {
  if (!trackingTaskType) {
    return false;
  }
  return [
    TrackingTaskType.SICKNESS,
    TrackingTaskType.VACATION,
    TrackingTaskType.SCHOOL,
    TrackingTaskType.TRAINING,
    TrackingTaskType.LEISURE_TIME_ACCOUNT,
  ].includes(trackingTaskType);
}

export function isWorkType(trackingTaskType?: TrackingTaskType | null) {
  return trackingTaskType === TrackingTaskType.WORK;
}

export function getLabelForTrackingTaskType(
  t: Translations,
  trackingTaskType?: TrackingTaskType | null,
  workPackageId?: string | null,
) {
  if (!trackingTaskType) {
    return "Unbekannt";
  }
  if (isAbsenceType(trackingTaskType)) {
    return t.timeEntryHistoryAbsenceLabel;
  }
  if (isWorkType(trackingTaskType)) {
    if (workPackageId) {
      return t.timeEntryHistoryWorkPackageLabel;
    }
    return t.timeEntryHistoryManagerLabel;
  }
  return "Aufgabe";
}
