import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { optimisticallyUpdateSyncStatusToRunning } from "~api/sync.ts";
import { useToast } from "~components/shared/useToast.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { ConstructionSitesService } from "~generated";

import { teamAssignmentKeys } from "./teamAssignmentKeys";
import { tenantKeys } from "./tenants";

import type { UseQueryOptions } from "@tanstack/react-query";
import type {
  ConstructionSite,
  ConstructionSiteDetail,
  Tenant,
  UpdateConstructionSitePlan,
} from "~generated";

export const constructionSiteKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "constructionSites"] as const,
  list: (tenantId: string, includeDeleted: boolean) =>
    [...constructionSiteKeys.base(tenantId), "list", includeDeleted] as const,
  show: (tenantId: string, id: string) =>
    [...constructionSiteKeys.base(tenantId), "show", id] as const,
  syncStatus: (tenantId: string) =>
    [...constructionSiteKeys.base(tenantId), "syncStatus"] as const,
};

async function getConstructionSite({
  tenantId,
  id,
}: {
  tenantId: Tenant["id"];
  id: ConstructionSite["id"];
}) {
  const constructionSite = await ConstructionSitesService.getConstructionSite(
    tenantId,
    id,
  );
  return constructionSite;
}

async function getConstructionSites({
  tenantId,
  includeDeleted,
}: {
  tenantId: Tenant["id"];
  includeDeleted: boolean;
}) {
  const constructionSites = await ConstructionSitesService.getConstructionSites(
    tenantId,
    includeDeleted,
  );
  if (!constructionSites || !constructionSites.construction_sites) {
    return [];
  }
  return constructionSites.construction_sites;
}

interface UseConstructionSitesQueryOptions {
  includeDeleted?: boolean;
  useQueryOptions?: Partial<
    UseQueryOptions<
      ConstructionSite[],
      Error,
      ConstructionSite[],
      ReturnType<typeof constructionSiteKeys.list>
    >
  >;
}

export function useConstructionSites(
  {
    includeDeleted = false,
    useQueryOptions,
  }: UseConstructionSitesQueryOptions = {
    includeDeleted: false,
  },
) {
  const currentTenant = useCurrentTenant();

  const queryResult = useQuery({
    ...useQueryOptions,
    queryKey: constructionSiteKeys.list(currentTenant.id, includeDeleted),
    queryFn: async () =>
      getConstructionSites({
        tenantId: currentTenant.id,
        includeDeleted,
      }),
  });

  return {
    ...queryResult,
    data: queryResult.data || [],
  };
}

export function useConstructionSite(
  id: string,
  useQueryOptions?: Partial<
    UseQueryOptions<
      ConstructionSiteDetail,
      Error,
      ConstructionSiteDetail,
      ReturnType<typeof constructionSiteKeys.show>
    >
  >,
) {
  const currentTenant = useCurrentTenant();

  return useQuery({
    ...useQueryOptions,
    queryKey: constructionSiteKeys.show(currentTenant.id, id),
    queryFn: async () =>
      getConstructionSite({
        tenantId: currentTenant.id,
        id,
      }),
  });
}

export function useConstructionSitesSyncStatus() {
  const currentTenant = useCurrentTenant();

  return useQuery({
    queryKey: constructionSiteKeys.syncStatus(currentTenant.id),
    queryFn: async () =>
      ConstructionSitesService.getConstructionSitesSyncStatus(currentTenant.id),
    refetchInterval: 5000,
  });
}

export function useSyncConstructionSites() {
  const queryClient = useQueryClient();
  const currentTenant = useCurrentTenant();

  return useMutation({
    mutationFn: async () =>
      ConstructionSitesService.syncConstructionSites(currentTenant.id),

    onMutate: () => {
      optimisticallyUpdateSyncStatusToRunning({
        queryKey: constructionSiteKeys.syncStatus(currentTenant.id),
        queryClient,
      });
    },
  });
}

export interface UpdateConstructionSitePlanProps {
  id: string;
  requestBody?: UpdateConstructionSitePlan;
}

export function useUpdateConstructionSitePlan() {
  const { showSuccessToast } = useToast();
  const t = useTranslation();
  const currentTenant = useCurrentTenant();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ id, requestBody }: UpdateConstructionSitePlanProps) =>
      ConstructionSitesService.updateConstructionSitePlan(
        currentTenant.id,
        id,
        requestBody,
      ),

    onSuccess: async () => {
      showSuccessToast(t.editSuccess);
      await queryClient.invalidateQueries({
        queryKey: teamAssignmentKeys.list(currentTenant.id),
      });
    },
  });
  return mutation;
}
