/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InvalidProject = {
    ID_PROJEKT: string;
    NR_PROJEKT: string;
    BEZEICHNUNG: string;
    INVALID_REASON: InvalidProject.INVALID_REASON;
};
export namespace InvalidProject {
    export enum INVALID_REASON {
        INVALID_TENANT_NUMBER = 'INVALID_TENANT_NUMBER',
        UNKNOWN_TENANT_NUMBER = 'UNKNOWN_TENANT_NUMBER',
    }
}

