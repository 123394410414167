import memoize from "lodash/memoize";

import { absenceCategoryTranslations } from "~components/shared/absenceCategoryTranslations.ts";
import { absenceCategoryForTrackingTaskType } from "~components/TimeManagement/shared/trackingTaskTypeHelpers.ts";
import { GroupingOption } from "~components/TimeManagement/shared/useGroupedEmbeddedTimeEntries/GroupingOption";
import { TimeEntryStatus, TrackingTaskType } from "~generated";
import { hashCode } from "~lib/hashCode";
import { ResourceType, getResourceName } from "~lib/resourceHelpers.ts";

import type { EmbeddedTimeEntry } from "~components/TimeManagement/shared/useEmbeddedTimeEntries/EmbeddedTimeEntry";
import type { Translations } from "~contexts/I18nContext/I18nContext.tsx";
import type { TimeEntry } from "~generated";

export interface TimeEntriesActionButtonProps {
  timeEntries: EmbeddedTimeEntry[];
}

export const canBeModified = (timeEntry: TimeEntry): boolean =>
  timeEntry.status === TimeEntryStatus.OPEN;

export const canBeUnapproved = (timeEntry: TimeEntry): boolean =>
  timeEntry.status === TimeEntryStatus.APPROVED;

export const canBeApproved = (timeEntry: EmbeddedTimeEntry): boolean =>
  canBeModified(timeEntry) && !timeEntry.isOverlapping;

export const canAssignWorkPackage = ({
  isTrackingTaskTypeWork,
  workPackage,
}: EmbeddedTimeEntry): boolean => isTrackingTaskTypeWork && !workPackage;

export const isToolTimeEntry = (timeEntry: TimeEntry): boolean =>
  timeEntry.resource.type === ResourceType.TOOL;

export const isEmployeeTimeEntry = (timeEntry: TimeEntry): boolean =>
  timeEntry.resource.type === ResourceType.EMPLOYEE;

const isWholeDayTrackingTaskTypeMap: Record<TrackingTaskType, boolean> = {
  [TrackingTaskType.WORK]: false,
  [TrackingTaskType.SCHOOL]: true,
  [TrackingTaskType.SICKNESS]: true,
  [TrackingTaskType.TRAINING]: false,
  [TrackingTaskType.VACATION]: true,
  [TrackingTaskType.LEISURE_TIME_ACCOUNT]: true,
};

export function isWholeDayTrackingTaskType(
  trackingTaskType: TrackingTaskType | null,
) {
  if (!trackingTaskType) {
    return false;
  }
  return isWholeDayTrackingTaskTypeMap[trackingTaskType];
}

interface GetGroupNameProps {
  groupingOption: GroupingOption;
  timeEntry: EmbeddedTimeEntry;
  t: Translations;
}

function getGroupName({ groupingOption, timeEntry, t }: GetGroupNameProps) {
  let displayValue: string;

  switch (groupingOption) {
    case GroupingOption.Team:
      displayValue = timeEntry.original_team_name || t.timeApprovalNoTeam;
      break;
    case GroupingOption.Resource:
      displayValue = getResourceName(timeEntry.resource);
      break;
    case GroupingOption.Task:
      if (!timeEntry.isTrackingTaskTypeWork) {
        const absenceCategory = absenceCategoryForTrackingTaskType(
          timeEntry.tracking_task_type,
        );
        displayValue = t[absenceCategoryTranslations[absenceCategory]];
      } else if (timeEntry.workPackage?.name) {
        displayValue = `${timeEntry.workPackage.name} (${timeEntry.constructionSite?.number})`;
      } else {
        displayValue = t.timeApprovalConstructionSiteManagerAccount;
      }
      break;
    default:
      throw Error(
        `Unhandled GroupingOption: ${groupingOption} for getGroupName`,
      );
  }
  return displayValue;
}

interface GetGroupNameForTimeEntryProps {
  groupingOption: GroupingOption;
  timeEntry: EmbeddedTimeEntry;
  t: Translations;
}

export const getGroupNameForTimeEntry = memoize(
  ({ groupingOption, timeEntry, t }: GetGroupNameForTimeEntryProps) =>
    getGroupName({
      groupingOption,
      timeEntry,
      t,
    }),
  ({ timeEntry, groupingOption }) =>
    hashCode(`${timeEntry.id}${groupingOption}`),
);

export function getTimeEntryStatusTranslation({
  t,
  status,
}: {
  t: Translations;
  status: TimeEntryStatus;
}) {
  switch (status) {
    case TimeEntryStatus.OPEN:
      return t.timeEntryStatusOpen;
    case TimeEntryStatus.APPROVED:
      return t.timeEntryStatusApproved;
    case TimeEntryStatus.DELETED:
      return t.timeEntryStatusDeleted;
    case TimeEntryStatus.EXPORTED:
      return t.timeEntryStatusExported;
    case TimeEntryStatus.MANAGER_ACCOUNT:
      return t.timeEntryStatusManagerAccount;
    default:
      throw new Error(`unknown status ${status}`);
  }
}
