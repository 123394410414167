/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum GeoConfidence {
    LOW = 'Low',
    MEDIUM = 'Medium',
    HIGH = 'High',
    MANUAL = 'Manual',
}
