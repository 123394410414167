/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TrackingTaskType {
    WORK = 'work',
    SCHOOL = 'school',
    SICKNESS = 'sickness',
    TRAINING = 'training',
    VACATION = 'vacation',
    LEISURE_TIME_ACCOUNT = 'leisure_time_account',
}
