import { useQuery } from "@tanstack/react-query";
import { formatISO } from "date-fns/formatISO";

import { tenantKeys } from "~api/tenants.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { TimeEntriesService } from "~generated";
import { isValidDate, muiDateRangeToDateRange } from "~lib/dateHelpers.ts";

import type { DateRange as MuiDateRange } from "@mui/x-date-pickers-pro";
import type { DateRange } from "~lib/dateHelpers.ts";

type TimeEntriesFilters = {
  startDate?: string;
  endDate?: string;
};

type ExportPreviewFilters = {
  startDate?: string;
  stopDate?: string;
  onlyUnexported: boolean;
};

type OverlappingTimeEntriesByResourceFilters = {
  resourceId: string;
  timeRange: StringDateRange;
};

export const timeEntriesKeys = {
  base: () => ["timeEntries"] as const,
  byTenant: (tenantId: string) =>
    [...timeEntriesKeys.base(), ...tenantKeys.detail(tenantId)] as const,
  list: (tenantId: string, filters?: TimeEntriesFilters) =>
    [...timeEntriesKeys.byTenant(tenantId), "list", filters] as const,
  exportPreview: (tenantId: string, filters: ExportPreviewFilters) =>
    [...timeEntriesKeys.byTenant(tenantId), "exportPreview", filters] as const,
  detail: (tenantId: string, id: string) =>
    [...timeEntriesKeys.byTenant(tenantId), "detail", id] as const,
  history: (tenantId: string, id: string) =>
    [...timeEntriesKeys.detail(tenantId, id), "history"] as const,
  overlappingTimeEntriesByResource: (
    filters: OverlappingTimeEntriesByResourceFilters,
  ) => [...timeEntriesKeys.base(), "overlapping", filters] as const,
  overlappingTimeEntryIds: (dateRange: StringDateRange) =>
    [...timeEntriesKeys.base(), "overlappingTimeEntryIds", dateRange] as const,
};

export function useTimeEntries(
  [startDate, endDate]: MuiDateRange<Date> | void = [null, null],
) {
  const currentTenant = useCurrentTenant();

  const startDateISO = startDate
    ? formatISO(startDate, { representation: "date" })
    : undefined;
  const endDateISO = endDate
    ? formatISO(endDate, { representation: "date" })
    : undefined;

  return useQuery({
    queryKey: timeEntriesKeys.list(currentTenant.id, {
      startDate: startDateISO,
      endDate: endDateISO,
    }),
    queryFn: async ({ signal }) => {
      const request = TimeEntriesService.getTimeEntries(
        currentTenant.id,
        startDateISO,
        endDateISO,
      );

      signal?.addEventListener("abort", () => {
        request.cancel();
      });

      return (await request).time_entries;
    },
  });
}

export function useTimeEntryHistoryEntries({
  timeEntryId,
}: {
  timeEntryId?: string;
}) {
  const currentTenant = useCurrentTenant();

  return useQuery({
    queryKey: timeEntriesKeys.history(currentTenant.id, timeEntryId ?? ""),
    enabled: !!timeEntryId,
    queryFn: async () =>
      (
        await TimeEntriesService.getTimeEntryHistoryEntries(
          currentTenant.id,
          timeEntryId ?? "",
        )
      ).history_entries,
  });
}

export function useTimeEntriesExportPreview({
  tenantId,
  startDate,
  stopDate,
  onlyUnexported,
  queryEnabled,
}: {
  tenantId: string;
  startDate?: Date | null;
  stopDate?: Date | null;
  onlyUnexported: boolean;
  queryEnabled: boolean;
}) {
  const startDateISO = formatISO(startDate || new Date(), {
    representation: "date",
  });
  const endDateISO = formatISO(stopDate || new Date(), {
    representation: "date",
  });

  return useQuery({
    queryKey: timeEntriesKeys.exportPreview(tenantId, {
      startDate: startDateISO,
      stopDate: startDateISO,
      onlyUnexported,
    }),
    queryFn: async () => {
      const request = TimeEntriesService.getTimeEntriesExportPreview(
        tenantId,
        startDateISO,
        endDateISO,
        onlyUnexported,
      );

      return (await request).time_entries;
    },
    enabled: queryEnabled,
  });
}

export function useOverlappingTimeEntryIds(
  dateRange: MuiDateRange<Date> | undefined,
) {
  const strDateRange = toStringDateRange(muiDateRangeToDateRange(dateRange));

  return useQuery({
    queryKey: timeEntriesKeys.overlappingTimeEntryIds(strDateRange),
    queryFn: async () => {
      if (!strDateRange) {
        return [];
      }
      const result = await TimeEntriesService.getOverlappingTimeEntryIds(
        strDateRange.start,
        strDateRange.end,
      );
      return result.overlapping_time_entry_ids;
    },
    enabled: strDateRange !== null,
  });
}

export function useOverlappingTimeEntries({
  resourceId,
  timeRange,
}: {
  resourceId?: string;
  timeRange: DateRange | null;
}) {
  const dateRange = toStringDateRange(timeRange);

  const queryKey = timeEntriesKeys.overlappingTimeEntriesByResource({
    resourceId: resourceId || "undefined",
    timeRange: dateRange,
  });

  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!resourceId || !timeRange || !dateRange) {
        return [];
      }
      const request = TimeEntriesService.getOverlappingTimeEntries(
        resourceId,
        dateRange.start,
        dateRange.end,
      );
      return (await request).overlapping_time_entries;
    },
    enabled: dateRange !== null,
  });
}

type StringDateRange = { start: string; end: string } | null;

function toStringDateRange(
  dateRange: DateRange | null | undefined,
): StringDateRange {
  if (
    !dateRange ||
    !isValidDate(dateRange.start) ||
    !isValidDate(dateRange.end)
  ) {
    return null;
  }

  return { start: formatISO(dateRange.start), end: formatISO(dateRange.end) };
}
