/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeoPosition } from './GeoPosition';
import type { Tool } from './Tool';
import type { ToolDecommissionReason } from './ToolDecommissionReason';
export type ToolDetail = (Tool & {
    attachments: string | null;
    comment: string | null;
    construction_year: number | null;
    cost_center: string | null;
    daily_rate: number | null;
    decommission_comment: string | null;
    decommissioned_at: string | null;
    decommission_reason: ToolDecommissionReason | null;
    financing_to: string | null;
    financing_type: ToolDetail.financing_type | null;
    readonly in_usage: boolean;
    insurance_line: string | null;
    insurance_tool_type: string | null;
    insurance_type: string | null;
    manufacturer: string | null;
    minute_rate: number | null;
    operating_hours: string | null;
    position: GeoPosition | null;
    power: string | null;
    purchase_date: string | null;
    purchase_price: number | null;
    query_operating_hours: boolean | null;
    size: string | null;
    supplier: string | null;
    temporarily_decommissioned_from: string | null;
    temporarily_decommissioned_to: string | null;
    type_designation: string | null;
});
export namespace ToolDetail {
    export enum financing_type {
        DARLEHEN = 'Darlehen',
        MIETKAUF = 'Mietkauf',
        LEASING = 'Leasing',
    }
}

