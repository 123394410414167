import Button from "@mui/material/Button";

import { useLogout } from "~auth/useLogout.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

export function SignOutButton() {
  const logout = useLogout();
  const t = useTranslation();

  return (
    <Button variant="outlined" onClick={logout}>
      {t.signOut}
    </Button>
  );
}
