import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";
import { memo } from "react";

import { DateRangePicker } from "~components/shared/DateRangePicker/DateRangePicker";
import { EmployeeWithPhone } from "~components/shared/EmployeeWithPhone/EmployeeWithPhone";
import { Select } from "~components/shared/Form/Select.tsx";
import {
  GroupedTable,
  GroupedTableErrorResult,
  GroupedTableHead,
  GroupedTableHeadCell,
  GroupedTableInfoResult,
  GroupedTableItem,
  GroupedTableItemCell,
  GroupedTableItemRow,
} from "~components/shared/GroupedTable/GroupedTable";
import { SearchField } from "~components/shared/SearchField/SearchField";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList";

import { DayTitle } from "./DayTitle";
import { ActionChip } from "./Notification/ActionChip";
import { Description } from "./Notification/Description";
import { NotificationLocationIndicator } from "./Notification/NotificationLocationIndicator";

import type {
  EmbeddedNotification,
  GroupedNotifications,
} from "./useEmbeddedNotifications";
import type { NotificationFilters } from "./useNotificationsView";

import styles from "./Notifications.module.scss";

type NotificationTableProps = {
  groupedNotifications: GroupedNotifications;
  onSelectNotification: (selectedNotification: EmbeddedNotification) => void;
  selectedNotification?: EmbeddedNotification;
  notificationFilters: NotificationFilters;
  isDateRangeValid: boolean;
  isLoading: boolean;
};
export function NotificationTable({
  groupedNotifications,
  onSelectNotification,
  selectedNotification,
  notificationFilters,
  isDateRangeValid,
  isLoading,
}: NotificationTableProps) {
  const t = useTranslation();
  return (
    <>
      <Filters {...notificationFilters} />
      <GroupedTable>
        <GroupedTableHead>
          <GroupedTableHeadCell>{t.notificationsTime}</GroupedTableHeadCell>
          <GroupedTableHeadCell>
            {t.notificationsOccurrence}
          </GroupedTableHeadCell>
          <GroupedTableHeadCell>{t.notificationsTool}</GroupedTableHeadCell>
          <GroupedTableHeadCell>{t.notificationsSender}</GroupedTableHeadCell>
          <GroupedTableHeadCell>
            {t.notificationsDescription}
          </GroupedTableHeadCell>
        </GroupedTableHead>
        {isDateRangeValid ? (
          <Result
            groupedNotifications={groupedNotifications}
            onSelectNotification={onSelectNotification}
            selectedNotification={selectedNotification}
            isLoading={isLoading}
          />
        ) : (
          <GroupedTableErrorResult text={t.invalidDateRangeInfo} />
        )}
      </GroupedTable>
    </>
  );
}

function Result({
  groupedNotifications,
  onSelectNotification,
  selectedNotification,
  isLoading,
}: {
  groupedNotifications: GroupedNotifications;
  onSelectNotification: (selectedNotification: EmbeddedNotification) => void;
  selectedNotification?: EmbeddedNotification;
  isLoading: boolean;
}) {
  const t = useTranslation();

  if (isLoading) {
    return <GroupedTableInfoResult text={t.loading} />;
  }

  return groupedNotifications.length === 0 ? (
    <GroupedTableInfoResult text={t.notificationsEmptyResult} />
  ) : (
    groupedNotifications.map(([date, notifications]) => (
      <GroupedTableItem
        groupTitle={<DayTitle date={date} />}
        key={date.toISOString()}
      >
        {notifications.map((notification) => (
          <MemoizedNotificationTableRow
            key={notification.id}
            notification={notification}
            isSelected={selectedNotification?.id === notification.id}
            onSelectNotification={onSelectNotification}
          />
        ))}
      </GroupedTableItem>
    ))
  );
}

function Filters({
  unreadOnly,
  setUnreadOnly,
  dateRange,
  setDateRange,
  searchTerm,
  setSearchTerm,
}: NotificationFilters) {
  const t = useTranslation();

  return (
    <Stack direction={"row"} spacing={2} sx={{ marginBottom: "2rem" }}>
      <DateRangePicker
        value={dateRange}
        onChange={setDateRange}
        disableFuture
        label={t.dateRange}
        sx={{ maxWidth: "20rem" }}
      />
      <Select
        size={"small"}
        label={"Status"}
        value={unreadOnly}
        onChange={(event) =>
          setUnreadOnly(event.target.value as NotificationFilters["unreadOnly"])
        }
        sx={{ maxWidth: "10rem" }}
      >
        <MenuItem value={"all"}>{t.allEntries}</MenuItem>
        <MenuItem value={"unread"}>{t.notificationsFilterUnread}</MenuItem>
      </Select>

      <SearchField value={searchTerm} onChange={setSearchTerm} />
    </Stack>
  );
}

interface NotificationTableRowProps {
  notification: EmbeddedNotification;
  isSelected: boolean;
  onSelectNotification: (selectedNotification: EmbeddedNotification) => void;
}

function NotificationTableRow({
  notification,
  isSelected,
  onSelectNotification,
}: NotificationTableRowProps) {
  return (
    <GroupedTableItemRow
      selected={isSelected}
      hover
      variant={notification.read_at ? "outlined" : "elevation"}
      className={classList(!notification.read_at && styles.unreadRow)}
      onClick={() => onSelectNotification(notification)}
    >
      <GroupedTableItemCell>
        <Stack direction={"row"} gap={1}>
          {format(parseISO(notification.notification_at), "HH:mm")}
          <NotificationLocationIndicator notification={notification} />
        </Stack>
      </GroupedTableItemCell>
      <GroupedTableItemCell>
        <ActionChip notificationType={notification.notification_type} />
      </GroupedTableItemCell>
      <GroupedTableItemCell>
        {notification.body.tool?.abbreviation}
      </GroupedTableItemCell>
      <GroupedTableItemCell>
        <EmployeeWithPhone employee={notification.sender} />
      </GroupedTableItemCell>
      <GroupedTableItemCell>
        <Description notification={notification} />
      </GroupedTableItemCell>
    </GroupedTableItemRow>
  );
}
const MemoizedNotificationTableRow = memo(NotificationTableRow);
