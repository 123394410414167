import AutorenewIcon from "@mui/icons-material/Autorenew";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useToast } from "~components/shared/useToast.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

export function RefetchButton() {
  const { refetch, isRefetching } = useRefetchButton();
  const t = useTranslation();
  return (
    <IconButton
      data-testid="refetchButton"
      onClick={refetch}
      disabled={isRefetching}
    >
      <Tooltip title={t.syncButtonTooltip}>
        <AutorenewIcon />
      </Tooltip>
    </IconButton>
  );
}

function useRefetchButton() {
  const [isRefetching, setRefetching] = useState(false);

  const queryClient = useQueryClient();
  const { showSuccessToast } = useToast();
  const t = useTranslation();

  async function refetch() {
    setRefetching(true);
    await queryClient.refetchQueries({ type: "active" });
    setRefetching(false);
    showSuccessToast(t.syncRefetchSuccess);
  }

  return {
    refetch,
    isRefetching,
  };
}
