import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { SettingsDrawer } from "./SettingsDrawer";

type SettingsButtonProps = {
  isDrawerOpen?: boolean;
};
export function SettingsButton({ isDrawerOpen = false }: SettingsButtonProps) {
  const t = useTranslation();
  const [settingsOpen, setSettingsOpen] = useState(isDrawerOpen);

  return (
    <>
      <IconButton
        onClick={() => setSettingsOpen(true)}
        aria-label={t.settingsButton}
      >
        <SettingsIcon fontSize="inherit" />
      </IconButton>
      <SettingsDrawer
        isOpen={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      />
    </>
  );
}
