import { startOfDay } from "date-fns/startOfDay";
import { subWeeks } from "date-fns/subWeeks";
import groupBy from "lodash/groupBy";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import { useCallback, useState } from "react";

import {
  useMarkAllNotificationsRead,
  useMarkNotificationRead,
} from "~components/Notifications/api/notifications.ts";
import { isValidDateRange } from "~components/shared/DateRangePicker/DateRangePicker";
import { matchesAny } from "~lib/matchesAny";
import { getResourceName } from "~lib/resourceHelpers.ts";

import { useNotificationCount } from "./api/notifications";
import { useEmbeddedNotifications } from "./useEmbeddedNotifications";

import type { EmbeddedNotification } from "./useEmbeddedNotifications";
import type { DateRange } from "@mui/x-date-pickers-pro";

// We use days instead of weeks as unit to avoid handling not full weeks.
// Example: Should we count 10 days as 1 week or 2 weeks?
export const MAX_DATE_RANGE_IN_DAYS = 6 * 30; // 6 months of ~30 days

type UnreadFilter = "all" | "unread";
export interface NotificationFilters {
  unreadOnly: UnreadFilter;
  setUnreadOnly: (filter: UnreadFilter) => void;
  dateRange: DateRange<Date>;
  setDateRange: (dateRange: DateRange<Date>) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

export function useNotificationsView() {
  const defaultDateRange: DateRange<Date> = [
    subWeeks(new Date(), 6),
    new Date(),
  ];
  const [dateRange, setDateRange] = useState<DateRange<Date>>(defaultDateRange);
  const isDateRangeValid = isValidDateRange(dateRange, MAX_DATE_RANGE_IN_DAYS);

  const [unreadOnly, setUnreadOnly] = useState<UnreadFilter>("all");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedNotification, setSelectedNotification] = useState<
    EmbeddedNotification | undefined
  >();

  const markNotificationReadMutation = useMarkNotificationRead();
  const markAllNotificationsReadMutation = useMarkAllNotificationsRead();
  const handleNotificationRead = useCallback(
    (notification: EmbeddedNotification) =>
      markNotificationReadMutation.mutate({ id: notification.id }),
    [markNotificationReadMutation],
  );
  const markAllNotificationsRead = () =>
    markAllNotificationsReadMutation.mutate();

  const onSelectNotification = useCallback(
    (notification?: EmbeddedNotification) => {
      setSelectedNotification((prev) =>
        prev === notification ? undefined : notification,
      );
      if (!notification || notification.read_at) return;
      handleNotificationRead(notification);
    },
    [handleNotificationRead],
  );

  const { data: count, isLoading: isCountLoading } = useNotificationCount();
  const { embeddedNotifications, isLoading: isNotificationsLoading } =
    useEmbeddedNotifications(isDateRangeValid ? dateRange : defaultDateRange);

  const filteredEmbeddedNotifications = embeddedNotifications
    .filter((notification) => unreadOnly !== "unread" || !notification.read_at)
    .filter((notification) => matchesSearchTerm(notification, searchTerm));

  const groupedNotifications = groupNotificationsByDate(
    filteredEmbeddedNotifications,
  );

  return {
    count,
    groupedNotifications,
    isLoading: isCountLoading || isNotificationsLoading,
    selectedNotification,
    onSelectNotification,
    notificationFilters: {
      dateRange,
      setDateRange,
      unreadOnly,
      setUnreadOnly,
      searchTerm,
      setSearchTerm,
    },
    isDateRangeValid,
    markAllNotificationsRead,
  };
}

function matchesSearchTerm(
  notification: EmbeddedNotification,
  searchTerm: string,
): boolean {
  return matchesAny(searchTerm, [
    notification.body.tool?.abbreviation,
    getResourceName(notification.sender),
    getResourceName(notification.body.responsibleEmployee),
    getResourceName(notification.body.previousResponsibleEmployee),
    getResourceName(notification.body.changeSet?.oldManager),
    getResourceName(notification.body.changeSet?.newManager),
  ]);
}

function groupNotificationsByDate(
  embeddedNotifications: EmbeddedNotification[],
) {
  const unsortedGroupedNotifications: [Date, EmbeddedNotification[]][] =
    Object.values(
      groupBy(embeddedNotifications, (embeddedNotification) =>
        startOfDay(embeddedNotification.notificationAt),
      ),
    ).map((notificationGroup) => [
      startOfDay(notificationGroup[0].notificationAt),
      notificationGroup,
    ]);
  return reverse(sortBy(unsortedGroupedNotifications, ([day]) => day));
}
