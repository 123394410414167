import {
  deDE as dataGridDe,
  enUS as dataGridEn,
} from "@mui/x-data-grid-pro/locales";
import {
  deDE as datePickersDe,
  enUS as datePickersEn,
} from "@mui/x-date-pickers-pro/locales";
import { de as deLocale } from "date-fns/locale/de";
import { enUS as enLocale } from "date-fns/locale/en-US";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import * as messages from "~translations/translations.ts";

import type { Locale } from "date-fns/types";

const translationsMap = {
  de: {
    locale: deLocale,
    datePickers:
      datePickersDe.components.MuiLocalizationProvider.defaultProps.localeText,
    dataGrid: dataGridDe.components.MuiDataGrid.defaultProps.localeText,
  },
  en: {
    locale: enLocale,
    datePickers:
      datePickersEn.components.MuiLocalizationProvider.defaultProps.localeText,
    dataGrid: dataGridEn.components.MuiDataGrid.defaultProps.localeText,
  },
};

type DatePickersTranslations = (typeof translationsMap)["de"]["datePickers"];
type DataGridTranslations = (typeof translationsMap)["de"]["dataGrid"];

type Language = keyof typeof translationsMap;

export const availableLanguages = Object.keys(translationsMap) as Language[];
export type Translations = {
  [key in keyof (typeof messages)[Language]]: (typeof messages)[Language][key];
};
export type TranslationKey = keyof Translations;

type NonNullableKeys<T> = {
  [K in keyof T]: T[K] extends null ? never : K;
}[keyof T];

export type NonNullTranslationKey = NonNullableKeys<Translations>;

export interface I18nContextType {
  lang: Language;
  setLang: (value: Language | ((val: Language) => Language)) => void;
  locale: Locale;
  translations: Translations;
  datePickersTranslations: DatePickersTranslations;
  dataGridTranslations: DataGridTranslations;
}

const NullI18nContext: I18nContextType = {
  lang: "de",
  setLang: () => {},
  locale: translationsMap.de.locale,
  translations: {} as unknown as Translations,
  datePickersTranslations: {} as unknown as DatePickersTranslations,
  dataGridTranslations: {} as DataGridTranslations,
};

const I18nContext = createContext<I18nContextType>(NullI18nContext);

interface I18nProviderProps {
  children: React.ReactNode;
  defaultLanguage?: Language;
}

export function I18nProvider({
  children,
  defaultLanguage = "de",
}: I18nProviderProps) {
  const [lang, setLang] = useLocalStorage<Language>("lang", defaultLanguage);
  const { locale, dataGrid, datePickers } = useMemo(
    () => translationsMap[lang] || translationsMap.de,
    [lang],
  );

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);

  return (
    <I18nContext.Provider
      value={{
        lang,
        setLang,
        locale,
        translations: messages[lang],
        datePickersTranslations: datePickers,
        dataGridTranslations: dataGrid,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
}

export function useI18n() {
  const i18n = useContext<I18nContextType>(I18nContext);
  if (!i18n) throw new Error("Missing I18nProvider");
  return i18n;
}

export function useTranslation() {
  return useI18n().translations;
}

export function useLocale() {
  return useI18n().locale;
}

function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setStoredValue(valueToStore);
    // Save to local storage
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    }
  };

  return [storedValue, setValue] as const;
}
