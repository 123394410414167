import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TimerIcon from "@mui/icons-material/TimerOutlined";
import CardActionArea from "@mui/material/CardActionArea";

import styles from "./RunningTimeTrackingBannerView.module.scss";

export type Props = {
  title: string;
};
export function RunningTimeTrackingBannerView({ title }: Props) {
  return (
    <CardActionArea
      className={styles.container}
      data-testid="running-time-tracking-banner"
    >
      <div className={styles.content}>
        <span className={styles.icon}>
          <TimerIcon fontSize="medium" />
        </span>
        <div className={styles.title}>{title}</div>
      </div>
      <ArrowForwardIcon />
    </CardActionArea>
  );
}
