/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationBody } from './NotificationBody';
export type Notification = {
    id: string;
    notification_at: string;
    read_at: string | null;
    notification_type: Notification.notification_type;
    sender_id: string;
    body: NotificationBody;
};
export namespace Notification {
    export enum notification_type {
        TOOL_RESPONSIBILITY_TAKEN = 'tool_responsibility_taken',
        TOOL_RESPONSIBILITY_RETURNED = 'tool_responsibility_returned',
        TOOL_DATA_CHANGED = 'tool_data_changed',
        TOOL_PERMANENT_DECOMMISSIONED = 'tool_permanent_decommissioned',
        TOOL_PERMANENT_RECOMMISSIONED = 'tool_permanent_recommissioned',
    }
}

