import ChevronIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { classList } from "~lib/classList";

import type { HTMLAttributes } from "react";

import styles from "./DashboardNavigationGroupView.module.scss";

interface DashboardNavigationGroupViewProps
  extends HTMLAttributes<HTMLDivElement> {
  title: string;
  icon: React.ReactNode;
}

export function DashboardNavigationGroupView({
  title,
  icon,
  children,
  className,
  ...props
}: DashboardNavigationGroupViewProps) {
  return (
    <div className={classList(styles.group, className)} {...props}>
      <h2>
        <span className={styles.icon}>{icon}</span>
        <span>{title}</span>
      </h2>
      <div>{children}</div>
    </div>
  );
}

export function DashboardNavigationGroupItemView({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const theme = useTheme();
  const isMinSm = useMediaQuery(theme.breakpoints.up("sm"));

  const Component = isMinSm ? "div" : Paper;
  return (
    <Component className={classList(styles.item, className)} {...props}>
      <span>{children}</span>
      <span className={styles.icon}>
        <ChevronIcon />
      </span>
    </Component>
  );
}
