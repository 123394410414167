import Drawer from "@mui/material/Drawer";

import { Settings } from "./Settings";
import { SettingsHeader } from "./SettingsHeader";

type SettingsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};
export function SettingsDrawer({ isOpen, onClose }: SettingsDrawerProps) {
  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          pl: 2,
          pr: 1,
          pt: 1,
          width: "300px",
        },
      }}
    >
      <SettingsHeader onClose={onClose} />
      <Settings />
    </Drawer>
  );
}
