/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobAbilities } from '../models/JobAbilities';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class JobAbilitiesService {
    /**
     * list of job abilities
     * @returns JobAbilities job abilities found
     * @throws ApiError
     */
    public static getJobAbilities(): CancelablePromise<JobAbilities> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/job_abilities',
        });
    }
}
