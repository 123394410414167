import { createContext, useContext } from "react";

import { useCurrentEmployeeRunningTimeTrackings } from "~components/TimeManagement/api/timeTracking.ts";

import type { ReactNode } from "react";
import type { TimeTracking } from "~generated";

interface CurrentEmployeeRunningTimeTrackingsContextValue {
  currentEmployeeRunningTimeTrackings: TimeTracking[];
}
const CurrentEmployeeRunningTimeTrackingsContext =
  createContext<CurrentEmployeeRunningTimeTrackingsContextValue>({
    currentEmployeeRunningTimeTrackings: [],
  });

interface CurrentEmployeeRunningTimeTrackingsProviderProps {
  children: ReactNode;
}
export function CurrentEmployeeRunningTimeTrackingsProvider({
  children,
}: CurrentEmployeeRunningTimeTrackingsProviderProps) {
  const { data: currentEmployeeRunningTimeTrackings } =
    useCurrentEmployeeRunningTimeTrackings();

  return (
    <CurrentEmployeeRunningTimeTrackingsContext.Provider
      value={{
        currentEmployeeRunningTimeTrackings:
          currentEmployeeRunningTimeTrackings || [],
      }}
    >
      {children}
    </CurrentEmployeeRunningTimeTrackingsContext.Provider>
  );
}

export function useCurrentEmployeeRunningTimeTrackingsContext() {
  const { currentEmployeeRunningTimeTrackings } = useContext(
    CurrentEmployeeRunningTimeTrackingsContext,
  );
  return currentEmployeeRunningTimeTrackings;
}
