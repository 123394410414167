/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TimeTrackingSessionSetWorkPackageEventBody = {
    action: TimeTrackingSessionSetWorkPackageEventBody.action;
    work_package_id: string;
};
export namespace TimeTrackingSessionSetWorkPackageEventBody {
    export enum action {
        SESSION_SET_WORK_PACKAGE = 'session_set_work_package',
    }
}

