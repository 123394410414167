import { onlineManager } from "@tanstack/react-query";
import { createContext, useEffect, useMemo, useState } from "react";

import { useOfflinePrefetch } from "./useOfflinePrefetch.tsx";

import type { AlertProps } from "@mui/material/Alert";
import type { ReactElement } from "react";

export interface BannerProps {
  severity: AlertProps["severity"];
  content: ReactElement | string;
}

export type OfflineContextProps = {
  isOffline: boolean;
  isOfflineReady: boolean;
};

export const OfflineContext = createContext<OfflineContextProps>({
  isOffline: false,
  isOfflineReady: false,
});

interface OfflineContextProviderProps {
  children: React.ReactNode;
}

export function OfflineContextProvider({
  children,
}: OfflineContextProviderProps) {
  const [isOffline, setIsOffline] = useState(!onlineManager.isOnline());

  const isOfflineReady = useOfflinePrefetch();

  useEffect(
    () =>
      onlineManager.subscribe(() => setIsOffline(!onlineManager.isOnline())),
    [],
  );

  const contextValues = useMemo(
    () => ({
      isOffline,
      isOfflineReady,
    }),
    [isOffline, isOfflineReady],
  );

  return (
    <OfflineContext.Provider value={contextValues}>
      {children}
    </OfflineContext.Provider>
  );
}
