/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EditAbsence = {
    employee_id?: string;
    start_date?: string;
    end_date?: string;
    category?: EditAbsence.category;
    note?: string | null;
};
export namespace EditAbsence {
    export enum category {
        SCHOOL = 'school',
        SICKNESS = 'sickness',
        TRAINING = 'training',
        VACATION = 'vacation',
        LEISURE_TIME_ACCOUNT = 'leisure_time_account',
    }
}

