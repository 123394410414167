import { useConstructionSite } from "~api/constructionSites.ts";
import { useAllEmployees } from "~api/employees";
import { findEntity } from "~api/helpers";
import { useWorkPackage } from "~api/workPackages.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { TimeTracking } from "~generated";
import { getResourceName } from "~lib/resourceHelpers.ts";

export function useTimeTrackingTitle(timeTracking?: TimeTracking) {
  const t = useTranslation();

  const { data: workPackage, isLoading: isLoadingWorkPackage } = useWorkPackage(
    timeTracking?.work_package_id,
    { enabled: !!timeTracking?.work_package_id },
  );

  const { data: constructionSite, isLoading: isLoadingConstructionSite } =
    useConstructionSite(workPackage?.construction_site_id as string, {
      enabled: !!workPackage?.construction_site_id,
    });

  const { data: employees, isLoading: isLoadingEmployees } = useAllEmployees({
    enabled: !!timeTracking?.construction_site_manager_id,
  });

  const isLoading =
    isLoadingWorkPackage || isLoadingConstructionSite || isLoadingEmployees;

  if (isLoading) {
    return { isLoading: true, title: { primary: "", secondary: "" } };
  }

  if (!timeTracking) {
    return {
      isLoading: false,
      title: { primary: t.trackingUnknownBannerTitle, secondary: "" },
    };
  }

  const constructionSiteManager = findEntity(
    employees,
    timeTracking.construction_site_manager_id,
  );

  switch (timeTracking.task_type) {
    case TimeTracking.task_type.WORK_PACKAGE:
      return {
        isLoading: false,
        title: {
          primary: workPackage?.name ?? t.unknownWorkPackage,
          secondary: constructionSite?.number ?? t.unknownConstructionSite,
        },
      };
    case TimeTracking.task_type.MANAGER_ACCOUNT:
      return {
        isLoading: false,
        title: {
          primary: getResourceName(
            constructionSiteManager,
            t.unknownConstructionSiteManager,
          ),
          secondary: timeTracking.task?.description ?? "",
        },
      };
    case TimeTracking.task_type.TRAINING:
      return {
        isLoading: false,
        title: {
          primary: t.trackingTrainingBannerTitle,
          secondary: timeTracking.training_note,
        },
      };
    default:
      return {
        isLoading: false,
        title: { primary: t.trackingUnknownBannerTitle, secondary: "" },
      };
  }
}
