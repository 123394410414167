/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeTrackingEvent } from '../models/TimeTrackingEvent';
import type { TimeTrackings } from '../models/TimeTrackings';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TimeTrackingsService {
    /**
     * list time_trackings for current user
     * @param tenantId tenant_id
     * @param status limit to status
     * @returns TimeTrackings returns time_trackings where current user is creator
     * @throws ApiError
     */
    public static getCurrentEmployeeTimeTrackings(
        tenantId: string,
        status?: 'running' | 'stopped',
    ): CancelablePromise<TimeTrackings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/me/time_trackings',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'status': status,
            },
        });
    }
    /**
     * create time_tracking_event
     * @param tenantId tenant_id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createTimeTrackingEvent(
        tenantId: string,
        requestBody?: Array<TimeTrackingEvent>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/time_tracking_events',
            path: {
                'tenant_id': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `invalid event body`,
                401: `employee does not have tenant access`,
                403: `current_employee is not allowed to create event of that type`,
            },
        });
    }
}
