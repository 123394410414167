import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns/minutesToMilliseconds";
import { useMemo } from "react";

import { ToolAdministrationService } from "~generated";

import { toolKeys } from "./toolKeys";

import type { BillableTool } from "./BillableTool";
import type { UseQueryOptions } from "@tanstack/react-query";
import type { Tools } from "~generated";

export interface FilterParams {
  page?: number;
  active?: boolean;
  billable?: boolean;
  categoryId?: string;
  limit?: number;
  searchText?: string;
  serialNumber?: string;
  responsibleEmployeeIds?: string[];
  tenantIds?: string[];
  managerIds?: string[];
  withoutResponsibleEmployee?: boolean;
}

interface GetToolsArgs extends FilterParams {
  signal?: AbortSignal;
}

export async function getTools({
  page,
  active,
  billable,
  categoryId,
  limit,
  searchText,
  serialNumber,
  responsibleEmployeeIds,
  tenantIds,
  managerIds,
  withoutResponsibleEmployee,
}: GetToolsArgs) {
  const promise = ToolAdministrationService.getTools(
    page,
    active,
    billable,
    categoryId,
    limit,
    searchText,
    serialNumber,
    withoutResponsibleEmployee,
    responsibleEmployeeIds,
    managerIds,
    tenantIds,
  );
  const response = await promise;
  if (!response || !response.tools) {
    return { tools: [], count: 0, next_page: null };
  }
  return response;
}

export function useBillableTools(
  filterParams?: FilterParams,
  options?: Partial<
    UseQueryOptions<Tools, Error, Tools, ReturnType<(typeof toolKeys)["list"]>>
  >,
) {
  const query = useAllTools({ billable: true, ...filterParams }, options);

  return useMemo(
    () => ({ ...query, data: query.data?.tools as BillableTool[] }),
    [query],
  );
}

export function useAllTools(
  filterParams: FilterParams,
  options?: Partial<
    UseQueryOptions<Tools, Error, Tools, ReturnType<(typeof toolKeys)["list"]>>
  >,
) {
  const searchText = getRelevantSearchText(filterParams);

  return useQuery({
    queryKey: toolKeys.list({ ...filterParams, searchText }),
    queryFn: async () => getTools({ ...filterParams, searchText }),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(1),
    ...options,
  });
}

export function getRelevantSearchText(filterParams: FilterParams) {
  return filterParams.searchText && filterParams.searchText.length >= 3
    ? filterParams.searchText
    : undefined;
}
