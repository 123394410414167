import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { useCallback, useState } from "react";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { useUpdate } from "~contexts/UpdateContext/UpdateContext.tsx";

export function UpdateBanner() {
  const { updateAvailable, updateNow } = useUpdate();
  const [disabled, setDisabled] = useState(false);

  const update = useCallback(() => {
    setDisabled(true);
    updateNow();
  }, [updateNow, setDisabled]);

  return (
    <UpdateBannerView
      updateAvailable={updateAvailable}
      disabled={disabled}
      onUpdate={update}
    />
  );
}

type UpdateBannerViewProps = {
  updateAvailable: boolean;
  disabled: boolean;
  onUpdate: () => void;
};

export function UpdateBannerView({
  updateAvailable,
  disabled,
  onUpdate,
}: UpdateBannerViewProps) {
  const t = useTranslation();

  return (
    <Snackbar
      open={updateAvailable}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        severity="info"
        variant="filled"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={onUpdate}
            disabled={disabled}
          >
            {t.updateNow}
          </Button>
        }
      >
        {t.updateAvailable}
      </Alert>
    </Snackbar>
  );
}
